import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';

export type UploadFileParams = {
  file: File;
};

export const performUploadAvatar = async (
  api: IApiStore,
  params: EndpointCallParams<UploadFileParams>,
  progressHandler?: (percent: number) => void,
): Promise<ApiResponse> => {
  const { payload, ...rest } = params;
  const { file } = payload;

  const req = await api
    .postAFile(
      'identity-app/api/v1/Users/UserPhoto',
      {
        ...rest,
        payload,
        progressHandler,
      },
      { fileField: 'picture', file },
      { },
    );

  return req;
}
