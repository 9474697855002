import React from 'react'
import { Observer } from 'mobx-react';
import { Form, FormikProps } from 'formik';
import { useTheme } from 'react-jss';

import { useForgotPasswordUIStore } from '@core/useStores';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormError } from '@shared/components/FormError';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { loginErrors, DefaultError } from './errors';
import { APP_EXTERNAL_STYLE_VARIANT } from '@shared/components/forms/BaseInputProps';

import { useStyles } from './ForgotPassword.styles';
import { messages } from 'AuthPart/authpart.messages';

export interface ForgotPasswordFormControlsProps extends FormikProps<FormValues> {};

export const ForgotPasswordFormControls: React.FC<ForgotPasswordFormControlsProps> = ({ errors }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const forgotPasswordUiStore = useForgotPasswordUIStore();
  const formErrors = Object.values(errors);

  return (
    <Form autoComplete='off' noValidate>
      <div className={styles.wrapper}>
        <h4 className={styles.formTitle}>{messages['forgotPasswordFormControls.title']}</h4>
        <p className={styles.formSubTitle}>{messages['forgotPasswordFormControls.subtitle']}</p>
      </div>

      <Observer>
        {() => (
          <div className={styles.errorWrapper}>
            {
              forgotPasswordUiStore.status.isError && (
                <FormError
                  text={loginErrors.get(forgotPasswordUiStore.processError) || DefaultError}
                />
              )
            }
          </div>
        )}
      </Observer>

      {
        formErrors && (
          <div className={styles.errorWrapper}>
            {
              formErrors.map((error, index) => (
                <FormError key={index} text={error || ''} />
              ))
            }
          </div>
        )
      }

      <div className={styles.wrapper}>
        <div className={styles.fieldWrapper}>
          <FormikTextInput
            schema={valuesSchema.email}
            autoFocus={true}
            cleanable={true}
            styleVariant={APP_EXTERNAL_STYLE_VARIANT}
          />
        </div>

        <div className={styles.actionWrapper}>
          <Observer>
            {() => (
              <Button
                type='submit'
                styleVariant="appExternal"
                loading={forgotPasswordUiStore.status.isLoading}
              >
                {messages['forgotPasswordFormControls.confirm']}
              </Button>
            )}
          </Observer>
        </div>
      </div>
    </Form>
  )
}
