import { Instance, SnapshotIn, types, flow, onSnapshot, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';
import { USERS_IN_GROUP_ID, ALL_USERS_ID } from '@shared/constants';
import { UsersGridStore } from './UsersGrid.store';
import { IGroupsDataStoreSnaphotOut, GroupUpdate$ } from 'Groups/store';
import { IPageQueryStoreSnapshotIn } from './PageQuery.store';

export const UsersGridUIStoreInferred = types
  .model('UsersGridUIStoreInferred', {
    _users: types.optional(UsersGridStore, {}),
    isActivePage: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    status: DataStateStore.create({ state: DataState.initial }),
  }))
  .views(self => ({
    get users() { return self._users.usersList; },
    get pagination() { return self._users.paginationStatus; },
    get requestParams() { return self._users.requestParams; },
    get statistics() { return self._users.statistics; },
    get inviteUser() { return self._users.inviteUser; },
    get isFilterOrSearchApplied() { return self._users.requestParams.areSearchParamsSet; },
  }))
  .views(self => ({
    get isTableVisible() { return self.users.length || self.status.isLoading; },
    get noFilteringResults() { return !self.users.length && !self.status.isLoading && self.isFilterOrSearchApplied },
    get noItemsInGroup() { return !self.users.length && !self.status.isLoading && !self.isFilterOrSearchApplied },
  }))
  .actions(self => {
    const load = flow(function * () {
      self.status.setLoading();
      yield self._users.loadUsers();
      self.status.setDone();
    });

    const loadStatistics = flow(function * () {
      yield self._users.loadStatistics();
    });

    const setParams = (params: IPageQueryStoreSnapshotIn) => {
      self.requestParams.setParams(params);
      self.requestParams.saveParamsToStorage();
    }

    const resetParams = () => self.requestParams.resetParams();

    const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

    const cleanUp = () => {
      resetParams();
      self._users.cleanData();
    }

    return {
      load,
      setParams,
      resetParams,
      loadStatistics,
      togglePageActivity,
      cleanUp,
    }
  })
  .actions(self => {
    const { groups } = getEnv<IStoresEnv>(self);

    return {
      afterCreate: () => {
        GroupUpdate$.subscribe(() => {
          if (self.isActivePage) {
            self.load();
            self.loadStatistics();
          }
        });

        onSnapshot(self.requestParams, () => {
          if (self.isActivePage) {
            self.load();
            self.loadStatistics();
          }

          self.requestParams.saveParamsToStorage();
        });

        onSnapshot(groups, (snap: IGroupsDataStoreSnaphotOut) => {
          if (snap.selectedGroup) {
            self.setParams({
              page: 0,
              inGroup: USERS_IN_GROUP_ID,
              inGroupId: snap.selectedGroup as string,
            });
          }

          // Show 0 page of 'All users' after group removing
          if (self.requestParams.inGroup === USERS_IN_GROUP_ID && !snap.selectedGroup) {
            self.setParams({
              page: 0,
              inGroup: ALL_USERS_ID,
              inGroupId: undefined,
            });
          }
        })
      },
    }
  })

type UsersGridUIStoreFactoryType = typeof UsersGridUIStoreInferred;
interface IUsersGridUIStoreFactory extends UsersGridUIStoreFactoryType {}
export const UsersGridUIStore: IUsersGridUIStoreFactory = UsersGridUIStoreInferred;
export interface IUsersGridUIStore extends Instance<IUsersGridUIStoreFactory> {}
export interface IUsersGridUIStoreSnapshotIn extends SnapshotIn<IUsersGridUIStore> {}
