import moment from 'moment';
import { getEnv, Instance, SnapshotIn, types, flow } from 'mobx-state-tree';

import { LoggedUser } from '@core/domain/LoggedUser';
import { IAuthStoreEnv } from './AuthStoreEnv';
import {
  performLogin, performLogout,
  performForgotPassword, performRefreshToken,
  performSetPassword, performResetPassword, SetPasswordParams,
  performVerifyToken, VerifyTokenParams,
  performSwitchCompany
} from '../services';

import { RoutePaths } from '@core/routes/RoutePaths';
import { TokenDetails } from '@core/domain/TokenDetails';
import { UserProfile, IUserProfile } from './UserProfile.store';
import { UserPermissions, IUserPermissions } from './UserPermissions.store';
import { CompanyStore, ICompanyStore } from '../../../Companies/stores';

const AUTH_USER_INFO = 'AuthUserInfo';

export const AuthStoreInferred = types
  .model('auth', {})
  .volatile(() => ({
    userData: null as unknown as LoggedUser,
  }))
  .views(self => ({
    get authHeaderValue () {
      return self.userData?.token
        ? `${self.userData.token.token_type} ${self.userData.token.access_token}`
        : '';
    },

    get userProfile (): IUserProfile | null {
      const userData = self.userData && self.userData.profile;

      if (!userData) {
        return null;
      }

      return UserProfile.create({
        id: userData.id,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        avatar: userData.avatar,
        department: userData.department,
        companyId: userData.companyId,
      });
    },

    get userCompany(): ICompanyStore | null {
      const userData = self.userData && self.userData.company;

      if (!userData) {
        return null;
      }

      return CompanyStore.create({
        id: userData.id,
        name: userData.name,
        description: userData.description,
        dataIsolation: userData.dataIsolation,
      });
    },

    get userPermissions (): IUserPermissions | null {
      const userPermissions = self.userData && self.userData.permissions;

      if (!userPermissions) {
        return null;
      }

      return UserPermissions.create({...userPermissions});
    },

    get userRoles (): string[] {
      const userRoles = self.userData && self.userData.roles;

      return userRoles;
    },
  }))
  .actions(self => {
    const { localStorage } = getEnv<IAuthStoreEnv>(self);

    return ({
      afterCreate: () => {
        const storedUserData = localStorage.getItem(AUTH_USER_INFO);
        const parsedUserData = storedUserData && JSON.parse(storedUserData);

        if (parsedUserData) {
          self.userData = parsedUserData;
        }
      },
    })
  })
  .actions(self => {
    const { localStorage, sessionStorage } = getEnv<IAuthStoreEnv>(self);

    const storeUserInfo = () => {
      localStorage.setItem(AUTH_USER_INFO, JSON.stringify(self.userData));
    };

    const setAvatar = (avatar: string) => {
      self.userData = {
        ...self.userData,
        profile: { ...self.userData?.profile, avatar },
      };

      storeUserInfo();
    };

    const updateTokenDetails = (tokenData: TokenDetails) => {
      self.userData = {
        profile: { ...self.userData?.profile },
        company: { ...self.userData?.company },
        permissions: { ...self.userData?.permissions },
        roles: [...self.userData?.roles],
        token: {
          ...tokenData,
          // eslint-disable-next-line @typescript-eslint/camelcase
          expires_at: moment().unix() + tokenData.expires_in,
        },
      };

      storeUserInfo();
    };

    const checkToken = flow(function* () {
      if (self.userData && self.userData.token.expires_at <= moment().unix()) {
        try {
          const result = yield performRefreshToken(self.userData.token.refresh_token || '');
          updateTokenDetails(result.body);
        } catch (e) {
          signOut();
        }
      }
    });

    const signIn = flow(function* (email: string, password: string) {
      return yield performLogin(
        {
          email,
          password,
        },
      );
    });

    const forgotPassword = flow(function* (email: string) {
      return yield performForgotPassword(
        { email },
      );
    });

    const setPassword = flow(function* (data: SetPasswordParams) {
      return yield performSetPassword({ ...data });
    });

    const resetPassword = flow(function* (data: SetPasswordParams) {
      return yield performResetPassword({ ...data });
    });

    const verifyToken = flow(function* (data: VerifyTokenParams) {
      return yield performVerifyToken({ ...data });
    });

    const signOut = flow(function* () {
      const { navigator } = getEnv<IAuthStoreEnv>(self);

      yield performLogout({ token: self.authHeaderValue });

      self.userData = null as unknown as LoggedUser;
      navigator.to(RoutePaths.login);

      localStorage.clear();
      sessionStorage.clear();
    });

    const clearLocalData = () => {
      self.userData = null as unknown as LoggedUser;

      localStorage.clear();
      sessionStorage.clear();
    };

    const switchCompany = flow(function* (payload: any) {
      return yield performSwitchCompany({ email: self.userProfile?.email, token: self.authHeaderValue, ...payload })
    });

    return ({
      signIn,
      forgotPassword,
      setPassword,
      resetPassword,
      verifyToken,
      signOut,
      checkToken,
      saveUserInfo(info: LoggedUser, keepToken: boolean = false) {
        if (keepToken && !info.token) {
          info.token = self.userData.token;
        }
        self.userData = info;
        storeUserInfo();
      },
      setAvatar,
      storeUserInfo,
      switchCompany,
      clearLocalData,
    });
  });

type AuthStoreFactoryType = typeof AuthStoreInferred;
interface IAuthStoreFactory extends AuthStoreFactoryType {}
export const AuthStore: IAuthStoreFactory = AuthStoreInferred;
export interface IAuthStore extends Instance<IAuthStoreFactory> {}
export interface IAuthStoreSnapshotIn extends SnapshotIn<IAuthStore> {}

type AuthState = IAuthStoreSnapshotIn | IAuthStore;

export const createAuthStore = (
  dependencies: IAuthStoreEnv,
  data: AuthState = {},
) => AuthStore.create(data, dependencies);
