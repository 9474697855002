import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetUserProceduresExecutionsParams = {
  id: string;
};

export const performGetUserProceduresExecutions = async (
  api: IApiStore, params: EndpointCallParams<GetUserProceduresExecutionsParams>
) => {
  const { payload, ...rest } = params;

  const req = api
    .post('uiaggregator-app/api/v1/Analytic/ProceduresAndExecutions', {
      payload,
      ...rest,
    })

  return req;
}
