import { FormSchema } from '@shared/components/forms/FormSchema';

import { ProcedureFormValues } from './FormValues';
import { messages } from 'Procedures/procedures.messages';

type ProcedureFormSchema = FormSchema<ProcedureFormValues>;

export const FIELD_NAMES = {
  name: messages['procedureModal.name.label'],
  description: messages['procedureModal.description.label'],
  groups: messages['procedureModal.groups.label'],
  users: messages['procedureModal.users.label'],
  ownership: messages['procedureModal.ownership.label'],
}

export const valuesSchema: ProcedureFormSchema = {
  name: {
    fieldName: 'name',
    label: FIELD_NAMES.name,
    isRequired: true,
  },
  description: {
    fieldName: 'description',
    label: FIELD_NAMES.description,
    isRequired: false,
    type: 'textarea',
  },
  groups: {
    fieldName: 'groups',
    label: FIELD_NAMES.groups,
    isRequired: false,
  },
  users: {
    fieldName: 'users',
    label: FIELD_NAMES.users,
    isRequired: false,
  },
  ownership: {
    fieldName: 'ownership',
    label: FIELD_NAMES.ownership,
    isRequired: false,
  },
};
