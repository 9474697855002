import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  panelWrapper: {
    borderTop: `1px solid ${theme.colors.grey_light}`,
    paddingTop: theme.spacing(4),
  },

  searchPanel: {
    marginBottom: theme.spacing(6.5),
  },

  emptyListText: {
    color: theme.colors.grey_text,
    fontSize: 16,
    lineHeight: 1.5,
  },

  addNewGroupButton: {
    marginTop: theme.spacing(6),
  },

  menuItem: {
    marginBottom: theme.spacing(2),
  },
}));
