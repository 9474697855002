import VRIcon from '@assets/vr.svg';
import ARIcon from '@assets/ar.svg';
import MobileIcon from '@assets/tablet.svg';
import PCIcon from '@assets/pc.svg';

export const deviceTypesImages = {
  'VR': VRIcon as string,
  'AR': ARIcon as string,
  'Mobile': MobileIcon as string,
  'PC': PCIcon as string,
} as {[key: string]: string};
