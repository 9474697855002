import { Instance, SnapshotIn, types, flow, getEnv, getSnapshot, applySnapshot } from 'mobx-state-tree';
import { IStoresEnv } from '@core/storesEnv';

import {
  performInviteUserRequest, performResendInviteUserRequest,
} from 'Users/services';

export const inviteUserInitialState = {
  email: '',
  firstName: '',
  lastName: '',
  roleId: '',
  department: '',
  groups: [],
  procedures: [],
  errors: null,
}

export type InviteUserParams = {
  email?: string,
  firstName?: string,
  lastName?: string,
  roleId?: string,
  department?: string,
  groups?: string[],
  procedures?: string[],
}

export type ResendInviteUserParams = {
  id: string,
  email: string,
}

export type InviteUserStoreParams = InviteUserParams & { errors: string[] | null }

export const InviteUserStoreInferred = types
  .model('InviteUserStoreInferred',
    {
      email: types.optional(types.string, ''),
      firstName: types.optional(types.string, ''),
      lastName: types.optional(types.string, ''),
      roleId: types.optional(types.string, ''),
      department: types.optional(types.string, ''),
      groups: types.optional(types.array(types.string), []),
      procedures: types.optional(types.array(types.string), []),
      errors: types.maybeNull(types.array(types.string)),
    })
  .views(self => ({
    get getInviteUserFields() {
      return getSnapshot(self);
    },
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const sendInviteUserRequest = flow(function* (payload: any) {
      const result = yield performInviteUserRequest(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
        {
          returnUrl: 'https://weavrmanageruimaster.azurewebsites.net/users',
        }
      );

      if (!result.success) {
        const { body } = result.error || {};

        self.errors = body?.errors;
      } else {
        self.errors = null;
      }

      return result;
    });

    const setInviteUserFields = (params: InviteUserStoreParams) => {
      applySnapshot(self, params);
    }

    const resendInviteUser = flow(function* (payload: ResendInviteUserParams) {
      const result = yield performResendInviteUserRequest(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      if (!result.success) {
        const { body } = result.error || {};

        self.errors = body?.errors;
      } else {
        self.errors = null;
      }

      return result;
    });

    return {
      sendInviteUserRequest,
      setInviteUserFields,
      resendInviteUser,
    }
  })

type InviteUserStoreFactoryType = typeof InviteUserStoreInferred;
interface IInviteUserStoreFactory extends InviteUserStoreFactoryType {}
export const InviteUserStore: IInviteUserStoreFactory = InviteUserStoreInferred;
export interface IInviteUserStore extends Instance<IInviteUserStoreFactory> {}
export interface IInviteUserStoreSnapshotIn extends SnapshotIn<IInviteUserStore> {}
