import * as Yup from 'yup';

import { ProceduresFiltersFormValues } from '.';
import { valuesSchema } from './valuesSchema';
import { messages } from 'Procedures/procedures.messages';

export const errors = {
  required: messages['procedureModal.error.required'],
};

export const validationSchema = Yup.object().shape<Partial<ProceduresFiltersFormValues>>({
  executionsMin: Yup.number()
    .min(0, messages['proceduresFiltersModal.validation.min'](valuesSchema.executionsMin.label))
    .max(1000000, messages['proceduresFiltersModal.validation.max'](valuesSchema.executionsMin.label))
    .integer(messages['proceduresFiltersModal.validation.integer'](valuesSchema.executionsMin.label))
    .required(errors.required(valuesSchema.executionsMin.label)),
  executionsMax: Yup.number()
    .min(0, messages['proceduresFiltersModal.validation.min'](valuesSchema.executionsMax.label))
    .max(1000000, messages['proceduresFiltersModal.validation.max'](valuesSchema.executionsMax.label))
    .integer(messages['proceduresFiltersModal.validation.integer'](valuesSchema.executionsMax.label))
    .required(errors.required(valuesSchema.executionsMax.label))
    .moreThan(Yup.ref('executionsMin'), messages['proceduresFiltersModal.validation.minMaxDifference']),
});
