import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetGroupParams = {
  id: string;
};

export const performGetGroup = async (api: IApiStore, params: EndpointCallParams<GetGroupParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .get(`uiaggregator-app/api/v1/Groups/${payload.id}`, {
      ...rest,
      payload: {},
    })

  return req;
}
