import React, { useEffect } from 'react';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useTheme } from 'react-jss';
import { Cell, Column } from 'react-table';
import classNames from 'classnames';

import { SimpleTable } from '@shared/components/SimpleTable';
import ArrowRightIcon from '@assets/arrow-right.svg';
import EditIcon from '@assets/edit.svg';
import KeysIcon from '@assets/keys.png';

import { useCompaniesGridUIStore, useCompanyUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { useStyles } from './CompaniesPage.styles';
import { CompanyModal } from '../CompanyModal';
import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ConfirmPasswordModal } from 'AuthPart/ConfirmWithPassword';
import { ToastMessage } from '@shared/components/Toast';

import { messages } from 'Companies/companies.messages';

const EXPANDER_CELL_WIDTH = 24;

const columnsData = [
  {
    id: 'expander',
    minWidth: 40,
    maxWidth: 40,
    Header: () => (
      <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
    ),
    Cell: ({ row }: Cell) => {
      const { style, ...props } = row.getToggleRowExpandedProps();
      const styles = useStyles();

      return (
        <div
          {...props}
          style={{
            ...style,
            minWidth: EXPANDER_CELL_WIDTH,
            display: 'flex',
          }}
        >
          {row.canExpand && (
            <img
              src={ArrowRightIcon}
              className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
            />
          )}
        </div>
      )
    },
    disableSortBy: true,
  },
  {
    Header: messages['companiesPage.table.name.header'],
    accessor: 'name',
    minWidth: 86,
    maxWidth: 280,
    width: 7,
    disableEllipsis: true,
    Cell: ({ value }: Cell) => <span title={value}>{value}</span>,
  },
  {
    Header: messages['companiesPage.table.description.header'],
    accessor: 'description',
    minWidth: 86,
    width: 7,
    disableEllipsis: true,
    Cell: ({ value }: Cell) => <span title={value}>{value}</span>,
  },
  {
    Header: messages['companiesPage.table.dataIsolation.header'],
    accessor: 'dataIsolation',
    minWidth: 40,
    width: 7,
    disableEllipsis: true,
    Cell: ({ value }: Cell) => <span title={value ? 'True' : 'False'}>{value ? 'True' : 'False'}</span>,
  },
  {
    id: 'edit',
    Header: messages['companiesPage.table.actions.header'],
    minWidth: 40,
    maxWidth: 40,
    disableSortBy: true,
    disableEllipsis: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();

      const companyUIStore = useCompanyUIStore();
      const userPermissionsUIStore = useUserPermissionsUIStore();

      const original: Record<string, any> = row.original;

      const canEdit = userPermissionsUIStore.canUpdateCompany;

      const onCellClick = () => {
        companyUIStore.setEditCompanyId(original.id)
        companyUIStore.toggleCompanyModalOpen(true);
      }

      return (
        <button
          onClick={() => canEdit && onCellClick()}
          disabled={!canEdit}
          className={styles.button}
          title={messages['companiesPage.table.editCompany']}
        >
          <img src={EditIcon} />
        </button>
      )
    },
  },
  {
    id: 'additionalActions',
    minWidth: 42,
    maxWidth: 42,
    disableSortBy: true,
    Cell: ({ row }: Cell) => {
      const styles = useStyles();
      const companyUIStore = useCompanyUIStore();
      const { api } = getEnv<IStoresEnv>(companyUIStore);
      const { auth } = getEnv(api);

      const original: Record<string, any> = row.original;

      const isOvertaken = original.id == auth.userProfile.companyId;

      const enterCompany = () => {
        companyUIStore.setSwitchCompanyId(original.id);
        companyUIStore.toggleConfirmSwithModalOpen(true);
      };

      return (
        <Observer>
          {() => (
            <button
              onClick={() => enterCompany()}
              className={classNames(styles.button, styles.buttonTakeover)}
              disabled={isOvertaken}
            >
              {
                <img className={styles.actionIcon}
                  title={messages['companiesPage.table.enterCompany']}
                  src={KeysIcon} />
              }
            </button>
          )}
        </Observer>
      )
    },
  },
]

export const CompaniesPage: React.FC = () => {
  const userPermissionsUIStore = useUserPermissionsUIStore();
  const companiesGridUIStore = useCompaniesGridUIStore();
  const companiesUIStore = useCompanyUIStore();

  const { filtersOptions } = getEnv<IStoresEnv>(companiesGridUIStore);

  const theme = useTheme();
  const styles = useStyles({ theme });

  const initialSortState = {
    sortBy: [{
      id: 'name',
      desc: false,
    }],
  };

  const columns: Column<object>[] = React.useMemo(() => columnsData, []);

  useEffect(() => {
    companiesGridUIStore.togglePageActivity(true);

    companiesGridUIStore.load();

    filtersOptions.loadFilterOptions();

    return () => {
      companiesGridUIStore.togglePageActivity(false);
      companiesGridUIStore.cleanUp();
    }
  }, []);

  const onExitCompany = () => {
    companiesUIStore.setSwitchCompanyId(null);
    companiesUIStore.toggleConfirmSwithModalOpen(true);
  }

  const onCreateCompanyModalOpen = () => {
    companiesUIStore.clearEditCompanyId();
    companiesUIStore.toggleCompanyModalOpen(true);
  }

  const onDeleteCompanyDecline = () => {
    companiesUIStore.toggleDeleteConfirmationModalOpened(false);
    companiesUIStore.toggleCompanyModalOpen(false);
    companiesUIStore.clearEditCompanyId();
  }

  const onDeleteCompanyConfirm = async () => {
    const result = await companiesUIStore.deleteCompany({ id: companiesUIStore.companyToEditId });

    if (!result.success) {
      toast.error(
        <ToastMessage
          message={messages['companiesPage.removeCompany.toast.error']}
          type='error'
        />
      );
    } else {
      toast.success(
        <ToastMessage
          message={messages['companiesPage.removeCompany.toast.success']}
          type='success'
        />
      );

      //companiesGridUIStore.setParams({ page: 0 });
      companiesGridUIStore.load();
      //companiesGridUIStore.loadStatistics();
      filtersOptions.loadFilterOptions();
    }

    companiesUIStore.clearEditCompanyId();
    companiesUIStore.toggleCompanyModalOpen(false);
    companiesUIStore.toggleDeleteConfirmationModalOpened(false);
  }

  const onCreateCompanyModalClose = () => {
    const params = companiesGridUIStore.createCompany.getCompanyFields;

    companiesGridUIStore.createCompany.setCompanyFields({ ...params, errors: null });
    companiesUIStore.clearEditCompanyId();
    companiesUIStore.toggleCompanyModalOpen(false);
  }

  const onSwitchCompany = (password: string) => {
    const result = companiesUIStore.switchCompany({ companyId: companiesUIStore.switchCompanyId, password });

    return result;
  }

  const onSwitchCompanyClosed = () => {
    companiesUIStore.setSwitchCompanyId(null);
    companiesUIStore.toggleConfirmSwithModalOpen(false);
  }

  return (
    <>

      <h2 className={styles.title}>
        {messages['companiesPage.pageTitle']}
      </h2>
      <div className={styles.tableActions}>
        <div className={styles.invite}>
          {
            userPermissionsUIStore.canCreateCompany && (
              <span style={{ display: 'inherit' }}>
                <button
                  onClick={onCreateCompanyModalOpen}
                  className={classNames(styles.button, styles.buttonCreate)}
                >
                  {
                    messages['companiesPage.createCompany.title']
                  }
                </button>
                <Observer>
                  {() => {
                    return (
                      <button
                        onClick={onExitCompany}
                        className={classNames(styles.button, styles.buttonCreate, styles.marginLeft)}
                        disabled={!companiesUIStore.canExitCompany}
                      >
                        {
                          messages['companiesPage.exitCompany.title']
                        }
                      </button>)
                  }}
                </Observer>
              </span>
            )
          }
        </div>
      </div>

      <Observer>
        {() => {
          const data = companiesGridUIStore.companies.toJS();
          const preparedData = data.map(row => ({
            ...row,
          }));

          return (
            <>
              <ConfirmPasswordModal
                isOpen={companiesUIStore.confirmPasswordModalOpened}
                actionName={messages['companiesPage.switchCompany.label']}
                onSubmitPassword={onSwitchCompany}
                onRequestClose={onSwitchCompanyClosed}
              />
              <CompanyModal
                isOpen={companiesUIStore.companyModalOpened}
                onRequestClose={onCreateCompanyModalClose}
              />
              {/* TODO move logic of modals to separate component */}
              <ConfirmationModal
                isOpen={companiesUIStore.deleteConfirmationModalOpened}
                message={messages['companiesPage.delete.message']}
                confirmText={messages['companiesPage.delete.confirmText']}
                onDecline={onDeleteCompanyDecline}
                onConfirm={onDeleteCompanyConfirm}
              />

              {
                companiesGridUIStore.isTableVisible && (
                  <SimpleTable
                    data={preparedData}
                    columns={columns}
                    isLoading={companiesGridUIStore.status.isLoading}
                    initialState={initialSortState}
                  />
                )
              }
            </>
          )
        }}
      </Observer>
    </>
  )
}
