import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';

import { useStyles } from './GroupsList.styles';
import { useGroupsUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { SearchForm } from '@shared/components/SearchForm';
import { Button } from '@shared/components/Button';
import { SidebarFilterButton } from '@shared/components/SidebarFilterButton';
import { messages } from 'Groups/groups.messages';

export const GroupsList: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const groupsUIStore = useGroupsUIStore();
  const params: { id: string } = useParams();
  const history = useHistory();
  const { id } = params;

  useEffect(() => {
    groupsUIStore.loadGroups();
  }, []);

  const userPermissionsUIStore = useUserPermissionsUIStore();

  const onSearchTextChanged = (text: string) => groupsUIStore.search(text);

  const onGroupClick = (groupId: string) => {
    groupsUIStore.setSelectedGroup(groupId);
    if (id) {
      history.push('/analytics');
    }
  }

  return (
    <Observer>
      {() => (
        <section className={styles.panelWrapper}>
          {
            !groupsUIStore.hasNoGroups && (
              <SearchForm
                initialValues={{
                  search: groupsUIStore.getStorageSearchText || '',
                }}
                className={styles.searchPanel}
                onSearchTextChanged={onSearchTextChanged}
                valueDelay={300}
              />
            )
          }

          {
            groupsUIStore.groups.map(group =>
              <SidebarFilterButton
                key={group.id}
                className={styles.menuItem}
                onClick={() => onGroupClick(group.id)}
                active={groupsUIStore.isActive(group.id)}
              >
                {group.name}
              </SidebarFilterButton>
            )
          }

          {
            groupsUIStore.isSearchApplied && !groupsUIStore.groups.length && (
              <div
                className={styles.emptyListText}
                data-testid="no-groups-found"
              >
                {messages['groupsList.noGroups']}
              </div>
            )
          }

          {
            userPermissionsUIStore.canCreateGroup && groupsUIStore.hasNoGroups && (
              <div className={styles.emptyListText}>
                <span>{messages['groupsList.noGroupsCreated']}</span>
                <Button
                  className={styles.addNewGroupButton}
                  onClick={() => groupsUIStore.toggleGroupModalOpen(true)}
                >
                  {messages['groupsList.create']}
                </Button>
              </div>
            )
          }
        </section>
      )}
    </Observer>
  )
};
