import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type DeleteGroupParams = {
  id: string;
};

export const performDeleteGroup = async (api: IApiStore, params: EndpointCallParams<DeleteGroupParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .delete(`identity-app/api/v1/Groups/${payload.id}`, {
      ...rest,
      payload: {},
    })

  return req;
}
