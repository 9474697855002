import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  modal: {
    width: 508,
    minHeight: 120,
  },

  h2: {
    fontFamily: theme.font.secondary,
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.colors.black,
    fontWeight: 'normal',
    marginBottom: theme.spacing(8),
  },

  note: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.grey_text,
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(8),
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  approve: {
    marginLeft: theme.spacing(5),
  },
}));
