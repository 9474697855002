import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { UsersStatisticsStore } from '@shared/stores';
import { UserStore } from './User.store';
import { PageQueryStore } from './PageQuery.store';
import { PaginationStatusStore } from './PaginationStatus.store';
import { InviteUserStore } from './InviteUser.store';

import { performGetUsers, performGetUsersStatistics } from 'Users/services';

export const UsersGridStoreInferred = types
  .model('UsersGridStoreInferred', {
    usersList: types.array(UserStore),
    requestParams: types.optional(PageQueryStore, {}),
    paginationStatus: types.optional(PaginationStatusStore, {}),
    inviteUser: types.optional(InviteUserStore, {}),
    statistics: types.optional(UsersStatisticsStore, {}),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadUsers = flow(function* () {
      const result = yield performGetUsers(
        api,
        {
          payload: { ...self.requestParams.getParams },
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.usersList = result.data.entities;
      self.paginationStatus = result.data.meta;
    });

    const loadStatistics = flow(function* () {
      const { inGroup, inGroupId: groupId } = self.requestParams.getParams;
      const result = yield performGetUsersStatistics(
        api,
        {
          payload: { inGroup, groupId },
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.statistics = result.data;
    });

    const cleanData = () => {
      self.usersList = cast([]);
      self.paginationStatus = cast({});
    };

    return {
      loadUsers,
      loadStatistics,
      cleanData,
    }
  })

type UsersGridStoreFactoryType = typeof UsersGridStoreInferred;
interface IUsersGridStoreFactory extends UsersGridStoreFactoryType {}
export const UsersGridStore: IUsersGridStoreFactory = UsersGridStoreInferred;
export interface IUsersGridStore extends Instance<IUsersGridStoreFactory> {}
export interface IUsersGridStoreSnapshotIn extends SnapshotIn<IUsersGridStore> {}
