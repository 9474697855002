export const messages = {
  'profilePage.title': 'My profile',
  'profileForm.email.label': 'Email',
  'profileForm.firstName.label': 'First name',
  'profileForm.lastName.label': 'Last name',
  'profileForm.error.email': 'Email shall contain \'@\', \'.\' and shall not contain space',
  'profileForm.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'profileAvatar.title': 'Avatar',
  'profileAvatar.buttons.uploadNew': 'Upload new avatar',
  'profileAvatar.buttons.remove': 'Remove avatar',
  'profileAvatar.error.tooLarge': 'File is too large. Maximum file size is 5Mb.',
}
