import { RoutePaths } from './RoutePaths';
import { PagesNames } from './PagesNames';

export const PagesRoutes = new Map([
  [PagesNames.Companies, RoutePaths.companies],
  [PagesNames.Users, RoutePaths.users],
  [PagesNames.Procedures, RoutePaths.procedures],
  [PagesNames.Collaboration, RoutePaths.collaboration],
  [PagesNames.Analytics, RoutePaths.analytics],
  [PagesNames.Analytics, RoutePaths.analyticsByUser],
  [PagesNames.Profile, RoutePaths.profile],
  [PagesNames.Login, RoutePaths.login],
]);
