import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  body: {
    ...flex('', 'center', 'flex-start'),
    alignContent: 'flex-start',
    padding: [30, 0],
    flexWrap: 'wrap',
    border: `1.5px solid ${theme.colors.grey_light}`,
    minHeight: 'calc(100vh - 128px)',
    background: theme.colors.white,
    borderRadius: theme.radius,
    marginTop: -80,
  },
  title: {
    fontFamily: theme.font.secondary,
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: 1.33,
    textAlign: 'center',
    color: theme.colors.grey_text,
    marginBottom: theme.spacing(10),
    width: '100%',
    whiteSpace: 'pre-line',
  },
  image: {
    maxWidth: 'calc(100% - 60px)',
  },
}));
