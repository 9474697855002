import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { ThemeProps } from '@styles/theme';
import color from 'color';

import CheckboxCheckedIcon from '@assets/checkbox-checked.svg';
import CheckboxUncheckedIcon from '@assets/checkbox-unchecked.svg';

export type SelectOptionProps = {
  isMulti: boolean,
  isSelected: boolean,
  isFocused: boolean,
  innerProps: object,
  getStyles: Function,
  children: React.ReactNode,
  label: string
}

const useStyles = createUseStyles((theme:ThemeProps) => ({
  active: {},
  option: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: [0, theme.spacing(3)],
    fontFamily: theme.font.primary,
    fontSize: 16,
    color: theme.colors.black,
    lineHeight: 1.5,
    '&$active': {
      cursor: 'pointer',
      backgroundColor: color(theme.colors.grey_light).fade(0.36).string(),
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkbox: {
    marginRight: theme.spacing(2),
  },
}))

export const SelectOption: FC<SelectOptionProps> = props => {
  const {
    children,
    innerProps,
    isMulti,
    isSelected,
    isFocused,
    label,
  } = props;

  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div
      className={classNames(styles.option, { [styles.active]: isSelected || isFocused })}
      {...innerProps}
    >
      {isMulti && (
        <img
          src={isSelected ? CheckboxCheckedIcon : CheckboxUncheckedIcon}
          className={styles.checkbox}
          data-testid="select-option-multi"
        />
      )}
      <div className={styles.text} title={label}>
        {children}
      </div>
    </div>
  )
}
