import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UserStatusInferred = types
  .model('UserStatusInferred', {
    id: types.identifier,
    name: types.string,
  });

type UserStatusFactoryType = typeof UserStatusInferred;
interface IUserStatusFactory extends UserStatusFactoryType {}
export const UserStatus: IUserStatusFactory = UserStatusInferred;
export interface IUserStatus extends Instance<IUserStatusFactory> {}
export interface IUserStatusSnapshotIn extends SnapshotIn<IUserStatus> {}
