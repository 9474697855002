const MAX_PADDED_NUMBER = 9;

export const padNumbersWithZero = (number: number) =>
  number > MAX_PADDED_NUMBER ? number : `0${number}`;

export const formatSecondsToTimeString = (seconds: number) => {
  if (seconds < 60) {
    return `${padNumbersWithZero(Math.trunc(seconds))} s`
  } else if (seconds >=60 && seconds < 3600) {
    const minutes = Math.trunc(seconds/60);

    return `${minutes} min ${padNumbersWithZero(Math.trunc(seconds) - 60*minutes)} s`
  } else {
    const hours = Math.trunc(seconds/3600);
    const minutes = Math.trunc((Math.trunc(seconds) - 3600*hours)/60);
    const sec = padNumbersWithZero(Math.trunc(seconds) - (hours*3600 + minutes*60));

    return `${hours} h ${padNumbersWithZero(minutes)} min ${sec} s`
  }
}

export const convertHhMmSsToSeconds = (hhmmss: string) => {
  if (!hhmmss) {
    return 0;
  }
  const splitted = hhmmss.split(':').map(data => Number(data));

  return splitted[0] * 60 * 60 + splitted[1] * 60 + splitted[2];
};
