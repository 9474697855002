export const messages = {
  'appExternalSuccessMessage.back': 'Back to login page',
  'avatar.alt': 'User avatar',
  'confirmationModal.default.cancelText': 'Cancel',
  'confirmationModal.default.confirmText': 'Confirm',
  'expandableList.toggleButton': (isLimit: boolean) => `Show ${isLimit ? 'more' : 'less'}`,
  'noResultsView.text': (entityName: string) => `There are no ${entityName} matching the selected parameters.`,
  'searchForm.placeholder': 'Search',
  'usersSummary.editGroup': 'Edit group',
  'usersSummary.allUsers': 'All users',
  'usersSummary.usersWithoutGroup': 'Ungrouped users',
  'usersSummary.active.title': 'Active',
  'usersSummary.active.text': (count: number | string) => `${count} ${count == 1 ? 'User' : 'Users'}`,
  'usersSummary.managed.title': 'Managed by',
  'usersSummary.managed.text': (count: number | string) => `${count} ${count == 1 ? 'Instructor' : 'Instructors'}`,
  'usersSummary.access.title': 'Access to',
  'usersSummary.access.text': (count: number | string) => `${count} ${count == 1 ? 'Procedure' : 'Procedures'}`,
  'tablePagination.summary': (current: number, total: number) => `Page ${current} of ${total}`,
}
