import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';
import { messages } from '@shared/components/components.messages';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  wrapper: {
    ...flex('column', 'center', 'center'),
    width: '100%',
    border: `1.5px solid ${theme.colors.grey_light}`,
    minHeight: 'calc(100vh - 300px)',
    height: 100,
    background: theme.colors.white,
    borderRadius: theme.radius,
    color: theme.colors.grey_text,
    fontFamily: theme.font.secondary,
    fontSize: 24,
    lineHeight: 1.3,
  },
}));

type NoResultsViewProps = {
  entityName?: string;
};

export const NoResultsView: React.FC<NoResultsViewProps> = ({
  entityName = 'items',
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <section className={styles.wrapper}>
      <p data-testid="no-results-view">{messages['noResultsView.text'](entityName)}</p>
    </section>
  )
}
