import React from 'react';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { getEnv } from 'mobx-state-tree';

import { useGroupsUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { ConfirmationModal } from '@shared/components/ConfirmationModal';
import { ToastMessage } from '@shared/components/Toast';

import { GroupsList } from './components/GroupsList';
import { GroupModal } from './components/GroupModal';
import { messages } from 'Groups/groups.messages';

export const Groups: React.FC = () => {
  const groupsUIStore = useGroupsUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(groupsUIStore);

  const onConfirm = async (id: string) => {
    const groupName = groupsUIStore.selectedGroup?.name;
    const result = await groupsUIStore.deleteGroup(id);

    if (result.success) {
      toast.success(
        <ToastMessage
          message={messages['group.delete.success'](groupName)}
          type='success'
        />
      );

      groupsUIStore.unselectGroup();
      groupsUIStore.loadGroups();
      filtersOptions.loadFilterOptions();
    }

    groupsUIStore.clearEditGroupId();
    groupsUIStore.toggleDeleteConfirmationModalOpened(false);
  }

  const onRequestModalClose = () => {
    groupsUIStore.toggleGroupModalOpen(false);
    groupsUIStore.clearEditGroupId();
  }

  const onGroupDeleteRequest = () => {
    groupsUIStore.toggleGroupModalOpen(false);
    groupsUIStore.toggleDeleteConfirmationModalOpened(true);
  }

  const onConfirmationDecline = () => {
    groupsUIStore.toggleDeleteConfirmationModalOpened(false);
    groupsUIStore.clearEditGroupId();
  }

  return (
    <Observer>
      {() => (
        <>
          <GroupsList/>

          {
            groupsUIStore.groupModalOpened && (
              <GroupModal
                isOpen={true}
                onRequestClose={onRequestModalClose}
                onGroupDeleteRequest={onGroupDeleteRequest}
                groupId={groupsUIStore.groupToEditId}
              />
            )
          }

          {
            groupsUIStore.deleteConfirmationModalOpened && (
              <ConfirmationModal
                isOpen={true}
                message={messages['group.delete.confirmation.message'](groupsUIStore.selectedGroup?.name)}
                note={messages['group.delete.confirmation.note']}
                confirmText={messages['group.delete.confirmation.confirmText']}
                onDecline={onConfirmationDecline}
                onConfirm={() =>
                  groupsUIStore.selectedGroup?.id && onConfirm(groupsUIStore.selectedGroup?.id)
                }
              />
            )
          }
        </>
      )}
    </Observer>
  )
};
