import React, { FC } from 'react';
import {
  useTable,
  useFlexLayout,
  useExpanded,
  useSortBy,
  TableOptions,
  TableState,
} from 'react-table';
import { useTheme } from 'react-jss';
import { CircularProgress } from '@material-ui/core';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import classNames from 'classnames';

import { useStyles } from './SimpleTable.styles';

type Props = {
  renderRowSubComponent?: Function,
  className?: string,
  isLoading?: boolean,
  initialState?: Partial<TableState>;
  fetchData?: (...args: any) => void,
};

export const SimpleTable: FC<Props & TableOptions<object>> = ({
  columns,
  data,
  renderRowSubComponent,
  className,
  isLoading,
  initialState,
  fetchData = () => {},
}) => {
  const instance = useTable(
    {
      columns,
      data,
      expandSubRows: false,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        sortBy: initialState?.sortBy,
      },
    },
    useFlexLayout,
    useSortBy,
    useExpanded,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    rows,
    state: {
      sortBy,
    },
  } = instance;

  React.useEffect(() => {
    fetchData({ sortBy });
  }, [sortBy])

  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <div
      className={classNames(styles.table, className, {[styles.tableDisabled]: isLoading})}
      {...getTableProps()}
    >
      <div>
        <div className={styles.header}>
          {
            isLoading && (
              <div className={styles.loadingWrapper}>
                <CircularProgress className={styles.loader} color='inherit' size={24} />
              </div>
            )
          }

          {headers.map(column => {
            const { key, style, ...props } = column.getHeaderProps(column.getSortByToggleProps({
              title: null,
            }));

            const { minWidth, width, maxWidth } = column;

            return (
              <div
                key={key}
                className={classNames(styles.headerCell,
                  {
                    [styles.withSorting]: column.canSort,
                  }
                )}
                style={{
                  ...style,
                  minWidth,
                  maxWidth: maxWidth || 'none',
                  // hack, set with in percents
                  flexBasis: width ? `${width}%`: 'auto',
                }}
                {...props}
              >
                <span>
                  {column.render('Header')}
                </span>

                {
                  column.canSort && !column.isSorted && (
                    <ArrowUpwardOutlinedIcon className={styles.sortPlaceholder} />
                  )
                }

                {
                  column.isSorted && (
                    <div className={styles.sortIndicator}>
                      <ArrowUpwardOutlinedIcon
                        className={classNames(styles.sortIcon, { [styles.sortedDesc]: column.isSortedDesc })}
                      />
                    </div>
                  )
                }
              </div>
            )
          })}
        </div>

        <div className={styles.body} {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...props } = row.getRowProps();

            return (
              <div key={key} className={styles.row} {...props}>
                <div className={styles.defaultRowContent}>
                  {row.cells.map(cell => {
                    const { key, ...props } = cell.getCellProps();
                    const { minWidth, width, maxWidth, disableEllipsis } = cell.column as any;
                    const { style, ...restProps } = props;

                    return (
                      <div
                        className={styles.cell}
                        key={key}
                        style={{
                          ...style,
                          minWidth,
                          maxWidth: maxWidth || 'none',
                          // hack, set with in percents
                          flexBasis: width ? `${width}%`: 'auto',
                        }}
                        {...restProps}
                      >
                        <span className={classNames({ [styles.ellipsis]: !disableEllipsis })}>
                          {cell.render('Cell')}
                        </span>
                      </div>
                    )
                  })}
                </div>
                {renderRowSubComponent && row.isExpanded && (
                  <div className={styles.expandedRowContent}>
                    {renderRowSubComponent({ row })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
