import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';

import {
  FONT_SECONDARY, RADIUS, SPACING_BASE,
  COLOR_VIOLET_PRIMARY, COLOR_BLACK,
  COLOR_GREY_LIGHT, COLOR_RED
} from '@styles/constants';
import { customScroll, flex } from '@resources/mixins';

export const customStyles = {
  menu: (provided: object) => ({
    ...provided,
    boxShadow: 'none',
    marginTop: 1,
    border: `1.5px solid ${COLOR_VIOLET_PRIMARY}`,

    '& > div': {
      maxHeight: 202,
      ...customScroll(),
    },
  }),
  singleValue: (provided: object) => ({
    ...provided,
    fontSize: 16,
    color: COLOR_BLACK,
  }),
  multiValue: (provided: object) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderRadius: RADIUS,
    height: 28,
    margin: SPACING_BASE,
    backgroundColor: color(COLOR_VIOLET_PRIMARY).fade(0.92).string(),
    maxWidth: 'calc(100% - 10px)',
  }),
  multiValueLabel: (provided: object) => ({
    ...provided,
    fontFamily: FONT_SECONDARY,
    lineHeight: 1.25,
    color: COLOR_VIOLET_PRIMARY,
    fontSize: 16,
  }),
  indicatorsContainer: (provided: object) => ({
    ...provided,
    alignItems: 'flex-start',
  }),
  dropdownIndicator: (provided: object, state: Record<string, any>) => ({
    ...provided,
    color: COLOR_VIOLET_PRIMARY,
    transition: 'transform .2s ease',
    transformOrigin: 'center',
    transform: state.selectProps.menuIsOpen ? 'scale(1, -1)' : 'scale(1, 1)',
  }),
  indicatorSeparator: (provided: object) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: object, state: Record<string, any>) => {
    const { error } = state.selectProps;

    return ({
      ...provided,
      borderWidth: 1.5,
      borderColor: error
        ? COLOR_RED
        : state.isFocused
          ? COLOR_VIOLET_PRIMARY
          : COLOR_GREY_LIGHT,
      boxShadow: 'none',
      maxHeight: 150,
      ...customScroll(),

      '&:hover': {
        borderColor: `${color(COLOR_VIOLET_PRIMARY).fade(0.62)}`,
      },
    })
  },
};

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  select: {
    '&:focus': {
      outline: 'none',
    },
  },
  wrapper: {
    position: 'relative',
  },
  labelWithError: {
    color: theme.colors.red,
  },
  errorMessage: {
    color: theme.colors.red,
    fontSize: 14,
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: -20,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  valueRemove: {
    height: '100%',
    color: theme.colors.violet_darker,
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    ...flex('row', 'center', 'center'),

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'inherit',
    },
  },
  valueRemoveIcon: {
    fontSize: 'inherit !important',
  },
}));
