import React from 'react';
import { FieldProps } from 'formik';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

import { BaseInputProps, APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../../BaseInputProps';
import { InputLabel } from '../InputLabel';
import { useStyles } from './ToggleInput.styles';
import { Switch } from '@material-ui/core';

export type ToggleInputProps = BaseInputProps & FieldProps;

export const ToggleInput:React.FC<ToggleInputProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const {
    field: { name, value },
    form, meta,
    label, isRequired,
    disabled,
    className, readOnly, styleVariant = APP_INNER_STYLE_VARIANT,
    type, helperText, autoFocus,
  } = props;

  const inputProps = {
    autoFocus,
    className: classNames(
      className,
      styles.input,
      { [styles.textarea]: type === 'textarea' },
      { [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT },
      { [styles.withError]: meta.touched && meta.error }
    ),
    color: 'secondary',
    size: 'medium',
    disabled,
    type: type || 'small',
    value,
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    readOnly,
  }

  return (
    <>
      {
        label && (
          <InputLabel
            label={label}
            isRequired={isRequired}
            styleVariant={styleVariant}
            className={classNames(
              { [styles.labelWithError]: meta.touched && meta.error }
            )}
          />
        )
      }

      <div className={styles.inputWrapper}>

        {
          //type === 'textarea'
          <Switch
            id={name}
            value={inputProps.value}
            color='primary'
            size='medium'
            onBlur={inputProps.onBlur}
            onChange={inputProps.onChange}
            readOnly={inputProps.readOnly}
            disabled={inputProps.disabled}
            name={name}
            autoFocus={inputProps.autoFocus} />
        }

      </div>

      {
        helperText && <div className={styles.helperWrapper}>{helperText}</div>
      }
    </>
  );
}
