import { FormSchema } from '@shared/components/forms/FormSchema';

import { UserFormValues } from './UserModal';
import { messages } from 'Users/users.messages';

type UserFormSchema = FormSchema<UserFormValues>;

export const FIELD_NAMES = {
  email: messages['userModal.email.label'],
  firstName: messages['userModal.firstName.label'],
  lastName: messages['userModal.lastName.label'],
  department: messages['userModal.department.label'],
  roleId: messages['userModal.roleId.label'],
  groups: messages['userModal.groups.label'],
  procedures: messages['userModal.procedures.label'],
}

export const valuesSchema: UserFormSchema = {
  email: {
    fieldName: 'email',
    label: FIELD_NAMES.email,
    isRequired: true,
    type: 'email',
  },
  firstName: {
    fieldName: 'firstName',
    label: FIELD_NAMES.firstName,
    isRequired: true,
  },
  lastName: {
    fieldName: 'lastName',
    label: FIELD_NAMES.lastName,
    isRequired: true,
  },
  department: {
    fieldName: 'department',
    label: FIELD_NAMES.department,
    isRequired: false,
  },
  roleId: {
    fieldName: 'roleId',
    label: FIELD_NAMES.roleId,
    isRequired: true,
    placeholder: '',
  },
  groups: {
    fieldName: 'groups',
    label: FIELD_NAMES.groups,
    isRequired: false,
    placeholder: '',
  },
  procedures: {
    fieldName: 'procedures',
    label: FIELD_NAMES.procedures,
    isRequired: false,
    placeholder: '',
  },
};
