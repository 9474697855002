import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { useTheme } from 'react-jss';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikPasswordInput } from '@shared/components/forms/formikWrappers/FormikPasswordInput';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { ToastMessage } from '@shared/components/Toast';

import { APP_INNER_STYLE_VARIANT } from '@shared/components/forms/BaseInputProps';

import { valuesSchema } from './valuesSchema';

import { messages } from 'AuthPart/authpart.messages';

import { useStyles } from './ConfirmWithPasswordModal.styles';
//import { ConfirmUIStore } from '../store';

export const COMMON_SELECT_PROPS = {
  isSearchable: false,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: false,
  closeMenuOnSelect: true,
  components: { Option: SelectOption },
}

export type ConfirmPasswordModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
  onSubmitPassword: (password: string) => Promise<boolean>,
  actionName: string,
}

export class ConfirmPasswordFormValues {
  password = '';
}

export const ConfirmPasswordModal: FC<ConfirmPasswordModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmitPassword,
  actionName,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  //const confirmUI = ConfirmUIStore.create({});

  const onSubmit = async (values: ConfirmPasswordFormValues) => {

    const success = await onSubmitPassword(values.password);

    if (success) {
      toast.success(
        <ToastMessage
          message={messages['confirmPasswordForm.action.success'](actionName)}
          type='success'
        />
      );
    } else {
      toast.error(
        <ToastMessage
          message={messages['confirmPasswordForm.action.fail'](actionName)}
          type='error'
        />
      );
    }
    onRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <h2 className={styles.h2}>
        {messages['confirmPasswordForm.label.title'](actionName)}
      </h2>
      <Formik
        onSubmit={onSubmit}
        initialValues={new ConfirmPasswordFormValues()}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {() => {

          return (
            <Form className={styles.form} autoComplete='off' noValidate>
              <div className={styles.field}>
                <FormikPasswordInput
                  schema={valuesSchema.password}
                  styleVariant={APP_INNER_STYLE_VARIANT}
                />
              </div>
              <div className={classNames(styles.buttons, { [styles.end]: true })}>
                <div>
                  <Button
                    onClick={onRequestClose}
                    className={styles.button} color="secondary"
                  >
                    {messages['confirmPasswordForm.button.cancel']}
                  </Button>
                  <Button
                    className={styles.button}
                    type="submit"
                  >
                    {messages['confirmPasswordForm.button.submit']}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
