import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';

import { ThemeProps } from '@styles/theme';
import { APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../../BaseInputProps';

export type InputLabelProps = {
  label: string;
  className?: string;
  isRequired?: boolean;
  styleVariant?: 'appExternal' | 'appInner',
};

const useStyles = createUseStyles((theme: ThemeProps) => ({
  appExternal: {},
  label: {
    fontSize: 16,
    lineHeight: 1.25,
    color: theme.colors.grey_text,
    fontFamily: theme.font.secondary,
    marginBottom: theme.spacing(1),
    '&$appExternal': {
      color: theme.colors.blue_primary,
      marginBottom: theme.spacing(2),
    },
  },

  requiredMark: {
    color: theme.colors.red,
    position: 'relative',
    top: -3,
    left: 1,
  },
}));

export const InputLabel:React.FC<InputLabelProps> = ({
  label, className, styleVariant = APP_INNER_STYLE_VARIANT, isRequired,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isExternal = styleVariant === APP_EXTERNAL_STYLE_VARIANT;

  return (
    <div className={classNames(
      styles.label,
      { [styles.appExternal]: isExternal },
      className
    )}>
      { label }
      {
        isRequired && !isExternal && <span className={styles.requiredMark}>*</span>
      }
    </div>
  );
}
