import { FormSchema } from '@shared/components/forms/FormSchema';

import { FormValues } from './FormValues';
import { messages } from 'AuthPart/authpart.messages';

type SetPasswordFormSchema = FormSchema<FormValues>;

export const valuesSchema: SetPasswordFormSchema = {
  password: {
    fieldName: 'password',
    label: messages['setPasswordForm.label.password'],
    isRequired: true,
    type: 'password',
  },
  confirmPassword: {
    fieldName: 'confirmPassword',
    label: messages['setPasswordForm.label.confirmPassword'],
    isRequired: true,
    type: 'password',
  },
};
