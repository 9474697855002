import classNames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import { DATE_MMM_DD } from '@shared/constants';
import { formatSecondsToTimeString } from '@shared/helpers/timeFormatters';
import { messages } from 'Analytics/analytic.messages';
import { useStyles } from './AnalyticsLineChart.styles';

type TooltipProps = {
  type?: string,
  payload?: {
    value: number,
    payload: Record<string, any>
  }[],
  label?: string,
  active?: boolean,
}

export const CustomizedTooltip: FC<TooltipProps> = ({
  payload,
  label,
  active,
}) => {
  const theme = useTheme();
  const styles: Record<string, any> = useStyles(theme);
  const DATA_KEYS = ['usrBest', 'allAverage', 'usr1', 'usr2', 'usr3'];

  const rowsData = payload && payload.length && payload[0].payload;

  if (active) {
    return (
      <div className={styles.tooltip} data-testid="customized-tooltip">
        <div className={styles.label}>{label}</div>
        {DATA_KEYS.map(key => (
          <div className={styles.row} key={key}>
            <p className={styles.date}>
              {key === 'allAverage'
                ? messages['lineChart.average']
                : rowsData && rowsData[key] && moment(rowsData[key].date).format(DATE_MMM_DD)
              }
            </p>
            <p className={classNames(styles.time, styles[key])}>
              {rowsData && rowsData[key] && formatSecondsToTimeString(rowsData[key].time)}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};
