import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetCompanyParams = {
  id: string;
};

export const performGetCompanies = async (api: IApiStore, params: EndpointCallParams<GetCompanyParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .get('identity-app/api/v1/Companies/', {
      ...rest,
      payload,
    })

  return req;
}

export const performGetCompany = async (api: IApiStore, params: EndpointCallParams<GetCompanyParams>) => {
  const { payload, ...rest } = params;
  const { id, ...restOfPayload } = payload;
  const req = api
    .get(`identity-app/api/v1/Companies/${id}`, {
      ...rest,
      ...restOfPayload,
    })

  return req;
}
