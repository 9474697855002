import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type EditGroupParams = {
  id: string;
  name: string;
  users: string[];
  procedures: string[];
};

export const performEditGroup = async (api: IApiStore, params: EndpointCallParams<EditGroupParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .put(`uiaggregator-app/api/v1/Groups/${payload.id}`, {
      ...rest,
      payload: {
        ...payload,
      },
    })

  return req;
}
