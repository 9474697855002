import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  withError: {},
  appExternal: {},

  labelWithError: {
    color: theme.colors.red,
  },

  inputWrapper: {
    position: 'relative',
  },

  inlineWrapper: {
    position: 'relative',
    flexDirection: 'row',
  },

  textarea: {
    resize: 'vertical',
    minHeight: 90,
  },

  input: {
    borderRadius: theme.radius,
    width: '100%',
    fontSize: 16,
    lineHeight: '24px',
    height: 40,
    //border: `1.5px solid ${theme.colors.grey_light}`,
    padding: [theme.spacing(2), theme.spacing(3)],
    color: theme.colors.black,
    '&:focus': {
      boxShadow: 'none',
      borderColor: theme.colors.violet_primary,
      outline: 'none',
    },
    '&:hover': {
      borderColor: `${color(theme.colors.violet_primary).fade(0.62)}`,
    },
    '&$appExternal': {
      backgroundColor: 'rgba(9, 237, 245, 0.12)',
      border: '1px solid transparent',
      height: 52,
      color: theme.colors.white,
      padding: [
        theme.spacing(4), theme.spacing(10.5),
        theme.spacing(4), theme.spacing(3)
      ],
      '&:hover': {
        background: 'rgba(9, 237, 245, 0.2)',
      },
      '&:focus': {
        boxShadow: `0 0 4px 0 ${theme.colors.blue_neon}`,
        borderColor: theme.colors.blue_primary,
        outline: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: theme.colors.blue_neon,
      },
    },

    '&$withError': {
      borderColor: theme.colors.red,
    },
  },

  errorMessage: {
    color: theme.colors.red,
    fontSize: 14,
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: -20,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  helperWrapper: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.colors.blue_primary,
    fontFamily: theme.font.secondary,
    marginTop: theme.spacing(4),
  },
}));
