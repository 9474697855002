import { Instance, SnapshotIn, types, getSnapshot } from 'mobx-state-tree';

import { UserGroup } from 'Users/stores/UserGroup.store';
import { ProcedureDevice } from './ProcedureDevice.store';

const ProcedureHistory = types.model({
  date: types.string,
  description: types.string,
});

const ProcedureMedia = types.model({
  src: types.string,
  description: types.string,
  height: types.integer,
  width: types.integer,
  size: types.number,
});

const ProcedureUser = types.model({
  id: types.string,
  name: types.string,
});

export const ListProcedureStoreInferred = types
  .model('ListProcedureStoreInferred', {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.string),
    collaboration: types.maybeNull(types.boolean),
    executionsCount: types.maybeNull(types.number),
    availableModes: types.optional(types.array(types.string), []),
    groups: types.optional(types.array(UserGroup), []),
    users: types.optional(types.array(ProcedureUser), []),
    history: types.optional(types.array(ProcedureHistory), []),
    devices: types.optional(types.array(ProcedureDevice), []),
    procedurePreview: types.maybeNull(ProcedureMedia),
    procedureMedias: types.maybeNull(types.array(ProcedureMedia)),
    companyId: types.optional(types.string, ''),
    ownership: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  })
  .views(self => ({
    get getProcedureData() {
      return getSnapshot(self);
    },
  }));

type ListProcedureStoreFactoryType = typeof ListProcedureStoreInferred;
interface IListProcedureStoreFactory extends ListProcedureStoreFactoryType {}
export const ListProcedureStore: IListProcedureStoreFactory = ListProcedureStoreInferred;
export interface IListProcedureStore extends Instance<IListProcedureStoreFactory> {}
export interface IListProcedureStoreSnapshotIn extends SnapshotIn<IListProcedureStore> {}
