import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';
import { InviteUserParams, ResendInviteUserParams } from 'Users/stores/InviteUser.store';

type AdditionalCallParams = {
  returnUrl: string
}

export type InviteUserCallParams = InviteUserParams & AdditionalCallParams

export const performInviteUserRequest = (
  api: IApiStore, params: EndpointCallParams<InviteUserCallParams>, query?: Record<string, any>
) => {
  const req = api
    .post('uiaggregator-app/api/v1/Users',
      params,
      query
    )

  return req;
}

export const performResendInviteUserRequest = (
  api: IApiStore, params: EndpointCallParams<ResendInviteUserParams>
) => {
  return api.post('identity-app/api/v1/Account/ResendInvite', params)
}
