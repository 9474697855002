import { Instance, SnapshotIn, types, getEnv, flow } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';
import { performUploadAvatar, performDeleteAvatar, performGetAvatar } from 'Profile/services';

export const ProfileUIStoreInferred = types
  .model({})
  .volatile(() => ({
    status: DataStateStore.create({ state: DataState.initial }),
  }))
  .views(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { auth } = getEnv(api);

    return {
      get userInfo() {
        return auth.userProfile;
      },
    }
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { auth } = getEnv(api);

    const uploadAvatar = flow(function* (file: File) {
      self.status.setLoading();

      const result = yield performUploadAvatar(
        api,
        {
          payload: { file },
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      self.status.setDone();

      return result;
    });

    const deleteAvatar = flow(function* () {
      self.status.setLoading();

      const result = yield performDeleteAvatar(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      self.status.setDone();

      if (result.success) {
        auth.setAvatar(null);
        auth.storeUserInfo();
      }

      return result;
    });

    const getAvatar = flow(function* () {
      self.status.setLoading();

      const result = yield performGetAvatar(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      self.status.setDone();

      if (result.success) {
        auth.setAvatar(result.data.avatar);
        auth.storeUserInfo();
      }

      return result;
    });

    return {
      uploadAvatar,
      deleteAvatar,
      getAvatar,
    }
  })

type ProfileUIStoreFactoryType = typeof ProfileUIStoreInferred;
interface IProfileUIStoreFactory extends ProfileUIStoreFactoryType {}
export const ProfileUIStore: IProfileUIStoreFactory = ProfileUIStoreInferred;
export interface IProfileUIStore extends Instance<IProfileUIStoreFactory> {}
export interface IProfileUIStoreSnapshotIn extends SnapshotIn<IProfileUIStore> {}
