export const messages = {
  'deviceActionConfirmation.default.cancelText': 'Cancel',
  'deviceActionConfirmation.default.confirmText': 'Confirm',
  'procedureDetailsForm.update.success': 'The procedure was successfully updated',
  'procedureDetailsForm.create.success': 'The procedure was successfully created',
  'procedureDevices.uploading': (progress: number) => `Uploading...${progress}%`,
  'procedureDevices.processing': 'Processing...',
  'procedureDevices.addDevice.tooltip': 'Add new device',
  'procedureFileView.default.uploadError': 'Something went wrong. Please try again.',
  'procedureFileView.error.format': 'The file has wrong format. File should be with .weavr, .wvr extensions',
  'procedureFileView.dropProcedureHere': 'Drop procedure file here or',
  'procedureFileView.browse': 'Browse',
  'procedureFileView.upload.inProgress': 'Upload in progress...',
  'procedureFileView.processing.inProgress': 'Processing in progress...',
  'procedureFileView.cancel': 'Cancel',
  'procedureFileView.validationFailed': 'Validation failed',
  'procedureFileView.default.deviceError': 'Something went wrong. Please try again.',
  'procedureFileView.uploadNewFile': 'Upload new file',
  'procedureFileView.deleteDevice': 'Delete device',
  'procedureFileView.device.update': 'Update',
  'procedureFileView.device.replace': 'Replace',
  'procedureFileView.device.replace.confirmation': (name?: string | null) =>
    `Are you sure you want to replace ${name} with a different file?`,
  'procedureFileView.device.delete': 'Delete device',
  'procedureFileView.device.delete.confirmation': (name?: string | null) =>
    `Are you sure you want to delete ${name}?`,
  'procedureFileView.device.delete.confirmation.confirmText': 'Delete',
  'procedureModal.loading': 'Loading...',
  'procedureModal.newProcedure': 'New procedure',
  'procedureModal.devices': 'Devices',
  'procedureModal.procedureDetails': 'Procedure details',
  'procedureModal.procedure.delete': 'Delete procedure',
  'procedureModal.cancel': 'Cancel',
  'procedureModal.next': 'Next',
  'procedureModal.save': 'Save',
  'procedureModal.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'procedureModal.name.label': 'Procedure name',
  'procedureModal.description.label': 'Description',
  'procedureModal.groups.label': 'Assigned to groups',
  'procedureModal.users.label': 'Assigned to users',
  'procedureModal.ownership.label': 'Ownership',
  'proceduresFiltersBar.devices.label': 'Device',
  'proceduresFiltersBar.modes.label': 'Mode',
  'proceduresFiltersBar.types.label': 'Type',
  'proceduresFiltersBar.groupIds.label': 'Group',
  'proceduresFiltersBar.collaboration.label': 'Procedure',
  'proceduresFiltersBar.lastModifiedStart.label': 'Last modified',
  'proceduresFiltersBar.executionsMin': (count: number) => `Executions min: ${count}`,
  'proceduresFiltersBar.executionsMax': (count: number) => `Executions max: ${count}`,
  'proceduresFiltersBar.collaboration': (collaboration: boolean) =>
    `Collaboration: ${collaboration ? 'Available' : 'Unavailable'}`,
  'proceduresFiltersModal.option.available': 'Available',
  'proceduresFiltersModal.option.notAvailable': 'Unavailable',
  'proceduresFiltersModal.title': 'Filter procedures list',
  'proceduresFiltersModal.cancel': 'Cancel',
  'proceduresFiltersModal.apply': 'Apply filter',
  'proceduresFiltersModal.validation.min': (label?: string) =>
    `${label} must be greater or equal to 0`,
  'proceduresFiltersModal.validation.max': (label?: string) =>
    `${label} must be less or equal to 1000000`,
  'proceduresFiltersModal.validation.integer': (label?: string) =>
    `${label} must be an integer`,
  'proceduresFiltersModal.validation.minMaxDifference': 'Min value must be less than max',
  'proceduresFiltersModal.devices.label': 'Devices',
  'proceduresFiltersModal.modes.label': 'Modes',
  'proceduresFiltersModal.types.label': 'Type',
  'proceduresFiltersModal.collaboration.label': 'Collaboration',
  'proceduresFiltersModal.executionsMin.label': 'Executions count (min)',
  'proceduresFiltersModal.executionsMax.label': 'Executions count (max)',
  'proceduresFiltersModal.groupIds.label': 'Access by Groups',
  'proceduresFiltersModal.lastModified.label': 'Last modified',
  'proceduresPage.noInfo': 'No information',
  'proceduresPage.table.header.name': 'Procedure name',
  'proceduresPage.table.header.devices': 'Devices',
  'proceduresPage.table.header.availableModes': 'Modes',
  'proceduresPage.table.header.type': 'Type',
  'proceduresPage.table.header.collaboration': 'Collaboration',
  'proceduresPage.table.header.executionsCount': 'Executions',
  'proceduresPage.available': 'Available',
  'proceduresPage.notAvailable': 'Unavailable',
  'proceduresPage.editProcedure': 'Edit procedure',
  'proceduresPage.subRow.title': 'Description',
  'proceduresPage.subRow.devices': 'Devices',
  'proceduresPage.subRow.access': 'Access',
  'proceduresPage.subRow.history': 'Change history',
  'proceduresPage.delete.success.toast': 'The procedure was successfully deleted',
  'proceduresPage.title': 'Procedures',
  'proceduresPage.addProcedure.title': 'Add new procedure',
  'proceduresPage.filter.title': 'Filter',
  'proceduresPage.delete.message': 'Are you sure you want to delete procedure?',
  'proceduresPage.delete.confirmText': 'Delete procedure',
  'proceduresPage.noProceduresInGroup': 'There are no procedures in this group',
  'proceduresSidebar.button.allProcedures': 'All procedures',
  'proceduresSidebar.button.public': 'Public',
  'proceduresSidebar.button.unassigned': 'Unassigned',
  'proceduresSidebar.button.private': 'Private',
  'proceduresSummary.total.title': 'Total',
  'proceduresSummary.total.text': (count: number | string) => `${count} ${count == 1 ? 'Procedure' : 'Procedures'}`,
  'proceduresSummary.average.title': 'On average',
  'proceduresSummary.average.text': (count: number | string) => `${count} Executions`,
  'proceduresSummary.spent.title': 'Average Time',
  'proceduresSummary.spent.text': (count: number | string) => `${count} Minutes`,
}
