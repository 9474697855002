import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  wrapper: {
    backgroundColor: theme.colors.white,
    padding: [theme.spacing(8), theme.spacing(6)],
    border: `1.5px solid ${theme.colors.grey_light}`,
    borderRadius: theme.radius,
    flexBasis: 444,
  },

  heading: {
    marginBottom: theme.spacing(8),
  },

  avatarWrapper: {
    marginBottom: theme.spacing(4),
    ...flex('row', 'center', 'center'),
  },

  customAvatar: {
    width: '100%',
    minHeight: 340,
  },

  loader: {
    position: 'absolute',
    color: theme.colors.white,
  },

  uploadButton: {
    margin: [theme.spacing(6), 0, theme.spacing(4)],
  },

  uploadError: {
    color: theme.colors.red,
  },
}));
