import React, { useState } from 'react';
import { useTheme } from 'react-jss';
import { ClickOutsideListener } from '@ace/core';
import { getEnv } from 'mobx-state-tree';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { useUserProfileUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Avatar } from '@shared/components/Avatar';
import { DropdownMenu } from '@shared/components/DropdownMenu';

import WhiteArrowUpIcon from '@assets/white-arrow-up.svg';
import { messages } from '../../header.messages';
import { useStyles } from './UserMenu.styles';

export const UserMenu = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const userProfileUIStore = useUserProfileUIStore();
  const { navigator } = getEnv<IStoresEnv>(userProfileUIStore);
  const [dropdownOpen, toggleDropdownOpen] = useState(false);

  const signOutUser = () => userProfileUIStore.signOut();
  const goToProfile = () => {
    toggleDropdownOpen(false);
    navigator.to(RoutePaths.profile);
  };

  return (
    <section className={styles.wrapper}>
      <span className={styles.userName}>
        {userProfileUIStore.userInfo?.fullName}
      </span>

      <Observer>
        {() => (
          <Avatar
            firstName={userProfileUIStore.userInfo?.firstName || ''}
            lastName={userProfileUIStore.userInfo?.lastName || ''}
            image={userProfileUIStore.userInfo?.avatar}
          />
        )}
      </Observer>

      <ClickOutsideListener
        outsideClickIgnoreClass={styles.dropdown}
        onClickOutside={() => toggleDropdownOpen(false)}
      >
        <button
          onClick={() => toggleDropdownOpen(!dropdownOpen)}
          className={classNames(styles.button, { [styles.rotated]: dropdownOpen })}
        >
          <img src={WhiteArrowUpIcon} />
        </button>

        <DropdownMenu
          isOpen={dropdownOpen}
          className={styles.dropdown}
          items={[
            {
              id: 0,
              title: messages['userMenu.profile'],
              onClick: goToProfile,
            },
            {
              id: 1,
              title: messages['userMenu.logout'],
              onClick: signOutUser,
            }
          ]}
        />
      </ClickOutsideListener>
    </section>
  );
}
