import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { messages } from '@shared/components/components.messages';

// TODO use generic type to avoid keyName and valueName
export type ExpandableListProps = {
  list: any[];
  limit?: number;
  keyName?: string;
  valueName?: string;
  itemRenderer?: Function;
}

const DEFAULT_LIMIT = 4;
const DEFAULT_KEY_NAME = 'id';
const DEFAULT_VALUE_NAME = 'name';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  toggleButton: {
    color: theme.colors.blue_link,
    marginTop: theme.spacing(2),
    cursor: 'pointer',
  },

  valueLine: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const ExpandableList: React.FC<ExpandableListProps> = ({
  list, limit = DEFAULT_LIMIT, keyName = DEFAULT_KEY_NAME,
  valueName = DEFAULT_VALUE_NAME, itemRenderer,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [itemsToShow, setItemsToShow] = useState(limit);

  const toggleVisibleElements = () =>
    setItemsToShow(itemsToShow === limit ? list.length : limit);

  return (
    <>
      {
        list.slice(0, itemsToShow).map((item, index) =>
          <div
            key={item[keyName] || index}
            className={styles.valueLine}
            title={item[valueName]}
            data-testid="expandable-list-item"
          >
            {itemRenderer ? itemRenderer(item[valueName], index) : item[valueName]}
          </div>
        )
      }

      {
        limit < list.length && (
          <div
            className={styles.toggleButton}
            onClick={toggleVisibleElements}
            data-testid="expandable-list-toggle-button"
          >
            {messages['expandableList.toggleButton'](itemsToShow === limit)}
          </div>
        )
      }
    </>
  )
};

ExpandableList.displayName = 'ExpandableList';
