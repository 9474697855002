import moment from 'moment';
import { Instance, SnapshotIn, types, getSnapshot, applySnapshot } from 'mobx-state-tree';

import { UserAnalyticsPageQueryParams } from '../domain/UserAnalyticsPageQueryParams'

export const requestParamsInitialState = {
  procedureId: null,
  procedureVersionId: '',
  procedureVersionPlatformId: '',
  userId: null,
  startDate: moment.utc().subtract(100, 'years').startOf('day').toISOString(),
  endDate: moment.utc().startOf('day').toISOString(),
}

export const UserAnalyticPageQueryStoreInferred = types
  .model({
    procedureId: types.maybeNull(types.string),
    procedureVersionId: types.optional(types.string, ''),
    procedureVersionPlatformId: types.optional(types.string, ''),
    userId: types.maybeNull(types.string),
    startDate: types.optional(types.string, requestParamsInitialState.startDate),
    endDate: types.optional(types.string, requestParamsInitialState.endDate),
  })
  .views(self => ({
    get getParams() {
      return getSnapshot(self);
    },
  }))
  .actions(self => {
    const setParams = (params: UserAnalyticsPageQueryParams) => {
      applySnapshot(self, Object.assign({}, self.getParams, params));
    };

    const resetParams = () => {
      applySnapshot(self, requestParamsInitialState);
    };

    return {
      setParams,
      resetParams,
    }
  })

type UserAnalyticPageQueryStoreFactoryType = typeof UserAnalyticPageQueryStoreInferred;
interface IUserAnalyticPageQueryStoreFactory extends UserAnalyticPageQueryStoreFactoryType {}
export const UserAnalyticPageQueryStore: IUserAnalyticPageQueryStoreFactory = UserAnalyticPageQueryStoreInferred;
export interface IUserAnalyticPageQueryStore extends Instance<IUserAnalyticPageQueryStoreFactory> {}
export interface IUserAnalyticPageQueryStoreSnapshotIn extends SnapshotIn<IUserAnalyticPageQueryStore> {}
