import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UserRoleInferred = types
  .model('UserRoleInferred', {
    id: types.identifier,
    name: types.string,
  });

type UserRoleFactoryType = typeof UserRoleInferred;
interface IUserRoleFactory extends UserRoleFactoryType {}
export const UserRole: IUserRoleFactory = UserRoleInferred;
export interface IUserRole extends Instance<IUserRoleFactory> {}
export interface IUserRoleSnapshotIn extends SnapshotIn<IUserRole> {}
