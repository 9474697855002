import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export const performGetUsersStatistics = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { payload, ...rest } = params;
  const req = api
    .get('uiaggregator-app/api/v1/Users/Statistics', {
      ...rest,
      payload: { ...payload },
    })

  return req;
}
