import { createUseStyles } from 'react-jss';
import color from 'color';
import ArrowBackIcon from '@assets/arrow-back.svg';
import { flex } from '@resources/mixins';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  '@global': {
    '.rdrDateDisplayWrapper, .rdrInputRanges': {
      display: 'none',
    },
    '.rdrMonths': {
      flexDirection: 'row',
    },
    '.rdrMonth': {
      width: 290,
      paddingBottom: 0,
      position: 'relative',
      padding: [0, theme.spacing(3)],
    },
    '.rdrMonth:first-child:before': {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: 1,
      height: 'calc(100% + 40px)',
      backgroundColor: theme.colors.grey_light,
    },
    '.rdrDayNumber': {
      color: theme.colors.black,
      position: 'relative',
      top: 'auto',
      right: 'auto',
      left: 'auto',
      bottom: 'auto',
      lineHeight: 2,
      fontSize: 12,
      width: 34,
      height: 34,
      margin: '0 auto',
    },
    '.rdrMonthAndYearWrapper': {
      width: 290,
      height: 40,
      paddingTop: 0,
    },
    '.rdrMonthAndYearPickers select': {
      width: 120,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      lineHeight: 1.5,
      color: theme.colors.black,
    },
    '.rdrMonthAndYearPickers select:hover': {
      backgroundColor: 'transparent',
    },
    '.rdrYearPicker select': {
      width: 70,
    },
    '.rdrYearPicker': {
      margin: '0 !important',
    },
    '.rdrNextPrevButton': {
      minWidth: 24,
      position: 'relative',
      background: 'none',
    },
    '.rdrNextPrevButton:hover': {
      backgroundColor: theme.colors.transp_violet,
    },
    '.rdrNextPrevButton i, .rdrNextPrevButton i': {
      border: 'none',
    },
    '.rdrNextPrevButton:before, .rdrNextPrevButton:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: 24,
      height: 24,
      backgroundImage: `url(${ArrowBackIcon})`,
      transformOrigin: 'center',
    },
    '.rdrPprevButton:before': {
      transform: 'scale(-1, 1)',
    },
    '.rdrDayStartPreview': {
      background: `${color(theme.colors.grey_light).fade(0.75)}`,
    },
    '.rdrDayInPreview': {
      background: `${color(theme.colors.grey_light).fade(0.75)}`,
    },
    '.rdrDayEndPreview': {
      background: `${color(theme.colors.grey_light).fade(0.75)}`,
    },
    '.rdrDay': {
      height: 40,
    },
    '.rdrStaticRange': {
      borderBottom: 'none',
    },
    '.rdrStaticRangeLabel': {
      fontFamily: theme.font.secondary,
      fontSize: 16,
      lineHeight: 1.25,
      color: theme.colors.violet_primary,
      padding: [theme.spacing(2), theme.spacing(6)],
    },
    '.rdrDefinedRangesWrapper': {
      borderRight: 'none',
    },
    '.rdrWeekDay': {
      color: theme.colors.grey_text,
    },
    '.rdrDay.rdrDayHovered .rdrDayNumber': {
      background: theme.colors.white,
      borderRadius: '50%',
      fontWeight: 'bold',
      color: `${theme.colors.black} !important`,
    },
    '.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span': {
      color: theme.colors.black,
    },
    '.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span': {
      color: theme.colors.black,
    },
    '.rdrDay:not(.rdrDayPassive) ~ .rdrDayToday .rdrDayNumber span': {
      color: theme.colors.black,
      fontWeight: 'bold',
    },
    '.rdrDay:not(.rdrDayPassive) ~ .rdrDayToday .rdrDayNumber span:after': {
      background: theme.colors.black,
    },
    '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
      color: theme.colors.black,
    },
  },
  body: {
    marginBottom: theme.spacing(4),
    padding: [0, theme.spacing(4)],
    ...flex('', 'space-between', 'center'),
    position: 'relative',
  },
  calendar: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 45,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: 766,
    padding: [theme.spacing(4), 0, theme.spacing(4), theme.spacing(3)],
    backgroundColor: theme.colors.white,
    border: `1.5px solid ${theme.colors.violet_primary}`,
    borderRadius: theme.radius,
  },
  padding: {},
  calendarIcon: {
    width: 38,
    height: 38,
    ...flex('column', 'center', 'center'),
    borderRadius: [theme.radius, 0, 0, theme.radius],
    color: theme.colors.violet_primary,
  },
  hidden: {
    display: 'none',
  },
  field: {
    ...flex('', '', 'center'),
    paddingRight: theme.spacing(2),
    height: 38,
    margin: 1.5,
    border: 'none',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    cursor: 'default',
  },
  fields: {
    border: `1.5px solid ${theme.colors.grey_light}`,
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius,
    transition: 'borderColor .2s ease',
    '&:hover': {
      borderColor: `${color(theme.colors.violet_primary).fade(0.62)}`,
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.font.secondary,
    fontSize: 16,
    lineHeight: 1.25,
    color: theme.colors.grey_text,
    marginRight: theme.spacing(4),
  },
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    width: 150,
    borderLeft: `1px solid ${theme.colors.grey_lighter}`,
  },
  button: {
    marginLeft: theme.spacing(6),
  },
}));
