import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { SelectDropdown } from '@shared/components/SelectDropdown';
import { FormSchemaItem } from '../../FormSchema';
import { OptionType } from '@shared/components/SelectDropdown';

export type FormikSelectDropdownProps = {
  schema: FormSchemaItem,
  className?: string,
  options: Array<object>,
  components?: object,
  closeMenuOnSelect?: boolean,
  hideSelectedOptions?: boolean,
  isMulti?: boolean,
  isClearable?: boolean;
  isSearchable?: boolean;
  onChange?: (value: OptionType, fieldName: string,) => void,
  value?: string,
  disabled?: boolean,
}

export const FormikSelectDropdown: FC<FormikSelectDropdownProps> = props => {
  const { schema, ...dropdownProps } = props;

  return (
    <Field name={schema.fieldName}>
      {({ field, form, meta }: FieldProps) => (
        <SelectDropdown
          field={field}
          form={form}
          meta={meta}
          error={meta.error}
          {...schema}
          {...dropdownProps}
        />
      )}
    </Field>
  )
}
