import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';

import { UserGroup } from './UserGroup.store';
import { UserRole } from './UserRole.store';
import { UserStatus } from './UserStatus.store';
import { performEditUser, performGetUser, performDeleteUser } from 'Users/services';
import { IStoresEnv } from '@core/storesEnv';

const UserProcedure = types.model({
  id: types.string,
  name: types.string,
})

export const userInitialState = {
  id: '',
  authUserId: '',
  companyId: '',
  createdAt: null,
  deletedAt: null,
  updatedAt: null,
  lastLoginAt: null,
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  department: '',
  avatar: null,
  status: { id: '', name: '' },
  groups: [],
  groupIds: [],
  roles: [],
  roleId: [],
  procedures: [],
  procedureIds: [],
}

export const UserStoreInferred = types
  .model('UserStoreInferred', {
    id: types.optional(types.string, ''),
    authUserId: types.optional(types.string, ''),
    companyId: types.optional(types.string, ''),
    createdAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    lastLoginAt: types.maybeNull(types.string),
    email: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    fullName: types.optional(types.string, ''),
    avatar: types.maybeNull(types.string),
    department: types.optional(types.string, ''),
    status: types.optional(UserStatus, { id: '', name: '' }),
    groups: types.optional(types.array(UserGroup), []),
    groupIds: types.optional(types.array(types.string), []),
    roles: types.optional(types.array(UserRole), []),
    roleId: types.optional(types.array(types.string), []),
    procedures: types.optional(types.array(UserProcedure), []),
    procedureIds: types.optional(types.array(types.string), []),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const editUser = flow(function* (payload: any) {
      const result = yield performEditUser(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      return result;
    });

    const loadUser = flow(function* (payload: any) {
      const result = yield performGetUser(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    const deleteUser = flow(function* (payload: any) {
      const result = yield performDeleteUser(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    return {
      editUser,
      loadUser,
      deleteUser,
    }
  })

type UserStoreFactoryType = typeof UserStoreInferred;
interface IUserStoreFactory extends UserStoreFactoryType {}
export const UserStore: IUserStoreFactory = UserStoreInferred;
export interface IUserStore extends Instance<IUserStoreFactory> {}
export interface IUserStoreSnapshotIn extends SnapshotIn<IUserStore> {}
