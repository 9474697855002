import React from 'react';
import { TextInputProps } from './inputs/TextInput';

export const APP_EXTERNAL_STYLE_VARIANT = 'appExternal';
export const APP_INNER_STYLE_VARIANT = 'appInner';

export interface BaseInputProps {
  label?: string;
  isRequired?: boolean;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  className?: string;
  isNumeric?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  wrapperClassName?: string;
  type?: string;
  min?: number;
  max?: number;
  cleanable?: boolean;
  helperText?: string;
  restrictEngine?(value: any): any;
  styleVariant?: 'appExternal' | 'appInner',
  suffix?: (props: TextInputProps) => React.ReactNode,
  prefix?: (props: TextInputProps) => React.ReactNode,
}
