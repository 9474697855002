import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export const performGetAvatar = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const req = api
    .get('identity-app/api/v1/Users/UserPhoto', {
      ...params,
      payload: {},
    })

  return req;
}
