import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { RoutePaths } from '../routes/RoutePaths';
import headerBackground from '@assets/header-background.png';
import { ThemeProps } from '@styles/theme';
import { Header } from '../../Header';
import { Groups } from '../../Groups';
import { GroupsHeading } from 'Groups/components/GroupsHeading';
import { UsersSidebar } from 'Users/components/UsersSidebar';
import { ProceduresSidebar } from 'Procedures/components/ProceduresSidebar';
import { AnalyticsSidebar } from 'Analytics/components/AnalyticsSidebar';

type AppLayoutProps = {
  children: React.ReactNode;
  showSidebar?: boolean;
  path?: string;
}

const SIDEBAR_COMPONENTS = {
  [RoutePaths.users]: <UsersSidebar />,
  [RoutePaths.procedures]: <ProceduresSidebar />,
  [RoutePaths.analytics]: <AnalyticsSidebar />,
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  outerWrapper: {},

  innerWrapper: {
    display: 'flex',
    padding: [0, theme.spacing(8)],
    maxWidth: 1440,
    minHeight: 'calc(100vh - 208px)',
    margin: '0 auto',
    '@media (max-width: 1200px)': {
      padding: [0, theme.spacing(5)],
    },
  },

  aside: {
    width: 202,
    flexShrink: 0,
    marginRight: theme.spacing(8),
    padding: [theme.spacing(8), 0],
    '@media (max-width: 1200px)': {
      width: 147,
      marginRight: theme.spacing(5),
    },
  },

  mainContent: {
    width: '100%',
    padding: [theme.spacing(8), 0, theme.spacing(12)],
  },

  footer: {
    height: 64,
    backgroundColor: theme.colors.violet_darkest,
    backgroundImage: `url(${headerBackground})`,
    backgroundSize: 'cover',
  },
}));

export const AppLayout: React.FC<AppLayoutProps> = ({
  children, showSidebar = true, path = '',
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <section className={styles.outerWrapper}>
      <Header />

      <section className={styles.innerWrapper}>
        <aside className={styles.aside}>
          {
            showSidebar && (
              <>
                <GroupsHeading />
                { SIDEBAR_COMPONENTS[path] }
                <Groups />
              </>
            )
          }
        </aside>

        <section className={styles.mainContent}>
          { children }
        </section>
      </section>

      <footer className={styles.footer} />
    </section>
  )
}
