import React, { FC } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { Observer } from 'mobx-react';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import { useProcedureUIStore } from '@core/useStores';
import { useStyles } from '../../ProcedureModal.styles';
import { ProcedureFileView } from '../ProcedureFileView';
import { UploadedFileResponse } from 'Procedures/domain/FileResponse';
import { IProcedureDeviceUI } from 'Procedures/stores';
import { messages } from 'Procedures/procedures.messages';
import { IProcedureGeneralInfo } from 'Procedures/stores/ProcedureGeneralInfo.store';

export type ProcedureDevicesProps = {
  className?: string,
  isEditMode: boolean,
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TabPanel = (props: {
  children: React.ReactNode,
  value: number,
  index: number,
}) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

export const ProcedureDevices: FC<ProcedureDevicesProps> = ({
  className, isEditMode,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const procedureUIStore = useProcedureUIStore();

  const handleTabChange = (_event: any, newValue: number) => {
    procedureUIStore.setCurrentDeviceIndex(newValue);
  };

  const addNewDeviceTab = () => {
    procedureUIStore.addDevice();
  };

  const onFileUpload = (fileInfo: UploadedFileResponse) => {
    if (!procedureUIStore.procedureGeneralInfo) {
      const preparedData = {
        name: fileInfo.procedureName,
        description: fileInfo.procedureDescription,
      } as IProcedureGeneralInfo;

      procedureUIStore.setProcedureGeneralInfo(preparedData);
    }
  };

  return (
    <Observer>
      {() => {
        return (
          <div className={classNames(className)}>
            <div className={styles.tabsHeader}>
              <Tabs
                value={procedureUIStore.currentDeviceIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={styles.tabsWrapper}
              >
                {
                  procedureUIStore.devices.map((device: IProcedureDeviceUI, index: number) => {
                    return (
                      <Tab
                        label={
                          device.isLoading
                            ? device.progress < 100
                              ? messages['procedureDevices.uploading'](device.progress)
                              : messages['procedureDevices.processing']
                            : device.name
                        }
                        {...a11yProps(index)}
                        key={index}
                      />
                    )
                  })
                }
              </Tabs>

              <span
                className={styles.addDeviceButtonWrapper}
                title={messages['procedureDevices.addDevice.tooltip']}
              >
                <AddOutlinedIcon
                  style={{ fontSize: 24 }}
                  className={classNames(
                    styles.addDeviceButton,
                    { [styles.addDeviceButtonDisabled]: procedureUIStore.isNewDeviceAddDisabled }
                  )}
                  onClick={() => !procedureUIStore.isNewDeviceAddDisabled && addNewDeviceTab()}
                />
              </span>
            </div>
            {
              procedureUIStore.devices.map((device, index) => {
                return (
                  <TabPanel
                    index={index}
                    key={device.uiId}
                    value={procedureUIStore.currentDeviceIndex}
                  >
                    <ProcedureFileView
                      isEditMode={isEditMode}
                      device={device}
                      onFileUpload={onFileUpload}
                      index={index}
                    />
                  </TabPanel>
                )
              })
            }
          </div>
        )
      }}
    </Observer>
  )
}
