import { ISelectOption } from './ISelectOption';

export const getOptionsByStrings = (value: string[], options: ISelectOption[]) => {
  if (!options || !options.length) {
    return [];
  }

  return value.map(value => getOptionByString(value, options));
}

export const getOptionByString = (value: string, options: ISelectOption[]) => ({
  value,
  label: options.find(option => option.value === value)?.label || '',
});
