import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import NotAvailableIcon from '@assets/collaboration-not-available.jpg';
import { useStyles } from './CollaborationPage.styles';
import { messages } from '../../collaboration.messages';

export const CollaborationPage: FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={styles.body} data-testid="collaboration-placeholder">
      <h2 className={styles.title}>
        {messages['collaborationPage.notAvailable']}
      </h2>

      <img
        className={styles.image}
        src={NotAvailableIcon}
        alt={messages['collaborationPage.placeholderAlt']}
      />
    </div>
  )
}
