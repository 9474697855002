import React from 'react';
import { Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

import { useProfileUIStore } from '@core/useStores';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { useStyles } from './UserInfoForm.styles';
import { messages } from '../../profile.messages';

export class UserInfoFormValues {
  email = '';
  firstName = '';
  lastName = '';
};

export const UserInfoForm: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const profileUIStore = useProfileUIStore();

  const initialValues = {
    email: profileUIStore.userInfo.email || '',
    firstName: profileUIStore.userInfo.firstName || '',
    lastName: profileUIStore.userInfo.lastName || '',
  };

  const onSubmit = async () => {
    // Update user will be implemented in next release
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Typography variant="h5">{messages['profilePage.title']}</Typography>
      </div>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form autoComplete='off' noValidate>
              <div className={styles.field}>
                <FormikTextInput
                  schema={valuesSchema.email}
                  disabled
                />
              </div>

              <div className={styles.fieldWrapper}>
                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikTextInput
                    schema={valuesSchema.firstName}
                    disabled
                  />
                </div>

                <div className={classNames(styles.field, styles.halfWidth)}>
                  <FormikTextInput
                    schema={valuesSchema.lastName}
                    disabled
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
