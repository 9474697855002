import { getEnv } from 'mobx-state-tree';
import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';

import { IStoresEnv } from '@core/storesEnv';
import { useUsersGridUIStore } from '@core/useStores';
import { OptionType } from '@shared/components/SelectDropdown';
import { FilterChip } from '@shared/components/FilterChip';
import { LAST_LOGIN_START_OPTIONS, LAST_LOGIN_END_OPTIONS } from '../UsersFiltersModal';

import { messages } from 'Users/users.messages';
import { ThemeProps } from '@styles/theme';

type FilterEntityProps = {
  id: string,
  name: string,
  type?: string
}

type UserFiltersEnumProps = {
  label: string,
  options: Array<FilterEntityProps>
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  margin: {},
  filters: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&$margin': {
      marginBottom: theme.spacing(4),
    },
  },
  clear: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  text: {
    fontFamily: theme.font.secondary,
    fontSize: 16,
    lineHeight: 1.75,
    color: theme.colors.violet_primary,
  },
}));

export const UsersFiltersBar: FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const usersGridUIStore = useUsersGridUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);

  const onClear = () => {
    usersGridUIStore.resetParams();
  }

  const onRemove = (id:string, filterKey:string) => {
    const requestParams: Record<string, any> = usersGridUIStore.requestParams.getParams;

    usersGridUIStore.setParams({
      ...requestParams,
      page: 0,
      [filterKey]: typeof requestParams[filterKey] === 'string'
        ? ''
        : requestParams[filterKey].filter((value: string) => value !== id),
    });
  }

  return (
    <Observer>
      {() => {
        const filters: Record<string, any> = usersGridUIStore.requestParams.getParams;
        const originalFilters: Record<string, any> = filtersOptions.getFilterOptions;

        const USER_FILTERS: Record<string, UserFiltersEnumProps> = {
          departments: {
            label: messages['usersFiltersBar.departments.label'],
            options: originalFilters.departments.map((department:string) => ({ id: department, name: department })),
          },
          roleIds: {
            label: messages['usersFiltersBar.roleIds.label'],
            options: originalFilters.roles,
          },
          statusIds: {
            label: messages['usersFiltersBar.statusIds.label'],
            options: originalFilters.statuses,
          },
          groupIds: {
            label: messages['usersFiltersBar.groupIds.label'],
            options: originalFilters.groups,
          },
          procedureIds: {
            label: messages['usersFiltersBar.procedureIds.label'],
            options: originalFilters.procedures,
          },
          lastLoginStart: {
            label: messages['usersFiltersBar.lastLoginStart.label'],
            options: LAST_LOGIN_START_OPTIONS
              .map((option:OptionType) => ({ id: option.value, name: option.label })),
          },
          lastLoginEnd: {
            label: messages['usersFiltersBar.lastLoginStart.label'],
            options: LAST_LOGIN_END_OPTIONS
              .map((option:OptionType) => ({ id: option.value, name: option.label })),
          },
        };

        const anyFilterSelected = Object.keys(USER_FILTERS)
          .some(key => Boolean(filters[key]) && filters[key].length > 0);

        return (
          <div className={classNames(styles.filters, { [styles.margin]: anyFilterSelected })}>
            {Object.keys(USER_FILTERS).map(key => {
              if (filters[key] && filters[key].length) {
                if (typeof filters[key] === 'string') {
                  const option = USER_FILTERS[key].options.find(option => option.id === filters[key]);

                  return (
                    option ? (
                      <FilterChip
                        key={option.id}
                        text={`${USER_FILTERS[key].label}: ${option.name}`}
                        onRemove={() => onRemove(filters[key], key)}
                      />
                    ) : null
                  )
                }

                return filters[key].map((id:string) => {
                  const option = USER_FILTERS[key].options.find(option => option.id === id);

                  return (
                    option ? (
                      <FilterChip
                        key={option.id}
                        text={`${USER_FILTERS[key].label}: ${option.name}`}
                        onRemove={() => onRemove(id, key)}
                      />
                    ) : null
                  )
                })
              }
            })}
            {anyFilterSelected && (
              <p
                onClick={onClear}
                className={classNames(styles.text, styles.clear)}
                data-testid="user-filter-bar-clear-button"
              >
                {messages['usersFiltersBar.clearAll']}
              </p>
            )}
          </div>
        )
      }}
    </Observer>
  )
}
