import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { CircularProgress } from '@material-ui/core';

import { APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../forms/BaseInputProps';
import { useStyles } from './Button.styles';

export const PRIMARY_COLOR_BUTTON_TYPE = 'primary';
export const SECONDARY_COLOR_BUTTON_TYPE = 'secondary';

export type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit';
  styleVariant?: 'appExternal' | 'appInner',
  color?: 'primary' | 'secondary',
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick, children, disabled, type = 'button', className, loading,
  styleVariant = APP_INNER_STYLE_VARIANT, color = PRIMARY_COLOR_BUTTON_TYPE,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <button
      className={
        classNames(styles.button,
          {
            [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT,
            [styles.secondary]: color === SECONDARY_COLOR_BUTTON_TYPE,
            [styles.disabled]: disabled,
          },
          className
        )}
      onClick={() => onClick && onClick()}
      disabled={disabled}
      type={type}
      data-testid="button"
    >
      {
        loading
          ? (
            <div className={styles.loaderWrapper}>
              <CircularProgress className={styles.loader} size={24} />
            </div>
          )
          : children
      }
    </button>
  )
};

Button.displayName = 'Button';
