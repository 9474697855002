import { Instance, SnapshotIn, types, flow, getEnv} from 'mobx-state-tree';
import { Subject } from 'rxjs';

import { GroupListingItem } from '../domain/Group';
import { IStoresEnv } from '@core/storesEnv';

export const GroupUpdate$ = new Subject();
export const GROUPS_SEARCH_TEXT_KEY = 'groups_search_text';

export const GroupsUIStoreInferred = types
  .model('GroupsUIStore', {
    searchText: types.optional(types.string, ''),
    isLoading: types.optional(types.boolean, true),
    groupModalOpened: types.optional(types.boolean, false),
    deleteConfirmationModalOpened: types.optional(types.boolean, false),
    groupToEditId: types.maybeNull(types.string),
  })
  .actions(self => {
    const { groups: groupsDataStore, sessionStorage } = getEnv<IStoresEnv>(self);

    const loadGroups = flow(function* () {
      // Default params
      const payload = {
        sortFieldName: 'name',
        sortDirection: 0,
      }

      self.isLoading = true;
      yield groupsDataStore.load(payload);
      self.isLoading = false;
    });

    const editGroup = flow(function* (payload) {
      const editGroupAction = yield groupsDataStore.editGroup(payload);

      if (editGroupAction.success) {
        GroupUpdate$.next();
      }

      return editGroupAction;
    });

    const toggleGroupModalOpen = (open: boolean) => {
      self.groupModalOpened = open;
    };

    const toggleDeleteConfirmationModalOpened = (open: boolean) => {
      self.deleteConfirmationModalOpened = open;
    };

    const showEditGroup = (id: string | null | undefined) => {
      if (id) {
        toggleGroupModalOpen(true);
        self.groupToEditId = id;
      }
    }

    const clearEditGroupId = () => self.groupToEditId = null;

    const search = (searchText: string) => {
      self.searchText = searchText;
      saveSearchTextToStorage();
    }

    const saveSearchTextToStorage = () => {
      sessionStorage.setItem(GROUPS_SEARCH_TEXT_KEY, JSON.stringify(self.searchText));
    }

    return {
      loadGroups,
      editGroup,
      search,
      setSelectedGroup: (id: string) => groupsDataStore.setSelectedGroup(id),
      unselectGroup: () => groupsDataStore.unselectGroup(),
      toggleGroupModalOpen,
      toggleDeleteConfirmationModalOpened,
      showEditGroup,
      clearEditGroupId,
      loadGroup: groupsDataStore.loadGroup,
      addGroup: groupsDataStore.addGroup,
      deleteGroup: groupsDataStore.deleteGroup,
    }
  })
  .views(self => {
    const { groups: groupsDataStore } = getEnv<IStoresEnv>(self);

    return {
      get groups() {
        return groupsDataStore.groups.filter((group: GroupListingItem) =>
          group.name.toLowerCase().includes(self.searchText.toLowerCase())
        );
      },
      get isSearchApplied () {
        return !!self.searchText;
      },
      get hasNoGroups () {
        return !self.isLoading && !groupsDataStore.groups.length;
      },
      get selectedGroup () {
        return groupsDataStore.selectedGroup;
      },
      get getStorageSearchText() {
        const storedSearchText = sessionStorage.getItem(GROUPS_SEARCH_TEXT_KEY);

        return storedSearchText && JSON.parse(storedSearchText);
      },
      isActive (id: string) {
        return groupsDataStore.selectedGroup?.id === id;
      },
    }
  });

type GroupsUIStoreFactoryType = typeof GroupsUIStoreInferred;
interface IGroupsUIStoreFactory extends GroupsUIStoreFactoryType {}
export const GroupsUIStore: IGroupsUIStoreFactory = GroupsUIStoreInferred;
export interface IGroupsUIStore extends Instance<IGroupsUIStoreFactory> {}
export interface IGroupsUIStoreSnapshotIn extends SnapshotIn<IGroupsUIStore> {}
