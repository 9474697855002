import React from 'react';
import { Field, FieldProps } from 'formik';

import { TextInput, TextInputProps } from '../../inputs/TextInput';
import { FormSchemaItem } from '../../FormSchema';

export type FormikTextInputProps = {
  schema: FormSchemaItem;
  cleanable?: boolean;
  helperText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  styleVariant?: 'appExternal' | 'appInner',
  suffix?: (props: TextInputProps) => React.ReactNode,
  prefix?: (props: TextInputProps) => React.ReactNode,
  type?: string;
}

export const FormikTextInput: React.FC<FormikTextInputProps> = props => {
  const {
    schema, cleanable, helperText, autoFocus,
    styleVariant, prefix, suffix, type, disabled,
  } = props;

  return (
    <Field name={schema.fieldName}>
      {({ field, form, meta }: FieldProps) => (
        <TextInput
          field={field}
          form={form}
          meta={meta}
          label={schema.label}
          isRequired={schema.isRequired}
          restrictEngine={schema.restrictEngine}
          placeholder={schema.placeholder}
          maxLength={schema.maxLength}
          type={type || schema.type}
          min={schema.min}
          max={schema.max}
          className={schema.className}
          styleVariant={styleVariant}
          prefix={prefix}
          suffix={suffix}
          cleanable={cleanable}
          helperText={helperText}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      )}
    </Field>
  )
};

FormikTextInput.displayName = 'FormikTextInput';
