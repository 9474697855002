import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import classNames from 'classnames';
import { Subject } from 'rxjs';
import _ from 'lodash';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { useProcedureUIStore } from '@core/useStores';
import { SelectOption } from '@shared/components/SelectDropdown/Option';

import { useStyles } from './ProcedureModal.styles';
import { ProcedureDevices } from './components/ProcedureDevices';
import { ProcedureDetailsForm } from './components/ProcedureDetailsForm';
import { ProcedureListingDevice } from 'Procedures/domain/Device';
import { messages } from 'Procedures/procedures.messages';

export const COMMON_SELECT_PROPS = {
  isSearchable: false,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: false,
  closeMenuOnSelect: true,
  components: { Option: SelectOption },
}

export type ProcedureModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
}

export const ProcedureModal: FC<ProcedureModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const procedureUIStore = useProcedureUIStore();
  const [isDevicesListVisible, setDevicesListVisibility] = useState(true);
  const [formSubmitSubject$] = useState<Subject<{}>>(new Subject());
  const isEditMode = !!procedureUIStore.procedureToEditId;

  useEffect(() => {
    const getProcedureData = async () => {
      if (isEditMode && procedureUIStore.procedureToEditId) {
        procedureUIStore.toggleLoading(true);

        const result = await procedureUIStore.loadProcedure(procedureUIStore.procedureToEditId);

        procedureUIStore.toggleLoading(false);

        if (result.success) {
          const preparedDevices = result.data.devices.map((device: ProcedureListingDevice) => {
            const { id, name, platform, type } = device;

            return {
              _device: {
                device: name,
                platform,
                deviceId: id,
                type,
              },
              uiId: _.uniqueId('tab_'),
            }
          });

          procedureUIStore.setProcedureGeneralInfo(result.data);
          procedureUIStore.setDevices(preparedDevices);
        }
      }
    }

    getProcedureData();
    procedureUIStore.getToken();
  }, [isEditMode]);

  const onDeleteProcedure = () => {
    procedureUIStore.toggleProcedureModalOpen(false);
    procedureUIStore.toggleDeleteConfirmationModalOpened(true);
  };

  const submitForm = () => formSubmitSubject$.next();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <Observer>
        {() => (
          <div className={styles.modalHeading}>
            <h2 className={styles.h2}>
              {
                isEditMode
                  ? procedureUIStore.procedureGeneralInfo?.name || messages['procedureModal.loading']
                  : messages['procedureModal.newProcedure']
              }
            </h2>

            {
              (procedureUIStore.isTokenLoading || procedureUIStore.isLoading) && (
                <CircularProgress className={styles.loader} color='inherit' size={24} />
              )
            }
          </div>
        )}
      </Observer>

      <div className={styles.processStepsWrapper}>
        <span
          onClick={() => setDevicesListVisibility(true)}
          className={
            classNames(styles.stepName,
              {[styles.stepNameActive]: isDevicesListVisible},
            )
          }
        >
          {messages['procedureModal.devices']}
        </span>

        <span className={styles.longDash}></span>

        <span
          onClick={() =>
            !procedureUIStore.isNextStepDisabled && setDevicesListVisibility(false)
          }
          className={
            classNames(styles.stepName,
              {[styles.stepNameActive]: !isDevicesListVisible},
            )
          }
        >
          {messages['procedureModal.procedureDetails']}
        </span>
      </div>

      <ProcedureDevices
        isEditMode={isEditMode}
        className={classNames({[styles.hidden]: !isDevicesListVisible})}
      />

      <ProcedureDetailsForm
        submitSubject={formSubmitSubject$}
        className={classNames({[styles.hidden]: isDevicesListVisible})}
      />

      <div className={classNames(styles.buttons, { [styles.end]: !isEditMode })}>
        {
          isEditMode && (
            <Button
              onClick={onDeleteProcedure}
              color="secondary"
              data-testid="prodecure-modal-delete"
            >
              {messages['procedureModal.procedure.delete']}
            </Button>
          )
        }

        <div>
          <Button
            onClick={onRequestClose}
            className={styles.button} color="secondary"
          >
            {messages['procedureModal.cancel']}
          </Button>

          {
            isDevicesListVisible
              ? (
                <Observer>
                  {() => (
                    <Button
                      className={styles.button}
                      onClick={() => setDevicesListVisibility(false)}
                      disabled={procedureUIStore.isNextStepDisabled}
                    >
                      {messages['procedureModal.next']}
                    </Button>
                  )}
                </Observer>
              )
              : (
                <Button
                  className={styles.button}
                  onClick={submitForm}
                >
                  {messages['procedureModal.save']}
                </Button>
              )
          }
        </div>
      </div>
    </Modal>
  )
}
