import * as Yup from 'yup';

import { ISelectOption } from '@shared/helpers/form/ISelectOption';
import { GroupFormValues } from './FormValues';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Groups/groups.messages';

export const errors = {
  required: messages['groupModal.error.required'],
};

export const validationSchema = Yup.object().shape<Partial<GroupFormValues>>({
  name: Yup.string().required(errors.required(FIELD_NAMES.name)),
  users: Yup.array().of(
    Yup.object().shape<ISelectOption>({
      label: Yup.string(),
      value: Yup.string(),
    })
  ).nullable(),
  procedures: Yup.array().of(
    Yup.object().shape<ISelectOption>({
      label: Yup.string(),
      value: Yup.string(),
    })
  ).nullable(),
});
