import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { CompanyStore } from './Company.store';
import { CreateCompanyStore } from './CreateCompany.store';

import { performGetCompanies } from 'Companies/services';

export const CompaniesGridStoreInferred = types
  .model('CompaniesGridStoreInferred', {
    companiesList: types.array(CompanyStore),
    createCompany: types.optional(CreateCompanyStore, { dataIsolation: false }),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadCompanies = flow(function* () {
      const result = yield performGetCompanies(
        api,
        {
          payload: null,
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.companiesList = result.data;
    });

    const cleanData = () => {
      self.companiesList = cast([]);
    };

    return {
      loadCompanies,
      cleanData,
    }
  })

type CompaniesGridStoreFactoryType = typeof CompaniesGridStoreInferred;
interface ICompaniesGridStoreFactory extends CompaniesGridStoreFactoryType {}
export const CompaniesGridStore: ICompaniesGridStoreFactory = CompaniesGridStoreInferred;
export interface ICompaniesGridStore extends Instance<ICompaniesGridStoreFactory> {}
export interface ICompaniesGridStoreSnapshotIn extends SnapshotIn<ICompaniesGridStore> {}
