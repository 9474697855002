import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { IStoresEnv } from '@core/storesEnv';

export const ForgotPasswordUIStoreInferred = types
  .model('ForgotPasswordUIStoreInferred', {
    processError: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
  })
  .volatile(() => ({
    status: DataStateStore.create({ state: DataState.initial }),
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { auth } = getEnv(api);

    return ({
      forgotPassword: flow(function* (email: string) {
        self.processError = '';
        self.email = email;
        self.status.setLoading();

        const result = yield auth.forgotPassword(email);

        if (result.success) {
          self.status.setDone();
        } else {
          const { body } = result.error || {};

          self.status.setError();
          self.processError = body?.errors[0];
        }
      }),

      resetSuccessView: () => self.status.setInitial(),
    })
  });

type ForgotPasswordUIStoreFactoryType = typeof ForgotPasswordUIStoreInferred;
interface IForgotPasswordUIStoreFactory extends ForgotPasswordUIStoreFactoryType {}
export const ForgotPasswordUIStore: IForgotPasswordUIStoreFactory = ForgotPasswordUIStoreInferred;
export interface IForgotPasswordUIStore extends Instance<IForgotPasswordUIStoreFactory> {}
export interface IForgotPasswordUIStoreSnapshotIn extends SnapshotIn<IForgotPasswordUIStore> {}
