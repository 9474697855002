import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  active: {},
  wrapper: {
    color: theme.colors.black,
    fontFamily: theme.font.primary,
    fontSize: 16,
    lineHeight: 1.5,
    textDecoration: 'none',
    padding: [theme.spacing(2), 0],
    borderRadius: theme.radius,
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    maxWidth: 220,
    transition: '0.3s',
    border: '1px solid transparent',
    background: 'none',
    textAlign: 'left',

    ['&:hover, &:focus, &$active']: {
      padding: [theme.spacing(2), theme.spacing(4)],
      cursor: 'pointer',
    },

    '&:hover': {
      backgroundColor: theme.colors.transp_grey_light,
    },

    '&:focus': {
      boxShadow: `0 0 4px 0 ${theme.colors.blue_neon}`,
      borderColor: theme.colors.blue_primary,
      outline: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      borderColor: 'transparent',
    },

    '&$active': {
      backgroundColor: theme.colors.grey_lighter,
      borderColor: theme.colors.violet_primary,
      borderTopColor: theme.colors.transp_grey_light,
      borderBottomColor: theme.colors.transp_grey_light,
      borderWidth: [1, 3],
      boxShadow: 'none',
    },
  },
}));
