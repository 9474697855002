import { createUseStyles } from 'react-jss';
import color from 'color';

import { flex, textOverflowDots } from '@resources/mixins';
import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  sortedDesc: {},
  tableDisabled: {},
  table: {
    ...flex('column', 'space-between', ''),
    flexWrap: 'wrap',
    width: '100%',
    border: `1.5px solid ${theme.colors.grey_light}`,
    minHeight: 'calc(100vh - 300px)',
    background: theme.colors.white,
    borderRadius: theme.radius,

    '&$tableDisabled': {
      pointerEvents: 'none',
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    borderBottom: `1px solid ${theme.colors.grey_light}`,
  },
  loadingWrapper: {
    height: 24,
    top: '50%',
    right: theme.spacing(5),
    position: 'absolute',
    transform: 'translate(0, -50%)',
  },
  loader: {
    color: theme.colors.violet_primary,
  },
  body: {
    width: '100%',
  },
  row: {
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.grey_light}`,
    flexWrap: 'wrap',

    '&:hover': {
      background: theme.colors.grey_background,
    },
  },
  defaultRowContent: {
    width: '100%',
    display: 'flex',
    minHeight: 64,
  },
  expandedRowContent: {
    width: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    padding: [0, theme.spacing(2)],
    color: theme.colors.black,
    fontFamily: theme.font.primary,
    fontSize: 16,
  },
  noGrow: {
    flexGrow: '0 !important',
  },
  headerCell: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
    padding: [0, theme.spacing(1)],
    backgroundColor: theme.colors.white,
    color: theme.colors.grey_text,
    textAlign: 'left',
    fontSize: 16,
    fontFamily: theme.font.secondary,

    '& span': {
      padding: [theme.spacing(0.5), theme.spacing(1)],
      borderRadius: 1,
      ...textOverflowDots(),
    },
  },
  sortPlaceholder: {
    marginLeft: theme.spacing(2),
    color: theme.colors.grey_light,
    transform: 'scale(1, 1)',
    display: 'none !important',
  },
  sortIndicator: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  sortIcon: {
    transform: 'scale(1, 1)',
    transition: 'transform .2s ease',
    color: theme.colors.violet_primary,

    '&$sortedDesc': {
      transform: 'scale(1, -1)',
    },
  },
  withSorting: {
    '&:hover svg': {
      display: 'inherit !important',
    },

    '&:hover span': {
      backgroundColor: `${color(theme.colors.violet_primary).fade(0.92)}`,
    },
  },
  ellipsis: {
    ...textOverflowDots(),
  },
  disabledEllipsis: {
    overflow: 'auto',
    whiteSpace: 'inherit',
    textOverflow: 'inherit',
  },
}));
