import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';

import { ThemeProps } from '@styles/theme';
import { flex } from '@styles/resources/mixins';
import ArrowRightIcon from '@assets/arrow-right.svg';
import PaginationEndIcon from '@assets/pagination-end.svg';
import { PaginationProps } from '../Table';
import { messages } from '@shared/components/components.messages';

type Props = {
  className?: string,
  paginationStatus: PaginationProps,
  goToPage: Function,
};

const useStyles = createUseStyles((theme:ThemeProps) => ({
  body: {
    ...flex('', 'flex-end', 'center'),
    width: '100%',
    height: 64,
    padding: [0, theme.spacing(6)],
    backgroundColor: theme.colors.white,
  },
  button: {
    border: 'none',
    background: 'none',
    padding: 0,
    height: 24,
    width: 24,
    cursor: 'pointer',
    '&:disabled': {
      opacity: .3,
      cursor: 'default',
    },
  },
  icon: {
    transformOrigin: 'center',
  },
  reverted: {
    transform: 'scale(-1, 1)',
  },
  text: {
    color: theme.colors.grey_text,
    fontSize: 16,
    fontFamily: theme.font.secondary,
    padding: [0, theme.spacing(2)],
  },
  margin: {
    marginRight: theme.spacing(6),
  },
}));

export const TablePagination:FC<Props> = ({
  paginationStatus,
  goToPage,
  className,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const {
    currentPage: pageIndex, limit: pageLimit, totalEntities, totalPages,
  } = paginationStatus;

  const canPreviousPage = () => pageIndex !== 0;
  const canNextPage = () => pageIndex < totalPages - 1;

  return (
    <div className={classNames(styles.body, className)}>
      <span className={classNames(styles.margin, styles.text)}>
        {pageIndex * pageLimit + 1} to {canNextPage() ? ((pageIndex + 1) * pageLimit) : totalEntities} of {totalEntities}
      </span>

      <button
        className={classNames(styles.button, styles.reverted)}
        onClick={() => goToPage(0)}
        disabled={!canPreviousPage()}
      >
        <img src={PaginationEndIcon} />
      </button>

      <button
        className={classNames(styles.button, styles.reverted)}
        onClick={() => goToPage(pageIndex - 1)}
        disabled={!canPreviousPage()}
      >
        <img src={ArrowRightIcon} />
      </button>

      <span className={styles.text}>
        {messages['tablePagination.summary'](pageIndex+1, totalPages)}
      </span>

      <button
        className={styles.button}
        onClick={() => goToPage(pageIndex + 1)} disabled={!canNextPage()}
      >
        <img src={ArrowRightIcon} />
      </button>

      <button
        className={styles.button}
        onClick={() => goToPage(totalPages - 1)} disabled={!canNextPage()}
      >
        <img src={PaginationEndIcon} />
      </button>
    </div>
  )
}
