import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import color from 'color';
import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

type ChipProps = {
  text: string,
  onRemove: () => void
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  chip: {
    position: 'relative',
    borderRadius: theme.radius,
    height: 28,
    backgroundColor: theme.colors.transp_violet,
    ...flex('', 'flex-start', 'center'),
    padding: [0, theme.spacing(1), 0, theme.spacing(2)],
    margin: [theme.spacing(1), theme.spacing(2), theme.spacing(1), 0],
    transition: 'background-color .2s ease',
    '&:hover': {
      backgroundColor: `${color(theme.colors.violet_darker).fade(0.84)}`,
      color: theme.colors.violet_darker,
    },
  },
  text: {
    fontFamily: theme.font.secondary,
    fontSize: 16,
    lineHeight: 1.25,
    color: theme.colors.violet_primary,
  },
  icon: {
    color: theme.colors.violet_primary,
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    marginTop: -2,
  },
}));

export const FilterChip: FC<ChipProps> = ({ text, onRemove }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={styles.chip}>
      <p className={styles.text} data-testid="filter-chip-title">{text}</p>
      <ClearOutlinedIcon className={styles.icon} onClick={onRemove} />
    </div>
  )
}
