import { Instance, SnapshotIn, types/*, flow, getEnv*/ } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';
//import moment from 'moment';

//import { IStoresEnv } from '@core/storesEnv';
//import { RoutePaths } from '@core/routes/RoutePaths';

export const ConfirmUIStoreInferred = types
  .model('ConfirmUIStoreInferred', {
    authError: types.optional(types.string, ''),
  })
  .volatile(() => ({
    status: DataStateStore.create({ state: DataState.initial }),
  }))
  //.actions(self => {
  //  const { navigator, api } = getEnv<IStoresEnv>(self);
  //  const { auth } = getEnv(api);

  //  const afterLoginNavigation = () => navigator.to(RoutePaths.users);

  //  return ({
  //    login: flow(function* (email: string, password: string) {
  //      self.authError = '';
  //      self.status.setLoading();

  //      const result = yield auth.signIn(email, password);

  //      if (result.success) {
  //        const { token, company, user, permissions, roles } = result.data.body || {};

  //        self.status.setDone();

  //        auth.saveUserInfo({
  //          profile: { ...user },
  //          company,
  //          permissions,
  //          roles,
  //          token: {
  //            ...token,
  //            // eslint-disable-next-line @typescript-eslint/camelcase
  //            expires_at: moment().unix() + token.expires_in,
  //          },
  //        });

  //        afterLoginNavigation();
  //      } else {
  //        const { body } = result.error || {};

  //        self.status.setError();
  //        self.authError = body?.errors[0];
  //      }
  //    }),
  //  });
  //})
  ;

type ConfirmUIStoreFactoryType = typeof ConfirmUIStoreInferred;
interface IConfirmUIStoreFactory extends ConfirmUIStoreFactoryType {}
export const ConfirmUIStore: IConfirmUIStoreFactory = ConfirmUIStoreInferred;
export interface IConfirmUIStore extends Instance<IConfirmUIStoreFactory> {}
export interface IConfirmUIStoreSnapshotIn extends SnapshotIn<IConfirmUIStore> {}
