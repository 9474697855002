import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  errorWrapper: {
    marginBottom: theme.spacing(8),
  },

  wrapper: {
    margin: [0, 'auto'],
    width: 320,
  },

  formTitle: {
    color: theme.colors.white,
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 'normal',
    fontFamily: theme.font.secondary,
    marginBottom: theme.spacing(2),
  },

  formSubTitle: {
    color: theme.colors.white,
    fontSize: 16,
    lineHeight: 1.25,
    fontFamily: theme.font.secondary,
    marginBottom: theme.spacing(8),
  },

  fieldWrapper: {
    marginBottom: theme.spacing(4),
  },

  actionWrapper: {
    marginTop: theme.spacing(8),

    '& button': {
      width: '100%',
    },
  },
}));
