import { Instance, SnapshotIn, types, flow } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';

import { CompaniesGridStore } from './CompaniesGrid.store';

export const CompaniesGridUIStoreInferred = types
  .model('CompaniesGridUIStoreInferred', {
    _companies: types.optional(CompaniesGridStore, {}),
    isActivePage: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    status: DataStateStore.create({ state: DataState.initial }),
  }))
  .views(self => ({
    get companies() { return self._companies.companiesList; },
    get createCompany() { return self._companies.createCompany; },
  }))
  .views(self => ({
    get isTableVisible() { return self.companies.length || self.status.isLoading; },
  }))
  .actions(self => {
    const load = flow(function * () {
      self.status.setLoading();
      yield self._companies.loadCompanies();
      self.status.setDone();
    });

    const togglePageActivity = (isActive: boolean) => self.isActivePage = isActive;

    const cleanUp = () => {
      self._companies.cleanData();
    }

    return {
      load,
      togglePageActivity,
      cleanUp,
    }
  })

type CompaniesGridUIStoreFactoryType = typeof CompaniesGridUIStoreInferred;
interface ICompaniesGridUIStoreFactory extends CompaniesGridUIStoreFactoryType {}
export const CompaniesGridUIStore: ICompaniesGridUIStoreFactory = CompaniesGridUIStoreInferred;
export interface ICompaniesGridUIStore extends Instance<ICompaniesGridUIStoreFactory> {}
export interface ICompaniesGridUIStoreSnapshotIn extends SnapshotIn<ICompaniesGridUIStore> {}
