import { castArray, flow as _flow, isFunction, isString } from 'lodash';
import request, { ProgressEvent, SuperAgentRequest } from 'superagent';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import { Subject } from 'rxjs';

import { ApiCallParams, ErrorHandlers, ErrorParser } from '@ace/core';
import { GeneralApiErrors, HttpStatusCode } from '@ace/core';
import { ApiError, ApiResponse } from '@ace/core';
import { Socket } from '@ace/core';

import { messages } from '@shared/shared.messages';
import { DEFAULT_API_ERROR_MESSAGES } from './constants';

import { IApiEnv } from './apiEnv';

interface ExtendedApiCallParams extends ApiCallParams {
  ignoreAuthHeader?: boolean,
  disableDefaultErrorHandlers?: boolean,
  cancelSubject?: Subject<{}>,
}

export const ApiStoreInferred = types.compose(Socket, types
  .model({})
  .named('api')
  .actions(self => {
    const env = getEnv<IApiEnv>(self);
    const { getURL } = env;

    /**
     * Returns the request object with appropriate Authentication-related handlers
     *
     * @param req SuperAgent request to wrap
     */
    const withAuthHeader = (req: SuperAgentRequest, ignore?: boolean) => {
      const request = ignore ? req : req.set('Authorization', env.auth.authHeaderValue);

      return request
        .on('error', e => (
          Number(e.status) === HttpStatusCode.UNAUTHORIZED
            ? env.auth.signOut()
            : ''
        ));
    };

    /**
     * Adds to the request progress handler if passed
     *
     * @param req SuperAgent request to wrap
     */
    const withProgressTracking = (
      req: SuperAgentRequest,
      progressHandler?: (progressPercent: number) => void,
    ) => {
      if (progressHandler) {
        return req.on('progress', (event: ProgressEvent) => {
          progressHandler(event.percent || 0);
        });
      }

      return req;
    };

    /**
     * Loops through error handlers, shows toast for each string handler, invokes every function handler with
     * an instance of the error. If no handlers given, default generic toast will be shown
     */
    const processErrorHandlers = (error: ApiError, handlers: ErrorHandlers<any>) => {
      const handlersToExecute = handlers[error.type] || handlers[GeneralApiErrors.DEFAULT];

      if (handlersToExecute) {
        const handlersList = castArray(handlersToExecute);

        handlersList.forEach(handler => {
          if (isString(handler)) {
            const message = error.status === HttpStatusCode.FORBIDDEN
              ? `${handler}${handler.endsWith('.') ? '' : '.'} ${messages.noPermissionsForCurrentUser}`
              : handler;

            return env.notifier.error(message);
          }

          if (isFunction(handler)) {
            return handler(error);
          }
        });
      } else {
        return env.notifier.error(DEFAULT_API_ERROR_MESSAGES[error.status])
      }
    };

    const performApiCall = function* (fn: (...args: any[]) => IterableIterator<any>, params: ExtendedApiCallParams) {
      try {
        // ApiStore, not apiStore
        if (!params.ignoreAuthHeader) {
          yield env.auth.checkToken();
        }

        const result = yield* fn();
        const error = params.failIf && params.failIf(result);

        if (error) {
          throw (isString(error) ? new Error(error) : new Error());
        }

        return new ApiResponse(result);
      } catch (e) {
        const parsers = castArray(params.errorParsers || []);
        const parse = _flow(parsers) as ErrorParser;

        const error = parse(new ApiError(e));

        if (!params.disableDefaultErrorHandlers) {
          processErrorHandlers(error, params.errorHandlers);
        }

        return new ApiResponse(null, error);
      }
    };

    return ({
      get: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const requestUrl = getURL(url);
          const response = yield withAuthHeader(
            request(requestUrl), params.ignoreAuthHeader
          ).query(params.payload || '');

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      getFile: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request(getURL(url)), params.ignoreAuthHeader);

          return response.text;
        };

        return yield* performApiCall(call, params);
      }),

      getRawFile: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request(getURL(url)), params.ignoreAuthHeader)
            .responseType('blob');

          return response.text;
        };

        return yield* performApiCall(call, params);
      }),

      getBlob: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request(getURL(url)), params.ignoreAuthHeader)
            .responseType('blob');

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      getZipFile: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request(getURL(url)), params.ignoreAuthHeader)
            .responseType('arraybuffer');

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      getText: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request(getURL(url)), params.ignoreAuthHeader);

          return response.text;
        };

        return yield* performApiCall(call, params);
      }),

      post: flow(function* (
        url: string,
        params: ExtendedApiCallParams,
        query?: string | object,
      ) {
        const call = function* () {
          const response = yield withAuthHeader(
            withProgressTracking(
              request.post(getURL(url)),
              params.progressHandler,
            ), params.ignoreAuthHeader)
            .query(query || '')
            .send(params.payload);

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      postAFile: flow(function* (
        url: string,
        params: ExtendedApiCallParams,
        fileData: { fileField: string, file: File },
        additionalData: { [key: string]: string },
      ) {
        const call = function* () {
          const req = withAuthHeader(
            withProgressTracking(
              request
                .post(getURL(url))
                .attach(fileData.fileField, fileData.file)
                .field(additionalData),
              params.progressHandler,
            ), params.ignoreAuthHeader);

          if (params.cancelSubject) {
            params.cancelSubject.subscribe(() => req.abort());
          }

          const response = yield req;

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      put: flow(function* (url: string, params: ExtendedApiCallParams) {
        const call = function* () {
          const response = yield withAuthHeader(request.put(getURL(url)), params.ignoreAuthHeader)
            .send(params.payload);

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),

      delete: flow(function* (url: string, params: ExtendedApiCallParams, query?: string | object) {
        const call = function* () {
          const response = yield withAuthHeader(request.delete(getURL(url)), params.ignoreAuthHeader)
            .query(query || '')
            .send(params.payload);

          return response.body;
        };

        return yield* performApiCall(call, params);
      }),
    });
  }));

type ApiStoreFactoryType = typeof ApiStoreInferred;
export interface IApiStoreFactory extends ApiStoreFactoryType {}
export const ApiStore: IApiStoreFactory = ApiStoreInferred;
export interface IApiStore extends Instance<IApiStoreFactory> {}

export const createApiStore = (dependencies: IApiEnv): IApiStore => ApiStore.create({}, dependencies);
