import { Instance, SnapshotIn, types } from 'mobx-state-tree';

const ProcedureGeneralInfoInferred = types
  .model('ProcedureGeneralInfoInferred', {
    id: types.maybe(types.string),
    name: types.maybe(types.string),
    description: types.maybe(types.string),
    userIds: types.array(types.string),
    groupIds: types.array(types.string),
    ownership: types.maybe(types.string),
  });

type ProcedureGeneralInfoFactoryType = typeof ProcedureGeneralInfoInferred;
interface IProcedureGeneralInfoFactory extends ProcedureGeneralInfoFactoryType {};
export const ProcedureGeneralInfo: IProcedureGeneralInfoFactory = ProcedureGeneralInfoInferred;
export interface IProcedureGeneralInfo extends Instance<IProcedureGeneralInfoFactory> {};
export interface IProcedureGeneralInfoSnapshotIn extends SnapshotIn<IProcedureGeneralInfo> {};
