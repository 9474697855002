import React, { FC } from 'react';
import { PieProps, Sector, Text } from 'recharts';

import { COLOR_VIOLET_PRIMARY, COLOR_BLACK } from '@styles/constants';
import { messages } from 'Analytics/analytic.messages';

export interface ActiveShapeProps extends PieProps{
  value: number,
}

export const ActiveShape: FC<ActiveShapeProps> = props => {
  // Made converting because of inconsistent typing in PieProps and TextProps/SectorProps
  const cx = Number(props.cx || 0);
  const cy = Number(props.cy || 0);
  const innerRadius = Number(props.innerRadius || 0);
  const outerRadius = Number(props.outerRadius || 0);
  const startAngle = Number(props.startAngle || 0);
  const endAngle = Number(props.endAngle || 0);

  return (
    <g>
      <Text
        x={cx}
        y={cy}
        dy={-8}
        textAnchor="middle"
        fontSize={20}
        fontWeight={600}
        fill={COLOR_VIOLET_PRIMARY}
      >
        {props.value}
      </Text>
      <Text
        fontSize={12}
        width={120}
        x={cx}
        y={cy}
        dy={24}
        textAnchor="middle"
        fill={COLOR_BLACK}
      >
        {messages['pieChart.activeShape.title'](props.value)}
      </Text>
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle + 4}
        endAngle={endAngle - 4}
        innerRadius={innerRadius + 8}
        outerRadius={outerRadius + 8}
        fill={props.fill}
      />
    </g>
  );
};
