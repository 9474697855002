import { pick } from 'lodash';

export type SetPasswordFormValues = {
  password: string,
  confirmPassword: string,
};

export class FormValues {
  password = '';
  confirmPassword = '';

  constructor(setPasswordData?: SetPasswordFormValues | null) {
    if (setPasswordData) {
      Object.assign(this, pick(setPasswordData, ...Object.keys(this)));
    }
  }
}
