import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type EditCompanyParams = {
  id: string;
  name?: string;
  description?: string;
};

export const performEditCompany = async (api: IApiStore, params: EndpointCallParams<EditCompanyParams>) => {
  const { payload, ...rest } = params;
  const { id, ...payloadBody } = payload;
  const req = api
    .put(`identity-app/api/v1/Companies/${id}`, {
      ...rest,
      payload: payloadBody,
    })

  return req;
}
