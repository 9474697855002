import { FormSchema } from '@shared/components/forms/FormSchema';

import { ResendInviteFormValues } from './ResendInviteModal';
import { messages } from 'Users/users.messages';

type ResendInviteFormSchema = FormSchema<ResendInviteFormValues>;

export const FIELD_NAMES = {
  email: messages['resendInviteModal.field.email.name'],
}

export const valuesSchema: ResendInviteFormSchema = {
  email: {
    fieldName: 'email',
    label: FIELD_NAMES.email,
    isRequired: true,
    type: 'email',
  },
};
