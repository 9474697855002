export const messages = {
  'resendInviteModal.success.toast': 'The invitation has been sent successfully',
  'resendInviteModal.title': (name?: string) => `Resend invitation to ${name}`,
  'resendInviteModal.button.cancel': 'Cancel',
  'resendInviteModal.button.submit': 'Resend invitation',
  'resendInviteModal.error.email': 'Email must contain \'@\', \'.\' and must not contain space',
  'resendInviteModal.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'resendInviteModal.field.email.name': 'Email',
  'userModal.submit.toast.success': (isEditMode: boolean) =>
    `${isEditMode ? 'The user info was successfully updated' : 'The invite was successfully sent'}`,
  'userModal.newUser': 'New user',
  'userModal.buttons.delete': 'Delete user',
  'userModal.buttons.cancel': 'Cancel',
  'userModal.buttons.submit': (isEditMode: boolean) => `${isEditMode ? 'Save' : 'Send invitation'}`,
  'userModal.validation.name': (name: string) => `${name} can only contain letters, dashes and spaces`,
  'userModal.validation.department': (fieldName: string) =>
    `${fieldName} can only contain letters, dashes and spaces between words`,
  'userModal.email.label': 'Email',
  'userModal.firstName.label': 'First name',
  'userModal.lastName.label': 'Last name',
  'userModal.department.label': 'Department',
  'userModal.roleId.label': 'Role',
  'userModal.groups.label': 'Member of groups',
  'userModal.procedures.label': 'Assigned procedures',
  'usersFiltersBar.departments.label': 'Department',
  'usersFiltersBar.roleIds.label': 'Role',
  'usersFiltersBar.statusIds.label': 'Status',
  'usersFiltersBar.groupIds.label': 'Group',
  'usersFiltersBar.procedureIds.label': 'Procedure',
  'usersFiltersBar.lastLoginStart.label': 'Last login',
  'usersFiltersBar.clearAll': 'Clear all filters',
  'usersFiltersModal.departments.label': 'Department',
  'usersFiltersModal.roleIds.label': 'Role',
  'usersFiltersModal.statusIds.label': 'Status',
  'usersFiltersModal.lastLogin.label': 'Last login',
  'usersFiltersModal.groupIds.label': 'Assigned groups',
  'usersFiltersModal.procedureIds.label': 'Assigned procedures',
  'usersFiltersModal.lastLogin.lastWeek': 'Within last week',
  'usersFiltersModal.lastLogin.lastMonth': 'Within last month',
  'usersFiltersModal.lastLogin.lastThreeMonth': 'Within last three months',
  'usersFiltersModal.lastLogin.lastYear': 'Within last year',
  'usersFiltersModal.lastLogin.weekAgo': 'More than a week ago',
  'usersFiltersModal.lastLogin.monthAgo': 'More than a month ago',
  'usersFiltersModal.lastLogin.threeMonthsAgo': 'More than three months ago',
  'usersFiltersModal.lastLogin.yearAgo': 'More than a year ago',
  'usersFiltersModal.title': 'Filter user list',
  'usersFiltersModal.button.cancel': 'Cancel',
  'usersFiltersModal.button.submit': 'Apply filter',
  'usersPage.noInfo': 'No information',
  'usersPage.table.fullName.header': 'Full Name',
  'usersPage.table.department.header': 'Department',
  'usersPage.table.groups.header': 'Assigned groups',
  'usersPage.table.roles.header': 'Role',
  'usersPage.table.lastLoginAt.header': 'Last login',
  'usersPage.table.status.header': 'Status',
  'usersPage.table.editUser': 'Edit user',
  'usersPage.table.resendInvitation': 'Resend invitation',
  'usersPage.table.goToAnalytics': 'User analytics',
  'usersPage.removeUser.toast.error': 'Failed to remove user',
  'usersPage.removeUser.toast.success': 'The user was successfully removed',
  'usersPage.subRow.email': 'Email',
  'usersPage.subRow.assignedGroups': 'Assigned groups',
  'usersPage.subRow.assignedProcedures': 'Assigned procedures',
  'usersPage.inviteUser.title': 'Invite new user',
  'usersPage.filter.title': 'Filter',
  'usersPage.title': 'Users',
  'usersPage.delete.message': 'Are you sure you want to delete this user?',
  'usersPage.delete.confirmText': 'Delete user',
  'usersPage.noUsersInGroup': 'There are no users in this group',
  'usersSidebar.button.allUsers': 'All users',
  'usersSidebar.button.usersWithoutGroups': 'Ungrouped users',
}
