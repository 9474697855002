export const messages = {
  'header.logo.alt': 'WeaVR Manager logo',
  'mainMenu.companies': 'Companies',
  'mainMenu.users': 'Users',
  'mainMenu.procedures': 'Procedures',
  'mainMenu.collaboration': 'Collaboration',
  'mainMenu.analytics': 'Analytics',
  'userMenu.profile': 'My profile',
  'userMenu.logout': 'Logout',
}
