import { Instance, SnapshotIn, types, flow, getEnv, getSnapshot } from 'mobx-state-tree';
import { IStoresEnv } from '@core/storesEnv';

import { convertStringsToOptions } from '@shared/helpers/form';
import {
  performGetProceduresDevices,
  performGetProceduresModes,
  performGetProceduresTypes,
  performGetProceduresOwnerships,
} from '../services';

export const ProceduresFiltersOptionsStoreInferred = types
  .model('ProceduresFiltersOptionsStoreInferred',
    {
      devices: types.array(types.string),
      modes: types.array(types.string),
      types: types.array(types.string),
      ownerships: types.array(types.string),
    })
  .views(self => ({
    get getFilterOptions() {
      return getSnapshot(self);
    },
    get getDevicesOptions() {
      return convertStringsToOptions(self.devices);
    },
    get getModesOptions() {
      return convertStringsToOptions(self.modes);
    },
    get getTypesOptions() {
      return convertStringsToOptions(self.types);
    },
    get getOwnershipsOptions() {
      return convertStringsToOptions(self.ownerships);
    },
  }))
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadDevices = flow(function* () {
      const result = yield performGetProceduresDevices(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.devices = result.data;
    });

    const loadModes = flow(function* () {
      const result = yield performGetProceduresModes(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.modes = result.data;
    });

    const loadTypes = flow(function* () {
      const result = yield performGetProceduresTypes(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.types = result.data;
    });

    const loadOwnerships = flow(function* () {
      const result = yield performGetProceduresOwnerships(
        api,
        {
          payload: {},
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.ownerships = result.data;
    });

    const loadFilterOptions = flow(function* () {
      yield Promise.all([
        loadDevices(),
        loadModes(),
        loadTypes(),
        loadOwnerships(),
      ])
    });

    return {
      loadFilterOptions,
    }
  })

type ProceduresFiltersOptionsStoreFactoryType = typeof ProceduresFiltersOptionsStoreInferred;
interface IProceduresFiltersOptionsStoreFactory extends ProceduresFiltersOptionsStoreFactoryType {}
export const ProceduresFiltersOptionsStore:
  IProceduresFiltersOptionsStoreFactory = ProceduresFiltersOptionsStoreInferred;
export interface IProceduresFiltersOptionsStore extends Instance<IProceduresFiltersOptionsStoreFactory> {}
export interface IProceduresFiltersOptionsStoreSnapshotIn extends SnapshotIn<IProceduresFiltersOptionsStore> {}
