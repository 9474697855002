import { FormSchema } from '@shared/components/forms/FormSchema';

import { CompanyFormValues } from './CompanyModal';
import { messages } from 'Companies/companies.messages';

type CompanyFormSchema = FormSchema<CompanyFormValues>;

export const FIELD_NAMES = {
  name: messages['companyModal.name.label'],
  description: messages['companyModal.description.label'],
  dataIsolation: messages['companyModal.dataIsolation.label'],
}

export const valuesSchema: CompanyFormSchema = {
  name: {
    fieldName: 'name',
    label: FIELD_NAMES.name,
    isRequired: true,
  },
  description: {
    fieldName: 'description',
    label: FIELD_NAMES.description,
    isRequired: true,
  },
  dataIsolation: {
    fieldName: 'dataIsolation',
    label: FIELD_NAMES.dataIsolation,
    isRequired: true,
  },
};
