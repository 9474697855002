import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';

export type DeleteProcedureParams = {
  id: string;
};

export const performDeleteProcedure = async (
  api: IApiStore,
  params: EndpointCallParams<DeleteProcedureParams>
): Promise<ApiResponse> => {
  const { payload, ...rest } = params;
  const req = api
    .delete(`content-app/api/v1/Procedures/${payload.id}`, {
      ...rest,
      payload: {},
    })

  return req;
}
