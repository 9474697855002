import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performGetAllFilters = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('uiaggregator-app/api/v1/Filters/UserFilters', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserDepartments = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/Departments', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserRoles = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/Roles', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserAssignableRoles = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Users/AssignableRoles ', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserStatuses = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/UserStatuses', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserGroups = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/Groups', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUserProcedures = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('content-app/api/v1/Filters/Procedures', {
      errorHandlers,
      payload: {},
    })

  return req;
}

export const performGetUsersList = async (api: IApiStore, params: EndpointCallParams<{}>) => {
  const { errorHandlers } = params;
  const req = api
    .get('identity-app/api/v1/Filters/Users', {
      errorHandlers,
      payload: {},
    })

  return req;
}
