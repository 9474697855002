import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { flex } from '@resources/mixins';

import { UserInfoForm } from '../UserInfoForm';
import { UserAvatarView } from '../UserAvatarView';

const useStyles = createUseStyles(() => ({
  wrapper: {
    ...flex('row', 'space-between'),
    marginTop: -80,

    '@media (max-width: 1200px)': {
      ...flex('column'),
    },
  },
}));

export const ProfilePage: React.FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={styles.wrapper}>
      <UserInfoForm />
      <UserAvatarView />
    </div>
  )
}
