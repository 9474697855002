import React, { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from 'react-jss';
import { Observer } from 'mobx-react';
import { Subject } from 'rxjs';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { useProcedureUIStore } from '@core/useStores';
import { ProgressBar } from '@shared/components/ProgressBar';
import { FormError } from '@shared/components/FormError';

import { useStyles } from '../../ProcedureModal.styles';
import { DeviceActionConfirmation } from '../DeviceActionConfirmation';
import { IProcedureDeviceUI } from 'Procedures/stores/ProcedureDeviceUI.store';
import { deviceTypesImages } from 'Procedures/helpers/deviceTypesImages';
import { UploadedFileResponse } from 'Procedures/domain/FileResponse';
import { messages } from 'Procedures/procedures.messages';

export type ProcedureFileViewProps = {
  isEditMode: boolean,
  onFileUpload: (fileInfo: UploadedFileResponse) => void,
  device: IProcedureDeviceUI,
  index: number,
}

const DEFAULT_UPLOAD_ERROR = messages['procedureFileView.default.uploadError'];

export const ProcedureFileView: FC<ProcedureFileViewProps> = ({
  isEditMode, onFileUpload, device, index,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const procedureUIStore = useProcedureUIStore();

  const [file, setFile] = useState<File | null>(null);
  const [cancelSubject$] = useState<Subject<{}>>(new Subject());

  const upload = async (file: File) => {
    device.toggleLoading(true);
    device.clearError();

    const result = await procedureUIStore.uploadFile(file, device, cancelSubject$);

    device.toggleLoading(false);
    device.toggleUpdatingMode(false);

    if (device.isCanceled) {
      device.replaceDevice(device.uiId);
      setFile(null);

      return;
    }

    if (result.success) {
      device.updateDevice(result.data);
      onFileUpload(result.data);
    } else {
      const error = result?.error?.body?.messages
        ? result.error.body.messages[0]
        : DEFAULT_UPLOAD_ERROR;

      device.setDeviceError(error);
    }
  };

  const onDropAccepted = useCallback(acceptedFiles => {
    if (!device.isLoading && (device.isUpdatingMode || !device._device?.deviceId)) {
      setFile(acceptedFiles[0]);
      upload(acceptedFiles[0]);
    }
  }, []);

  const onDropRejected = useCallback(() => {
    device.setDeviceError(messages['procedureFileView.error.format']);
  }, []);

  const onFileDialogCancel = useCallback(() => {
    device.toggleUpdatingMode(false);
  }, [])

  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    accept: ['application/x-tar', '.weavr', '.wvr'],
    multiple: false,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
  });

  const { onClick, ...rootProps } = getRootProps();

  const onCancelUpload = () => {
    device.toggleCanceledState(true);
    cancelSubject$.next()
  };

  const onDeviceDelete = () => device.toggleDeleteConfirmation(true);

  const onDeviceDeleteConfirm = () => {
    if (!isEditMode && device._device?.deviceId) {
      procedureUIStore.cancelProcedureDeviceProcess(device._device.deviceId);
    }

    procedureUIStore.deleteDeviceFromList(index);
    setFile(null);
  };

  const onReplaceDevice = () => device.toggleReplaceConfirmation(true);

  const onDeviceReplaceConfirm = () => {
    device.replaceDevice(device.uiId);
    setFile(null);
    open();
  };

  const onUpdateDevice = () => {
    device.toggleUpdatingMode(true);
    setFile(null);
    open();
  };

  return (
    <div className={styles.uploadPanel} {...rootProps}>
      <div className={styles.uploadPanelInner}>
        <input {...getInputProps()} data-testid="procedure-file-view-input"/>

        <Observer>
          {() => (
            <>
              {
                !device.isUploaded && !device.isLoading && !device.hasError && (
                  <div className={styles.dragDropDescription}>
                    <CloudUploadOutlinedIcon className={styles.uploadIcon} />

                    <span className={styles.dropProcedureText}>
                      {messages['procedureFileView.dropProcedureHere']}
                    </span>

                    <span
                      onClick={onClick}
                      className={styles.uploadProcessButton}
                      data-testid="prodecure-file-view-browse"
                    >
                      {messages['procedureFileView.browse']}
                    </span>
                  </div>
                )
              }

              {
                device.isLoading && (
                  <div className={styles.uploadInProgress} data-testid="prodecure-file-view-loader">
                    <CloudUploadOutlinedIcon className={styles.uploadIconProgress} />
                    {
                      device.progress < 100
                        ? messages['procedureFileView.upload.inProgress']
                        : messages['procedureFileView.processing.inProgress']
                    }
                  </div>
                )
              }

              {
                file && device.isLoading && (
                  <div className={styles.progressBarWrapper}>
                    <ProgressBar
                      text={file?.name}
                      progress={device.progress || 0}
                    />
                  </div>
                )
              }

              {
                device.canBeCanceled && (
                  <span
                    onClick={onCancelUpload}
                    className={styles.uploadProcessButton}
                    data-testid="prodecure-file-view-cancel"
                  >
                    {messages['procedureFileView.cancel']}
                  </span>
                )
              }

              {
                device.hasError && (
                  <>
                    <div className={styles.validationFailed}>
                      <ErrorOutlineOutlinedIcon
                        className={styles.failIcon}
                        style={{ fontSize: 24 }}
                      />
                      {messages['procedureFileView.validationFailed']}
                    </div>

                    <FormError
                      text={device.error || messages['procedureFileView.default.deviceError']}
                    />

                    {
                      !device._device?.deviceId && (
                        <>
                          <span
                            onClick={onDeviceReplaceConfirm}
                            className={styles.uploadProcessButton}
                            data-testid="prodecure-file-view-upload-new-file"
                          >
                            {messages['procedureFileView.uploadNewFile']}
                          </span>

                          <span
                            // Delete file without confirmation in case upload error
                            onClick={onDeviceDeleteConfirm}
                            className={styles.uploadProcessButton}
                            data-testid="prodecure-file-view-device-delete"
                          >
                            {messages['procedureFileView.deleteDevice']}
                          </span>
                        </>
                      )
                    }
                  </>
                )
              }

              {
                device.isUploaded && (
                  <>
                    <div className={styles.deviceName} data-testid="prodecure-file-view-device-uploaded">
                      {
                        device.type && (
                          <img
                            src={deviceTypesImages[device.type]}
                            className={styles.deviceImage}
                          />
                        )
                      }
                      { device.name }
                    </div>

                    {
                      device.isActionsBlockVisible && (
                        <>
                          {
                            isEditMode
                              ? (
                                <span
                                  onClick={onUpdateDevice}
                                  className={styles.uploadProcessButton}
                                  data-testid="prodecure-file-view-update-device"
                                >
                                  {messages['procedureFileView.device.update']}
                                </span>
                              )
                              : (
                                <span
                                  onClick={onReplaceDevice}
                                  className={styles.uploadProcessButton}
                                  data-testid="prodecure-file-view-replace-device"
                                >
                                  {messages['procedureFileView.device.replace']}
                                </span>
                              )
                          }

                          <span
                            onClick={onDeviceDelete}
                            className={styles.uploadProcessButton}
                            data-testid="prodecure-file-view-delete-device"
                          >
                            {messages['procedureFileView.device.delete']}
                          </span>
                        </>
                      )
                    }
                  </>
                )
              }

              {
                device.replaceConfirmationVisible && (
                  <DeviceActionConfirmation
                    text={messages['procedureFileView.device.replace.confirmation'](device.name)}
                    confirmText={messages['procedureFileView.device.replace']}
                    onConfirm={onDeviceReplaceConfirm}
                    onRequestCancel={() => device.toggleReplaceConfirmation(false)}
                  />
                )
              }

              {
                device.deleteConfirmationVisible && (
                  <DeviceActionConfirmation
                    text={messages['procedureFileView.device.delete.confirmation'](device.name)}
                    confirmText={messages['procedureFileView.device.delete.confirmation.confirmText']}
                    onConfirm={onDeviceDeleteConfirm}
                    onRequestCancel={() => device.toggleDeleteConfirmation(false)}
                  />
                )
              }
            </>
          )}
        </Observer>
      </div>
    </div>
  )
}
