import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  modal: {
    width: 508,
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'auto',
  },
  h2: {
    fontFamily: theme.font.secondary,
    fontSize: 24,
    lineHeight: 1.33,
    color: theme.colors.black,
    fontWeight: 'normal',
    marginBottom: theme.spacing(7),
  },
  field: {
    marginBottom: theme.spacing(6),
  },
  dropdown: {
    margin: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  button: {
    marginLeft: theme.spacing(5),
  },
}));
