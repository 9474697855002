import React from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import color from 'color';

import { ThemeProps } from '@styles/theme';

export type FormErrorProps = {
  text: string;
  className?: string;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  errorBox: {
    maxWidth: 468,
    fontFamily: theme.font.secondary,
    background: `${color(theme.colors.red).fade(0.2)}`,
    color: theme.colors.white,
    fontSize: 16,
    lineHeight: '24px',
    padding: [theme.spacing(3), theme.spacing(4)],
    borderRadius: theme.radius,
    marginBottom: theme.spacing(4),
    display: 'flex',
  },

  icon: {
    marginRight: theme.spacing(4),
  },
}))

export const FormError: React.FC<FormErrorProps> = ({ text, className }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <section className={classNames(styles.errorBox, className)}>
      <ErrorOutlineOutlinedIcon className={styles.icon} />
      <div data-testid="form-error-title">{ text }</div>
    </section>
  )
};

FormError.displayName = 'FormError';
