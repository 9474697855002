import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { Form, Formik, FormikProps } from 'formik';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { FormSchema } from '@shared/components/forms/FormSchema';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';

import { AutoSubmit } from './AutoSubmit';
import { useStyles } from './SearchForm.styles';
import { messages } from '@shared/components/components.messages';

class FormValues {
  search = '';
}

type SearchFormSchema = FormSchema<FormValues>;

const valueSchema: SearchFormSchema = {
  search: {
    isRequired: true,
    fieldName: 'search',
    placeholder: messages['searchForm.placeholder'],
  },
}

type SearchPrefixProps = {
  hasValue: boolean;
}

const SearchPrefix: React.FC<SearchPrefixProps> = ({ hasValue = false }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={classNames(styles.search, { [styles.touched]: hasValue })}>
      <SearchOutlinedIcon
        className={classNames(styles.icon, { [styles.touched]: hasValue })}
      />
    </div>
  )
}

export type SearchFormProps = {
  onSearchTextChanged: Function;
  valueDelay?: number;
  className?: string;
  initialValues?: FormValues;
  disabled?: boolean;
}

export const SearchForm: React.FC<SearchFormProps> = ({
  onSearchTextChanged, valueDelay = 500, className,
  initialValues = { search: '' }, disabled,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const searchTextChange$ = new Subject();

  searchTextChange$
    .pipe(debounceTime(valueDelay))
    .subscribe((value: any) => {
      onSearchTextChanged(value.search);
    });

  return (
    <Formik
      onSubmit={(values: FormValues) => searchTextChange$.next(values)}
      initialValues={initialValues}
    >
      {({ values, submitForm }: FormikProps<FormValues>) => (
        <>
          <Form className={className} autoComplete='off' noValidate>
            <FormikTextInput
              schema={{ ...valueSchema.search, className: styles.field }}
              prefix={() => <SearchPrefix hasValue={!!values.search} />}
              disabled={disabled}
              cleanable
            />
          </Form>
          <AutoSubmit values={values} submitForm={submitForm} />
        </>
      )}
    </Formik>
  )
}
