import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { ThemeProps } from '@styles/theme';

type ToastMessageProps = {
  message: React.ReactNode,
  // TODO move to enum
  type: 'error' | 'success'
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: theme.spacing(4),
  },
}))

export const ToastMessage: FC<ToastMessageProps> = ({
  message,
  type,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const MESSAGE_ICONS: Record<string, any> = {
    error: <ErrorOutlineOutlinedIcon className={styles.icon} style={{ fontSize: 24 }} />,
    success: <CheckOutlinedIcon className={styles.icon} style={{ fontSize: 24 }} />,
  }

  return (
    <div className={styles.container}>
      {MESSAGE_ICONS[type] || <ErrorOutlineOutlinedIcon className={styles.icon} />}
      <div data-testid="toast-message">
        {message}
      </div>
    </div>
  )
}
