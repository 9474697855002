import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  halfWidth: {},

  wrapper: {
    backgroundColor: theme.colors.white,
    padding: [theme.spacing(8), theme.spacing(6)],
    border: `1.5px solid ${theme.colors.grey_light}`,
    borderRadius: theme.radius,
    flexBasis: 674,
  },

  heading: {
    marginBottom: theme.spacing(8),
  },

  field: {
    marginBottom: theme.spacing(6),

    '&$halfWidth': {
      width: 'calc(50% - 12px)',
    },
  },

  fieldWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));
