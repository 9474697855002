import { pick } from 'lodash';

export type LoginFormValues = {
  email: string,
  password: string,
};

export class FormValues {
  email = '';
  password = '';

  constructor(loginData?: LoginFormValues | null) {
    if (loginData) {
      Object.assign(this, pick(loginData, ...Object.keys(this)));
    }
  }
}
