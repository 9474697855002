import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { NavigationManager } from '@ace/core';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Button } from '@shared/components/Button';
import { messages } from '@shared/components/components.messages';

export type AppExternalSuccessMessageProps = {
  text: string;
  navigator: NavigationManager;
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
  successMessageWrapper: {
    color: theme.colors.white,
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: theme.font.secondary,
    width: 310,
    marginBottom: theme.spacing(6),
    display: 'flex',
  },

  viewWrapper: {
    width: 468,
    ...flex('column', 'center', 'center'),
  },

  icon: {
    marginRight: theme.spacing(4),
    color: theme.colors.blue_neon,
  },
}))

export const AppExternalSuccessMessage: React.FC<AppExternalSuccessMessageProps> = ({ text, navigator }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const navigateToLogin = () => navigator.to(RoutePaths.login);

  return (
    <div className={styles.viewWrapper}>
      <p className={styles.successMessageWrapper}>
        <DoneOutlinedIcon className={styles.icon} />
        { text }
      </p>

      <Button
        styleVariant="appExternal"
        onClick={navigateToLogin}
      >
        {messages['appExternalSuccessMessage.back']}
      </Button>
    </div>
  )
};

AppExternalSuccessMessage.displayName = 'AppExternalSuccessMessage';
