import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import logo from '@assets/logo.svg';
import bgImage from '@assets/app-external-bg.png';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  outerWrapper: {
    backgroundImage: 'radial-gradient(circle at 53% 40%, #291567, #0a0a12 214%);',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',

    '&:after': {
      content: '""',
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      background: `url(${bgImage}) center no-repeat`,
      backgroundSize: 'cover',
      pointerEvents: 'none',
    },
  },

  innerWrapper: {
    padding: theme.spacing(8),
    position: 'relative',
    zIndex: 1,
  },

  logoWrapper: {
    margin: { bottom: theme.spacing(8) },
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const AppExternalLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <section className={styles.outerWrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.logoWrapper}>
          <img src={logo} alt='WeaVR Manager logo' />
        </div>

        <section>
          { children }
        </section>
      </div>
    </section>
  )
}
