import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';

import {
  performEditCompany,
  performGetCompany,
  performDeleteCompany,
} from 'Companies/services';
import { IStoresEnv } from '@core/storesEnv';

export const userInitialState = {
  id: '',
  name: '',
  description: '',
  dataIsolation: false,
}

export const CompanyStoreInferred = types
  .model('CompanyStoreInferred', {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.string),
    dataIsolation: types.optional(types.boolean, false),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const editCompany = flow(function* (payload: any) {

      const result = yield performEditCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
          disableDefaultErrorHandlers: true,
        },
      );

      return result;
    });

    const loadCompany = flow(function* (payload: any) {
      const result = yield performGetCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    const deleteCompany = flow(function* (payload: any) {
      const result = yield performDeleteCompany(
        api,
        {
          payload,
          errorHandlers: { DEFAULT: '' },
        },
      );

      return result;
    });

    return {
      editCompany,
      loadCompany,
      deleteCompany,
    }
  })

type CompanyStoreFactoryType = typeof CompanyStoreInferred;
interface ICompanyStoreFactory extends CompanyStoreFactoryType {}
export const CompanyStore: ICompanyStoreFactory = CompanyStoreInferred;
export interface ICompanyStore extends Instance<ICompanyStoreFactory> {}
export interface ICompanyStoreSnapshotIn extends SnapshotIn<ICompanyStore> {}
