import React, { FC } from 'react';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';

import { messages } from '@shared/components/components.messages';
import { useStyles } from './SummaryPanel.styles';

type PanelItemType = {
  id: number,
  title: string,
  text: string,
  icon: string
}

type SummaryPanelProps = {
  className?: string,
  title: string | React.ReactNode,
  items: Array<PanelItemType>,
  isEditable?: boolean,
  onEdit?: () => void
}

export const SummaryPanel: FC<SummaryPanelProps> = ({
  className, isEditable, onEdit, items, title,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={classNames(styles.panel, className)}>
      <div className={styles.left}>
        <Typography variant="h5" data-testid="summary-panel-title">{title}</Typography>
        {
          isEditable && (
            <span className={styles.iconWrapper} title={messages['usersSummary.editGroup']}>
              <EditOutlinedIcon
                onClick={onEdit}
                className={styles.icon}
              />
            </span>
          )
        }
      </div>
      <div className={styles.items}>
        {items.map((item: PanelItemType) => (
          <div className={styles.item} key={item.id} data-testid="summary-panel-list-item">
            <img src={item.icon} />
            <div className={styles.info}>
              <p className={styles.itemTitle}>{item.title}</p>
              <p className={styles.text}>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
