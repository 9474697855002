import React, { useState } from 'react'

import {
  FormikTextInput,
  FormikTextInputProps,
} from '@shared/components/forms/formikWrappers/FormikTextInput';
import { TextInputProps } from '../../inputs/TextInput';
import { VisibilityTogglePrefix } from './VisibilityTogglePrefix';
import { PasswordInputType } from './PasswordInputType';

export const FormikPasswordInput: React.FC<FormikTextInputProps> = props => {
  const [type, setType] = useState(PasswordInputType.PASSWORD);

  return (
    <FormikTextInput
      { ...props }
      type={type}
      suffix={
        (props: TextInputProps) => (
          <VisibilityTogglePrefix type={type} setType={setType} value={props.meta.value} />
        )
      }
    />
  )
}
