/**
 * use https://material-ui.com/components/typography/ to find matching 'variant'
 * see https://material-ui.com/api/typography/ to find overriding className
 */

import { createUseStyles } from 'react-jss';
import { ThemeProps } from './theme';

export const useTypographyStyles = createUseStyles((theme: ThemeProps) => ({
  '@global': {
    '.app .MuiTypography-root': {
      fontFamily: theme.font.secondary,
      fontWeight: 'normal',
      color: theme.colors.black,
    },
    '.app .MuiTypography-h6': {
      lineHeight: 1.4,
    },
    '.app .MuiTypography-body1': {
      lineHeight: 1.25,
    },
    '.app .MuiTypography-colorSecondary': {
      color: theme.colors.grey_text,
    },
  },
}))
