import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';

import { useStyles } from './ConfirmationModal.styles';
import { messages } from '@shared/components/components.messages';

type ConfirmationModalProps = {
  isOpen: boolean;
  message: string;
  note?: string;
  cancelText?: string;
  confirmText?: string;
  onDecline: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  message,
  note,
  cancelText = messages['confirmationModal.default.cancelText'],
  confirmText = messages['confirmationModal.default.confirmText'],
  onDecline,
  onConfirm,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={onDecline}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <h2 className={styles.h2} data-testid="confirmation-modal-title">{message}</h2>
      {
        note && <p className={styles.note}>{note}</p>
      }
      <div className={styles.buttons}>
        <Button
          onClick={onDecline}
          color="secondary"
        >
          {cancelText}
        </Button>

        <Button
          onClick={onConfirm}
          className={styles.approve}
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  )
}
