import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { Column } from 'react-table';
import { getEnv } from 'mobx-state-tree';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { Grid } from '@shared/components/Grid';
import { SearchForm } from '@shared/components/SearchForm';
import { UsersSummary } from '@shared/components/UsersSummary';
import { NoResultsView } from '@shared/components/NoResultsView';
import { useAnalyticsGridUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';

import { NoUsersInGroup } from '../NoUsersInGroup';
import { GridItem } from './GridItem';
import { messages } from 'Analytics/analytic.messages';
import { useStyles } from './AnalyticsPage.styles';

type FetchDataType = {
  pageIndex: number;
};

const initialSortState = {
  sortBy: [{
    id: 'fullName',
    desc: false,
  }],
};

const columnsData = [
  { accessor: 'userId' },
  { accessor: 'userFirstName' },
  { accessor: 'userLastName' },
  { accessor: 'completedProcedures' },
  { accessor: 'assignedProcedures' },
];

export const AnalyticsPage: React.FC = () => {
  const analyticsGridUIStore = useAnalyticsGridUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(analyticsGridUIStore);

  const theme = useTheme();
  const styles = useStyles({ theme });

  const columns: Column<object>[] = React.useMemo(() => columnsData, []);

  useEffect(() => {
    analyticsGridUIStore.togglePageActivity(true);

    analyticsGridUIStore.load();
    analyticsGridUIStore.loadStatistics();

    filtersOptions.loadFilterOptions();

    return () => {
      analyticsGridUIStore.togglePageActivity(false);
      analyticsGridUIStore.cleanUp();
    }
  }, []);

  const onFetchData = ({ pageIndex }: FetchDataType) => {
    const params = { page: pageIndex };

    analyticsGridUIStore.setParams(params);
  };

  const onSearchTextChanged = (searchText: string) => {
    if (searchText && searchText.length === 1) {
      return;
    }

    analyticsGridUIStore.setParams({
      page: 0,
      searchText,
    });
  };

  return (
    <>
      <UsersSummary
        statistics={analyticsGridUIStore.statistics}
        pageQueryStore={analyticsGridUIStore.requestParams}
      />

      <div className={styles.gridActions}>
        <Observer>
          {() => (
            <>
              <div className={styles.pageTitle}>
                <Typography variant="h6">{messages['analyticsPage.pageTitle']}</Typography>

                {analyticsGridUIStore.status.isLoading && (
                  <CircularProgress className={styles.loader} size={24} />
                )}
              </div>

              <div className={styles.filters}>
                <SearchForm
                  initialValues={{
                    search: analyticsGridUIStore.requestParams.getStorageParams
                      ? analyticsGridUIStore.requestParams.getStorageParams.searchText
                      : '',
                  }}
                  onSearchTextChanged={onSearchTextChanged}
                  className={styles.searchWrapper}
                  disabled={analyticsGridUIStore.status?.isLoading}
                />
              </div>
            </>
          )}
        </Observer>
      </div>

      <Observer>
        {() => {
          const data = analyticsGridUIStore.users.toJS();

          return (
            <>
              {
                analyticsGridUIStore.isTableVisible && (
                  <Grid
                    data={data}
                    columns={columns}
                    fetchData={onFetchData}
                    GridItem={GridItem}
                    isLoading={analyticsGridUIStore.status?.isLoading}
                    paginationStatus={{...analyticsGridUIStore.pagination}}
                    initialState={initialSortState}
                  />
                )
              }

              {
                analyticsGridUIStore.noFilteringResults && <NoResultsView entityName='users' />
              }

              {
                analyticsGridUIStore.noItemsInGroup && <NoUsersInGroup />
              }
            </>
          )
        }}
      </Observer>
    </>
  )
}
