import { RouteProps, Route, Redirect } from 'react-router-dom';
import React from 'react';

import { useUserProfileUIStore } from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';

export const AnonymousRoute = ({ children, ...rest }: RouteProps) => {
  const userProfileUIStore = useUserProfileUIStore();

  return (
    <Route
      {...rest}
      render={() =>
        userProfileUIStore.isAuthentificated
          ? <Redirect to={{ pathname: RoutePaths.root }} />
          : children
      }
    />
  );
}
