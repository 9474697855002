import { Instance, SnapshotIn, types } from 'mobx-state-tree';

const BaseItemInferred = types.model('BaseItem', {
  id: types.string,
  name: types.string,
});

type BaseItemFactoryType = typeof BaseItemInferred;
interface IBaseItemFactory extends BaseItemFactoryType {}
export const BaseItem: IBaseItemFactory = BaseItemInferred;
export interface IBaseItem extends Instance<IBaseItemFactory> {}
export interface IBaseItemSnapshotIn extends SnapshotIn<IBaseItem> {}
