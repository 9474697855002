import { Instance, SnapshotIn, types, getSnapshot } from 'mobx-state-tree';

import { BasePageQueryStore } from '@shared/stores/BasePageQuery.store';

const DEFAULT_PAGE_LIMIT = 12;
export const ANALYTICS_FILTERS_KEY = 'analytics_filters';

export const PageQueryStoreInferred = types.compose(BasePageQueryStore, types
  .model({
    limit: DEFAULT_PAGE_LIMIT,
    startDate: types.maybe(types.string),
    endDate: types.maybe(types.string),
  }))
  .volatile(() => ({
    _FILTER_KEY: ANALYTICS_FILTERS_KEY,
  }))
  .views(self => ({
    get getParams() {
      return getSnapshot(self);
    },
  }))
  .actions(self => ({
    setParams(params: IPageQueryStoreSnapshotIn) {
      self._setParams<IPageQueryStoreSnapshotIn>(params);
    },
  }))
  .actions(self => ({
    _prefillFromStorage() {
      if (self.getStorageParams) {
        self.setParams(self.getStorageParams);
      }
    },
  }))
  .actions(self => ({
    resetParams() {
      const FILTERS_TO_KEEP = {
        inGroup: self.getParams.inGroup,
        inGroupId: self.getParams.inGroupId,
      }
      self._resetParams(FILTERS_TO_KEEP);
    },
  }));

type PageQueryStoreFactoryType = typeof PageQueryStoreInferred;
interface IPageQueryStoreFactory extends PageQueryStoreFactoryType {}
export const PageQueryStore: IPageQueryStoreFactory = PageQueryStoreInferred;
export interface IPageQueryStore extends Instance<IPageQueryStoreFactory> {}
export interface IPageQueryStoreSnapshotIn extends SnapshotIn<IPageQueryStore> {}
