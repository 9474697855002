import * as React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';

import { MainMenu} from './components/MainMenu';
import { UserMenu } from './components/UserMenu';

import logo from '@assets/logo.svg';
import headerBackground from '@assets/header-background.png';
import { flex } from '@styles/resources/mixins';
import { ThemeProps } from '@styles/theme';
import { messages } from './header.messages';

import { getEnv } from 'mobx-state-tree';
import { IStoresEnv } from '@core/storesEnv';
import { useUserProfileUIStore } from '../@core/useStores';
import { Observer } from 'mobx-react';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  wrapper: {
    height: 144,
    color: theme.colors.white,
    backgroundColor: theme.colors.violet_darkest,
    backgroundImage: `url(${headerBackground})`,
    backgroundSize: 'cover',
    padding: [theme.spacing(7), theme.spacing(8)],
    '@media (max-width: 1200px)': {
      padding: [theme.spacing(7), theme.spacing(5)],
    },
  },
  container: {
    maxWidth: 1440,
    margin: '0 auto',
    ...flex('row', 'space-between', 'flex-start'),
    padding: [0, theme.spacing(8)],
    '@media (max-width: 1200px)': {
      padding: 0,
    },
  },
  verticalContainer: {
    ...flex('column', '', 'flex-start'),
  },
  sideWrapper: {
    ...flex('row', '', 'flex-start'),
  },
  centeredLabel: {
    alignSelf: 'stretch',
    textAlign: 'center',
  },
  logoLink: {
    marginRight: theme.spacing(9),
    '@media (max-width: 1200px)': {
      marginRight: theme.spacing(5),
      '& img': {
        width: 145,
      },
    },
  },
}))

export const Header = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const { api } = getEnv<IStoresEnv>(useUserProfileUIStore());
  const { auth } = getEnv(api);

  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <section className={styles.sideWrapper}>
          <div className={styles.verticalContainer}>
            <Link to="/" className={styles.logoLink}>
              <img src={logo} alt={messages['header.logo.alt']} />
            </Link>
            <Observer>
              {() => (
                <p className={styles.centeredLabel}>{auth.userCompany?.name}</p>
              )}
            </Observer>
          </div>
          <MainMenu />
        </section>
        <UserMenu />
      </div>
    </header>
  );
}
