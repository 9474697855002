import React, { FC, useEffect, useState } from 'react';
import { getEnv } from 'mobx-state-tree';
import { useTheme } from 'react-jss';
import { Form, Formik, FormikProps } from 'formik';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { SelectOption, SelectOptionProps } from '@shared/components/SelectDropdown/Option';
import { OptionType } from '@shared/components/SelectDropdown';
import { getOptionByString, convertStringsToOptions, getOptionsByStrings } from '@shared/helpers/form';
import { FormError } from '@shared/components/FormError';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { useUsersGridUIStore, useProceduresUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';
import {
  LAST_LOGIN_START_OPTIONS,
  LAST_LOGIN_END_OPTIONS,
  START_OPTION_TYPE
} from 'Users/components/UsersFiltersModal';

import { messages } from 'Procedures/procedures.messages';
import { useStyles } from './ProceduresFiltersModal.styles';

export const COMMON_SELECT_PROPS = {
  isSearchable: false,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: true,
  closeMenuOnSelect: false,
  components: { Option: SelectOption },
}

export type ProceduresFiltersModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
}

const COLLABORATION_OPTION_AVAILABLE = {
  value: true, label: messages['proceduresFiltersModal.option.available'],
};
const COLLABORATION_OPTION_NOT_AVAILABLE = {
  value: false, label: messages['proceduresFiltersModal.option.notAvailable'],
};
export const DEFAULT_EXECUTIONS_MIN = 0;
export const DEFAULT_EXECUTIONS_MAX = 10000;

export const COLLABORATION_OPTIONS = [
  COLLABORATION_OPTION_AVAILABLE,
  COLLABORATION_OPTION_NOT_AVAILABLE,
]

export class ProceduresFiltersFormValues {
  devices = [] as OptionType[];
  modes = [] as OptionType[];
  types = [] as OptionType[];
  collaboration = { value: '', label: '' } as { value: boolean | string | null, label: string };
  executionsMin = DEFAULT_EXECUTIONS_MIN as number | undefined;
  executionsMax = DEFAULT_EXECUTIONS_MAX as number | undefined;
  groupIds = [] as Array<{ value: string, label: string | undefined }>;
  lastModified = '' as { value: string, label: string | undefined } | string;
}

// TODO move to helpers or avoid it
const normalizeToStrings = (options: Record<string, any>) =>
  (options && options.length >= 0)
    ? options.map((option: OptionType) => option.value)
    : []

const emptyOption = { value: '', label: '' };

export const ProceduresFiltersModal: FC<ProceduresFiltersModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const usersGridUIStore = useUsersGridUIStore();
  const proceduresGridUIStore = useProceduresUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);

  const requestParams = proceduresGridUIStore.requestParams.getParams;

  const [stateLastModifiedStart, setLastModifiedStart] = useState(requestParams.lastModifiedStart || '');
  const [stateLastModifiedEnd, setLastModifiedEnd] = useState(requestParams.lastModifiedEnd || '');

  const devicesOptions = proceduresGridUIStore.proceduresFilters.getDevicesOptions;
  const modesOptions = proceduresGridUIStore.proceduresFilters.getModesOptions;
  const typesOptions = proceduresGridUIStore.proceduresFilters.getTypesOptions;
  const groupsOptions = filtersOptions.getGroupsOptions;

  useEffect(() => {
    if (!proceduresGridUIStore.proceduresFilters.devices.length) {
      proceduresGridUIStore.proceduresFilters.loadFilterOptions();
    }
    if (!groupsOptions.length) {
      filtersOptions.loadFilterOptions();
    }
  }, []);

  const onLastModifiedSelectChange = (option: OptionType) => {
    const isStartType = option.type === START_OPTION_TYPE;

    setLastModifiedStart(isStartType ? option.value : '');
    setLastModifiedEnd(isStartType ? '' : option.value);
  }

  const onSubmit = async (values: ProceduresFiltersFormValues) => {
    const updatedParams = {
      page: 0,
      groupIds: normalizeToStrings(values.groupIds),
      devices: normalizeToStrings(values.devices),
      modes: normalizeToStrings(values.modes),
      types: normalizeToStrings(values.types),
      executionsMin: values.executionsMin || DEFAULT_EXECUTIONS_MIN,
      executionsMax: values.executionsMax || DEFAULT_EXECUTIONS_MAX,
      collaboration: typeof values.collaboration.value === 'string'
        ? null
        : values.collaboration.value,
      lastModifiedStart: stateLastModifiedStart,
      lastModifiedEnd: stateLastModifiedEnd,
    }

    proceduresGridUIStore.setParams(updatedParams);

    setLastModifiedStart('');
    setLastModifiedEnd('');
    onRequestClose();
  }

  return (
    <Observer>
      {() => {
        const {
          groupIds,
          devices,
          modes,
          types,
          collaboration,
          lastModifiedStart = '',
          lastModifiedEnd = '',
          executionsMin,
          executionsMax,
        } = proceduresGridUIStore.requestParams.getParams;

        const initialValues = {
          devices: convertStringsToOptions(devices),
          modes: convertStringsToOptions(modes),
          types: convertStringsToOptions(types),
          collaboration: collaboration === null
            ? emptyOption
            : collaboration ? COLLABORATION_OPTION_AVAILABLE : COLLABORATION_OPTION_NOT_AVAILABLE,
          executionsMin: executionsMin || DEFAULT_EXECUTIONS_MIN,
          executionsMax: executionsMax || DEFAULT_EXECUTIONS_MAX,
          lastModified: lastModifiedStart
            ? getOptionByString(lastModifiedStart, LAST_LOGIN_START_OPTIONS)
            : getOptionByString(lastModifiedEnd, LAST_LOGIN_END_OPTIONS),
          groupIds: getOptionsByStrings(groupIds, filtersOptions.getGroupsOptions),
        }

        return (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
          >
            <h2 className={styles.h2}>{messages['proceduresFiltersModal.title']}</h2>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
            >
              {(form: FormikProps<ProceduresFiltersFormValues>) => {
                const formErrors: Record<string, any> = form.errors;
                const errors = Object.keys(formErrors).length
                  ? Object.keys(formErrors)
                    .map((key: string) => formErrors[key].value ? formErrors[key].value : formErrors[key])
                  : [];

                return (
                  <Form className={styles.form} autoComplete='off' noValidate>
                    <>
                      {errors?.length ? (
                        <div className={styles.errors}>
                          {errors.map((text: string) => (
                            <FormError className={styles.error} key={text} text={text} />
                          ))}
                        </div>
                      ) : null}
                    </>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.devices}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={devicesOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.modes}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={modesOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.types}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={typesOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.collaboration}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={COLLABORATION_OPTIONS}
                      />
                    </div>
                    <div className={styles.fieldWrapper}>
                      <div className={classNames(styles.field, styles.halfWidth)}>
                        <FormikTextInput schema={valuesSchema.executionsMin} />
                      </div>
                      <div className={classNames(styles.field, styles.halfWidth)}>
                        <FormikTextInput schema={valuesSchema.executionsMax} />
                      </div>
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.groupIds}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={groupsOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.lastModified}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={[...LAST_LOGIN_START_OPTIONS, ...LAST_LOGIN_END_OPTIONS]}
                        onChange={onLastModifiedSelectChange}
                        components={{
                          Option: (props: SelectOptionProps) => <SelectOption {...props} isSelected={false} />,
                        }}
                      />
                    </div>
                    <div className={styles.buttons}>
                      <Button
                        onClick={onRequestClose}
                        className={styles.button} color="secondary"
                      >
                        {messages['proceduresFiltersModal.cancel']}
                      </Button>
                      <Button
                        className={styles.button}
                        type="submit"
                      >
                        {messages['proceduresFiltersModal.apply']}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal>
        )
      }}
    </Observer>
  )
}
