import { ISelectOption } from '@shared/helpers/form/ISelectOption';

export type GroupFormValues = {
  name: string,
  users: ISelectOption[] | null,
  procedures: ISelectOption[] | null,
};

export class FormValues {
  name = '';
  users = [];
  procedures = [];
}
