import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const paginationStatusInitialState = {
  currentPage: 0,
  limit: 10,
  isFirstPage: true,
  isLastPage: false,
  totalEntities: 0,
  totalPages: 0,
}

export const PaginationStatusStoreInferred = types
  .model({
    currentPage: types.optional(types.number, 0),
    limit: types.optional(types.number, 10),
    isFirstPage: types.optional(types.boolean, true),
    isLastPage: types.optional(types.boolean, false),
    totalEntities: types.optional(types.number, 0),
    totalPages: types.optional(types.number, 0),
  })

type PaginationStatusStoreFactoryType = typeof PaginationStatusStoreInferred;
interface IPaginationStatusStoreFactory extends PaginationStatusStoreFactoryType {}
export const PaginationStatusStore: IPaginationStatusStoreFactory = PaginationStatusStoreInferred;
export interface IPaginationStatusStore extends Instance<IPaginationStatusStoreFactory> {}
export interface IPaginationStatusStoreSnapshotIn extends SnapshotIn<IPaginationStatusStore> {}
