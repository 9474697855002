import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UserAnalyticStatisticStoreInferred = types
  .model({
    assignedProcedures: (types.number, 0),
    completedProcedures: (types.number, 0),
    inProgress: (types.number, 0),
    userFirstName: types.maybeNull(types.string),
    userLastName: types.maybeNull(types.string),
    userAvatar: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
  });

type UserAnalyticStatisticStoreFactoryType = typeof UserAnalyticStatisticStoreInferred;
interface IUserAnalyticStatisticStoreFactory extends UserAnalyticStatisticStoreFactoryType {}
export const UserAnalyticStatisticStore: IUserAnalyticStatisticStoreFactory = UserAnalyticStatisticStoreInferred;
export interface IUserAnalyticStatisticStore extends Instance<IUserAnalyticStatisticStoreFactory> {}
export interface IUserAnalyticStatisticStoreSnapshotIn extends SnapshotIn<IUserAnalyticStatisticStore> {}
