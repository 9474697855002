import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';
import { AnalyticsLineChart } from '../AnalyticsLineChart';
import { AnalyticsPieChart } from '../AnalyticsPieChart';
import { UserAnalyticsFilter } from '../UserAnalyticsFilter';
import { UserAnalyticsSummary } from '../UserAnalyticsSummary';

const useStyles = createUseStyles((theme: ThemeProps) => ({
  body: {
    padding: theme.spacing(0),
  },
}))

export const UserAnalyticsPage: FC = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className={styles.body}>
      <UserAnalyticsSummary />
      <UserAnalyticsFilter />
      <AnalyticsPieChart />
      <AnalyticsLineChart />
    </div>
  )
}
