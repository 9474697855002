import { Instance, SnapshotIn, types, getSnapshot } from 'mobx-state-tree';

import { BasePageQueryStore } from '@shared/stores/BasePageQuery.store';
import { ALL_PROCEDURES_ID, PROCEDURES_AVAILABILITY_ALL } from 'Procedures/domain/constants';

export const PROCEDURES_FILTERS_KEY = 'procedures_filters';

export const PageQueryStoreInferred = types.compose(BasePageQueryStore, types
  .model({
    inGroup: ALL_PROCEDURES_ID,
    procedureAvailability: PROCEDURES_AVAILABILITY_ALL,
    groupIds: types.array(types.string),
    devices: types.array(types.string),
    modes: types.array(types.string),
    types: types.array(types.string),
    collaboration: types.maybeNull(types.boolean),
    lastModifiedStart: types.maybe(types.string),
    lastModifiedEnd: types.maybe(types.string),
    executionsMin: types.maybe(types.number),
    executionsMax: types.maybe(types.number),
  }))
  .volatile(() => ({
    _FILTER_KEY: PROCEDURES_FILTERS_KEY,
  }))
  .views(self => ({
    get getParams() {
      return getSnapshot(self);
    },
  }))
  .actions(self => ({
    setParams(params: IPageQueryStoreSnapshotIn) {
      self._setParams<IPageQueryStoreSnapshotIn>(params);
    },
  }))
  .actions(self => ({
    _prefillFromStorage() {
      if (self.getStorageParams) {
        self.setParams(self.getStorageParams);
      }
    },
  }))
  .actions(self => ({
    resetParams() {
      const FILTERS_TO_KEEP = {
        inGroup: self.getParams.inGroup,
        inGroupId: self.getParams.inGroupId,
        procedureAvailability: self.getParams.procedureAvailability,
        sortFieldName: self.getParams.sortFieldName,
        sortDirection: self.getParams.sortDirection,
      }
      self._resetParams(FILTERS_TO_KEEP);
    },
  }));

type PageQueryStoreFactoryType = typeof PageQueryStoreInferred;
interface IPageQueryStoreFactory extends PageQueryStoreFactoryType {}
export const PageQueryStore: IPageQueryStoreFactory = PageQueryStoreInferred;
export interface IPageQueryStore extends Instance<IPageQueryStoreFactory> {}
export interface IPageQueryStoreSnapshotIn extends SnapshotIn<IPageQueryStore> {}
