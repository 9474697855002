import { createUseStyles } from 'react-jss';

import { flex } from '@styles/resources/mixins';
import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  wrapper: {
    ...flex('row', '', 'center'),
    position: 'relative',
  },
  userName: {
    fontFamily: theme.font.primary,
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.white,
    display: 'inline-block',
    marginRight: theme.spacing(4),
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    transformOrigin: 'center',
    transform: 'scale(1, -1)',
    transition: 'transform .2s ease',
    cursor: 'pointer',
  },
  rotated: {
    transform: 'scale(1, 1)',
  },
  dropdown: {
    position: 'absolute',
    right: 0,
    top: 48,
    width: 216,
  },
}));
