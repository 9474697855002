import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  headingWrapper: {
    ...flex('row', 'space-between', 'center'),
    marginBottom: theme.spacing(6),
  },

  titleWrapper: {
    ...flex('row', '', 'center'),
  },

  addIcon: {
    color: theme.colors.violet_primary,
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },

  loader: {
    color: theme.colors.violet_primary,
  },
}));
