import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UserProfileInferred = types.model('UserProfile', {
  id: types.identifier,
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  avatar: types.maybeNull(types.string),
  department: types.maybeNull(types.string),
  companyId: types.maybeNull(types.string),
})
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
  }));

type UserProfileFactoryType = typeof UserProfileInferred;
interface IUserProfileFactory extends UserProfileFactoryType {}
export const UserProfile: IUserProfileFactory = UserProfileInferred;
export interface IUserProfile extends Instance<IUserProfileFactory> {}
export interface IUserProfileSnapshotIn extends SnapshotIn<IUserProfile> {}
