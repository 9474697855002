import { Instance, SnapshotIn, types, getEnv, flow } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';

export const UserProfileUIStoreInferred = types
  .model({})
  .views(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { auth } = getEnv(api);

    return {
      get isAuthentificated() {
        return !!auth.authHeaderValue;
      },

      get userInfo() {
        return auth.userProfile;
      },
    }
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);
    const { auth } = getEnv(api);

    const signOut = flow(function* () {
      yield auth.signOut();
    });

    return {
      signOut,
    }
  })

type UserProfileUIStoreFactoryType = typeof UserProfileUIStoreInferred;
interface IUserProfileUIStoreFactory extends UserProfileUIStoreFactoryType {}
export const UserProfileUIStore: IUserProfileUIStoreFactory = UserProfileUIStoreInferred;
export interface IUserProfileUIStore extends Instance<IUserProfileUIStoreFactory> {}
export interface IUserProfileUIStoreSnapshotIn extends SnapshotIn<IUserProfileUIStore> {}
