import * as Yup from 'yup';
import { CompanyFormValues } from './';
import { FIELD_NAMES } from './valuesSchema';
import { messages } from 'Companies/companies.messages';

const NAME_REGEX = /^([0-9A-Za-z-\s]+)$/;

export const errors = {
  required: messages['companyModal.error.required'],
};

export const validationSchema = Yup.object().shape<Partial<CompanyFormValues>>({
  name: Yup.string()
    .matches(NAME_REGEX, messages['companyModal.validation.name'](FIELD_NAMES.name))
    .required(errors.required(FIELD_NAMES.name)),
});
