import React, { FC, useState } from 'react';
import { useTheme } from 'react-jss';
import { getEnv } from 'mobx-state-tree';
import { Form, Formik } from 'formik';
import { Observer } from 'mobx-react';
import moment from 'moment';

import { IStoresEnv } from '@core/storesEnv';
import { useUsersGridUIStore } from '@core/useStores';
import { Button } from '@shared/components/Button';
import { Modal } from '@shared/components/Modal';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { SelectOption, SelectOptionProps } from '@shared/components/SelectDropdown/Option';
import { FormSchema } from '@shared/components/forms/FormSchema';
import { OptionType } from '@shared/components/SelectDropdown';
import { getOptionsByStrings, getOptionByString } from '@shared/helpers/form';

import { useStyles } from './UsersFiltersModal.styles';
import { messages } from 'Users/users.messages';

export type UsersFiltersModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
}

class FormValues {
  departments = [];
  roleIds = [];
  statusIds = [];
  groupIds = [];
  procedureIds = [];
  lastLogin = '';
}

type FilterFormSchema = FormSchema<FormValues>;

const valuesSchema: FilterFormSchema = {
  departments: {
    fieldName: 'departments',
    label: messages['usersFiltersModal.departments.label'],
    placeholder: '',
  },
  roleIds: {
    fieldName: 'roleIds',
    label: messages['usersFiltersModal.roleIds.label'],
    placeholder: '',
  },
  statusIds: {
    fieldName: 'statusIds',
    label: messages['usersFiltersModal.statusIds.label'],
    placeholder: '',
  },
  lastLogin: {
    fieldName: 'lastLogin',
    label: messages['usersFiltersModal.lastLogin.label'],
    placeholder: '',
  },
  groupIds: {
    fieldName: 'groupIds',
    label: messages['usersFiltersModal.groupIds.label'],
    placeholder: '',
  },
  procedureIds: {
    fieldName: 'procedureIds',
    label: messages['usersFiltersModal.procedureIds.label'],
    placeholder: '',
  },
}

export const COMMON_SELECT_PROPS = {
  isSearchable: false,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: true,
  closeMenuOnSelect: false,
  components: { Option: SelectOption },
}

export const START_OPTION_TYPE = 'lastLoginStart';
export const END_OPTION_TYPE = 'lastLoginEnd';

export const LAST_LOGIN_START_OPTIONS = [
  {
    label: messages['usersFiltersModal.lastLogin.lastWeek'],
    type: START_OPTION_TYPE,
    value: moment.utc().subtract(7, 'days').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.lastMonth'],
    type: START_OPTION_TYPE,
    value: moment.utc().subtract(1, 'months').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.lastThreeMonth'],
    type: START_OPTION_TYPE,
    value: moment.utc().subtract(3, 'months').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.lastYear'],
    type: START_OPTION_TYPE,
    value: moment.utc().subtract(1, 'years').startOf('day').format(),
  },
]

export const LAST_LOGIN_END_OPTIONS = [
  {
    label: messages['usersFiltersModal.lastLogin.weekAgo'],
    type: END_OPTION_TYPE,
    value: moment.utc().subtract(7, 'days').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.monthAgo'],
    type: END_OPTION_TYPE,
    value: moment.utc().subtract(1, 'months').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.threeMonthsAgo'],
    type: END_OPTION_TYPE,
    value: moment.utc().subtract(3, 'months').startOf('day').format(),
  },
  {
    label: messages['usersFiltersModal.lastLogin.yearAgo'],
    type: END_OPTION_TYPE,
    value: moment.utc().subtract(1, 'years').startOf('day').format(),
  }
]

// TODO move to helpers or avoid it
const normalizeToStrings = (values: Record<string, any>) => {
  const filterParams: Record<string, any> = {};

  Object.keys(values)
    .forEach(key => {
      filterParams[key] = Array.isArray(values[key])
        ? values[key].map((option: OptionType) => option.value)
        : []
    });

  return filterParams;
}

export const UsersFiltersModal: FC<UsersFiltersModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const usersGridUIStore = useUsersGridUIStore();
  const { filtersOptions } = getEnv<IStoresEnv>(usersGridUIStore);
  const requestParams = usersGridUIStore.requestParams.getParams;

  const [stateLastLoginStart, setLastLoginStart] = useState(requestParams.lastLoginStart || '');
  const [stateLastLoginEnd, setLastLoginEnd] = useState(requestParams.lastLoginEnd || '');

  const onLastLoginSelectChange = (option: OptionType) => {
    const isStartType = option.type === START_OPTION_TYPE;

    setLastLoginStart(isStartType ? option.value : '');
    setLastLoginEnd(isStartType ? '' : option.value);
  }

  const onSubmit = (values: Record<string, any>) => {
    const filterParams = normalizeToStrings(values);

    usersGridUIStore.setParams({
      page: 0,
      ...filterParams,
      lastLoginStart: stateLastLoginStart,
      lastLoginEnd: stateLastLoginEnd,
    });
    setLastLoginStart('');
    setLastLoginEnd('');
    onRequestClose();
  }

  return (
    <Observer>
      {() => {
        const departmentsOptions = filtersOptions.getDepartmentsOptions;
        const rolesOptions = filtersOptions.getRolesOptions;
        const statusesOptions = filtersOptions.getStatusesOptions;
        const groupsOptions = filtersOptions.getGroupsOptions;
        const proceduresOptions = filtersOptions.getProceduresOptions;

        const {
          departments,
          groupIds,
          procedureIds,
          roleIds,
          statusIds,
          lastLoginStart = '',
          lastLoginEnd = '',
        } = usersGridUIStore.requestParams.getParams;

        const initialValues = {
          departments: getOptionsByStrings(departments, departmentsOptions),
          groupIds: getOptionsByStrings(groupIds, groupsOptions),
          procedureIds: getOptionsByStrings(procedureIds, proceduresOptions),
          roleIds: getOptionsByStrings(roleIds, rolesOptions),
          statusIds: getOptionsByStrings(statusIds, statusesOptions),
          lastLogin: lastLoginStart
            ? getOptionByString(lastLoginStart, LAST_LOGIN_START_OPTIONS)
            : getOptionByString(lastLoginEnd, LAST_LOGIN_END_OPTIONS),
        }

        return (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
          >
            <h2 className={styles.h2}>{messages['usersFiltersModal.title']}</h2>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
            >
              {form => {
                const values:Record<string, any> = form.values;

                const isValid = Object.keys(values)
                  .some(key => values[key] && values[key].length > 0) || values.lastLogin.value;

                return (
                  <Form autoComplete='off' noValidate>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.departments}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={departmentsOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.roleIds}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={rolesOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.statusIds}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={statusesOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.lastLogin}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={[...LAST_LOGIN_START_OPTIONS, ...LAST_LOGIN_END_OPTIONS]}
                        onChange={onLastLoginSelectChange}
                        components={{
                          Option: (props: SelectOptionProps) => <SelectOption {...props} isSelected={false} />,
                        }}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.groupIds}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={groupsOptions}
                      />
                    </div>
                    <div className={styles.field}>
                      <FormikSelectDropdown
                        schema={valuesSchema.procedureIds}
                        className={styles.dropdown}
                        {...COMMON_SELECT_PROPS}
                        options={proceduresOptions}
                      />
                    </div>
                    <div className={styles.buttons}>
                      <Button
                        onClick={onRequestClose}
                        className={styles.button} color="secondary"
                      >
                        {messages['usersFiltersModal.button.cancel']}
                      </Button>
                      <Button
                        disabled={!isValid}
                        className={styles.button}
                        type="submit"
                      >
                        {messages['usersFiltersModal.button.submit']}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal>
        )
      }}
    </Observer>
  )
}
