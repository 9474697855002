import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'react-jss';

import { useStyles } from './SidebarFilterButton.styles';

export type SidebarFilterButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
  active?: boolean;
  className?: string;
}

// TODO try to customize Button component instead of this
export const SidebarFilterButton: React.FC<SidebarFilterButtonProps> = ({ onClick, children, active, className }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <button
      onClick={onClick}
      type="button"
      className={
        classNames(styles.wrapper, className, { [styles.active]: active })
      }
      data-testid="sidebar-filter-button"
    >
      {children}
    </button>
  )
};

SidebarFilterButton.displayName = 'SidebarFilterButton';
