import React, { useEffect } from 'react';

type AutoSubmitProps = {
  values: any;
  submitForm: () => void;
}

export const AutoSubmit: React.FC<AutoSubmitProps> = ({ values, submitForm }) => {
  useEffect(() => {
    submitForm()
  }, [values, submitForm]);

  return null;
}
