import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { getEnv } from 'mobx-state-tree';
import { useTheme } from 'react-jss';
import { toast } from 'react-toastify';

import { Modal } from '@shared/components/Modal';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { Button } from '@shared/components/Button';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { getOptionsByStrings, convertOptionsToStrings } from '@shared/helpers/form';
import { FormError } from '@shared/components/FormError';
import { ToastMessage } from '@shared/components/Toast';
import { useGroupsUIStore } from '@core/useStores';
import { IStoresEnv } from '@core/storesEnv';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';
import { FormValues, GroupFormValues } from './FormValues';
import { GroupData } from '../../domain/Group';

import { useStyles } from './GroupModal.styles';
import { messages } from 'Groups/groups.messages';

export const COMMON_SELECT_PROPS = {
  isSearchable: true,
  hideSelectedOptions: false,
  isClearable: false,
  isMulti: true,
  closeMenuOnSelect: false,
  components: { Option: SelectOption },
}

export type GroupModalProps = {
  isOpen: boolean,
  onRequestClose: () => void,
  onGroupDeleteRequest: () => void,
  groupId: string | null,
}

export const GroupModal: FC<GroupModalProps> = ({
  isOpen,
  groupId,
  onRequestClose,
  onGroupDeleteRequest,
}) => {
  const isEditMode = !!groupId;
  const theme = useTheme();
  const styles = useStyles(theme);
  const groupsUIStore = useGroupsUIStore();

  const { filtersOptions } = getEnv<IStoresEnv>(groupsUIStore);

  const usersOptions = filtersOptions.getUsersOptions;
  const proceduresOptions = filtersOptions.getProceduresOptions;

  const [isLoading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState<GroupData | null>(null);
  const [backEndErrors, setBackEndErrors] = useState([]);

  useEffect(() => {
    async function fetchGroupData() {
      if (groupId) {
        setLoading(true);
        const { data } = await groupsUIStore.loadGroup(groupId);
        setLoading(false);

        setGroupData(data);
      }
    }

    fetchGroupData();
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { name, users, procedures } = values;
    const computedValues = {
      name,
      userIds: users ? convertOptionsToStrings(users) : [],
      procedureIds: procedures ? convertOptionsToStrings(procedures) : [],
    }

    const result = isEditMode
      ? await groupsUIStore.editGroup({...computedValues, id: groupId})
      : await groupsUIStore.addGroup(computedValues);

    if (result.success) {
      toast.success(
        <ToastMessage
          type='success'
          message={messages['groupModal.editAction.success'](result.data.name, isEditMode)}
        />
      );

      groupsUIStore.loadGroups();
      filtersOptions.loadFilterOptions();

      onRequestClose();
    } else {
      // TODO Ask BE make this error consistent( in most cases we use errors)
      const { body } = result.error;

      setBackEndErrors(body.messages || []);
    }
  }

  const initialValues = groupData ? {
    name: groupData.name || '',
    users: getOptionsByStrings(groupData.userIds, usersOptions),
    procedures: getOptionsByStrings(groupData.procedureIds, proceduresOptions),
  } : new FormValues();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div className={styles.heading}>
        <h2 className={styles.h2}>
          { isEditMode ? groupsUIStore.selectedGroup?.name : messages['groupModal.newGroup'] }
        </h2>

        {
          isLoading && (
            <CircularProgress className={styles.loader} color='inherit' size={24} />
          )
        }
      </div>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues as FormValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {(form: FormikProps<GroupFormValues>) => {
          const formErrors: Record<string, any> = form.errors;
          const errors = Object.keys(formErrors).length
            ? Object.keys(formErrors).map((key: string) =>
              formErrors[key].value ? formErrors[key].value : formErrors[key]
            )
            : backEndErrors;

          return (
            <Form autoComplete='off' noValidate>
              {!!errors?.length && (
                <div className={styles.errors}>
                  {errors.map((text: string) => (
                    <FormError className={styles.error} key={text} text={text} />
                  ))}
                </div>
              )}

              <div className={styles.field}>
                <FormikTextInput
                  schema={valuesSchema.name}
                  cleanable
                />
              </div>

              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.users}
                  className={styles.dropdown}
                  options={usersOptions}
                  {...COMMON_SELECT_PROPS}
                />
              </div>

              <div className={styles.field}>
                <FormikSelectDropdown
                  schema={valuesSchema.procedures}
                  className={styles.dropdown}
                  options={proceduresOptions}
                  {...COMMON_SELECT_PROPS}
                />
              </div>

              <div className={styles.buttons}>
                {
                  isEditMode && (
                    <Button
                      onClick={() => onGroupDeleteRequest()}
                      color="secondary"
                      data-testid="group-modal-delete"
                    >
                      {messages['groupModal.delete']}
                    </Button>
                  )
                }

                <div>
                  <Button
                    onClick={onRequestClose}
                    className={styles.cancel}
                    color="secondary"
                    data-testid="group-modal-cancel"
                  >
                    {messages['groupModal.cancel']}
                  </Button>

                  <Button type="submit">
                    {isEditMode ? messages['groupModal.save'] : messages['groupModal.create']}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
