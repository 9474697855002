import _ from 'lodash';
import { Instance, SnapshotIn, types, getSnapshot, getEnv, applySnapshot } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { ALL_USERS_ID } from '@shared/constants';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_LIMIT = 10;

export const BasePageQueryStoreInferred = types
  .model({
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_LIMIT,
    sortFieldName: types.maybe(types.string),
    sortDirection: types.maybe(types.number),
    searchText: '',
    inGroup: ALL_USERS_ID,
    inGroupId: '',
  })
  .volatile(() => ({
    _FILTER_KEY: '',
  }))
  .views(self => ({
    get getParams() {
      return getSnapshot(self);
    },
    get getStorageParams() {
      const { sessionStorage } = getEnv<IStoresEnv>(self);
      const storedParamsData = sessionStorage.getItem(self._FILTER_KEY);
      const parsedParamsData = storedParamsData && JSON.parse(storedParamsData);

      return parsedParamsData;
    },
  }))
  .views(self => ({
    get areSearchParamsSet() {
      const filterAndSearchParams =
        _.omit(self.getParams, 'page', 'limit', 'sortFieldName', 'sortDirection', 'inGroup', 'inGroupId');
      const values = _.flatten(_.values(filterAndSearchParams));

      return !!_.compact(values).length;
    },
  }))
  .actions(self => ({
    _setParams<T>(params: T) {
      applySnapshot(self, Object.assign({}, self.getParams, params));
    },
  }))
  .actions(self => {
    const { sessionStorage } = getEnv<IStoresEnv>(self);

    return {
      // Default function, will be replaced by extended store
      _prefillFromStorage: () => {},
      _resetParams<T>(filtersToKeep: T) {
        sessionStorage.setItem(self._FILTER_KEY, JSON.stringify(filtersToKeep));
        applySnapshot(self, filtersToKeep);
      },
      saveParamsToStorage() {
        sessionStorage.setItem(self._FILTER_KEY, JSON.stringify(self.getParams));
      },
      clearAllParams() {
        sessionStorage.setItem(self._FILTER_KEY, '');
        applySnapshot(self, {});
      },
    };
  })
  .actions(self => ({
    afterCreate: () => {
      self._prefillFromStorage();
    },
  }));

type BasePageQueryStoreFactoryType = typeof BasePageQueryStoreInferred;
interface IBasePageQueryStoreFactory extends BasePageQueryStoreFactoryType {}
export const BasePageQueryStore: IBasePageQueryStoreFactory = BasePageQueryStoreInferred;
export interface IBasePageQueryStore extends Instance<IBasePageQueryStoreFactory> {}
export interface IBasePageQueryStoreSnapshotIn extends SnapshotIn<IBasePageQueryStore> {}
