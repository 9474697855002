import { Instance, SnapshotIn, types, flow, getEnv, cast } from 'mobx-state-tree';

import { IStoresEnv } from '@core/storesEnv';
import { UsersStatisticsStore } from '@shared/stores';
import { performGetUsersStatistics } from 'Users/services';
import { UserAnalyticStore } from './UserAnalytic.store';
import { PageQueryStore } from './PageQuery.store';
import { PaginationStatusStore } from './PaginationStatus.store';

import { performGetAnalytic } from '../services';

export const AnalyticsGridStoreInferred = types
  .model('AnalyticsGridStoreInferred', {
    analyticsList: types.array(UserAnalyticStore),
    requestParams: types.optional(PageQueryStore, {}),
    paginationStatus: types.optional(PaginationStatusStore, {}),
    statistics: types.optional(UsersStatisticsStore, {}),
  })
  .actions(self => {
    const { api } = getEnv<IStoresEnv>(self);

    const loadAnalytics = flow(function* () {
      const result = yield performGetAnalytic(
        api,
        {
          payload: { ...self.requestParams.getParams },
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.analyticsList = result.data.entities;
      self.paginationStatus = result.data.meta;
    });

    // TODO remove this call and try to share statistic between store
    const loadStatistics = flow(function* () {
      const { inGroup, inGroupId: groupId } = self.requestParams.getParams;
      const result = yield performGetUsersStatistics(
        api,
        {
          payload: { inGroup, groupId },
          errorHandlers: { DEFAULT: '' },
        },
      );

      self.statistics = result.data;
    });

    const cleanData = () => {
      self.analyticsList = cast([]);
      self.paginationStatus = cast({});
    };

    return {
      loadAnalytics,
      loadStatistics,
      cleanData,
    }
  })

type AnalyticsGridStoreFactoryType = typeof AnalyticsGridStoreInferred;
interface IAnalyticsGridStoreFactory extends AnalyticsGridStoreFactoryType {}
export const AnalyticsGridStore: IAnalyticsGridStoreFactory = AnalyticsGridStoreInferred;
export interface IAnalyticsGridStore extends Instance<IAnalyticsGridStoreFactory> {}
export interface IAnalyticsGridStoreSnapshotIn extends SnapshotIn<IAnalyticsGridStore> {}
