import React, { FC } from 'react';
import { useTheme } from 'react-jss';
import classNames from 'classnames';
import _ from 'lodash';

import { useStyles } from './ExecutionsProgress.styles';
import { messages } from 'Analytics/analytic.messages';

type ExecutionsProgressProps = {
  completed: number;
  assigned: number;
}

const EDGE_ASSIGNED_COUNT = 50;

export const ExecutionsProgress: FC<ExecutionsProgressProps> = ({ completed, assigned }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const isProgressVisible = completed <= assigned;
  const isDividedProgressBarVisible = assigned <= EDGE_ASSIGNED_COUNT;

  return (
    <>
      <div className={styles.stats} data-testid="executions-progress-stats">
        <span className={styles.executedText}>
          {messages['executionsProgress.completed'](completed)}
        </span>
        {messages['executionsProgress.assigned'](assigned)}
      </div>

      <div className={styles.progress}>
        {
          isProgressVisible && isDividedProgressBarVisible && _.times(assigned, index =>
            <div
              key={index}
              className={classNames(
                styles.progressBlock,
                {[styles.progressBlockExecuted]: index < completed},
              )}
              data-testid="executions-progress-block"
            />
          )
        }

        {
          isProgressVisible && !isDividedProgressBarVisible && (
            <div className={styles.progressBlock} data-testid="executions-progress-block">
              <div
                style={{ width: `${completed / assigned * 100}px` }}
                className={styles.progressExecutedMark}
              ></div>
            </div>
          )
        }
      </div>
    </>
  )
}
