import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetGroupsParams = {
  sortFieldName: string;
  sortDirection: number;
};

export const performGetGroups = async (api: IApiStore, params: EndpointCallParams<GetGroupsParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .get('identity-app/api/v1/Groups', {
      ...rest,
      payload,
    })

  return req;
}
