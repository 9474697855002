import { createUseStyles } from 'react-jss';

import { flex, textOverflowDots } from '@resources/mixins';
import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  gridDisabled: {},
  grid: {
    ...flex('column', 'space-between', ''),
    flexWrap: 'wrap',
    width: '100%',
    minHeight: 'calc(100vh - 300px)',

    '&$gridDisabled': {
      pointerEvents: 'none',
    },
  },

  loadingWrapper: {
    height: 24,
    top: '50%',
    right: theme.spacing(5),
    position: 'absolute',
    transform: 'translate(0, -50%)',
  },

  loader: {
    color: theme.colors.violet_primary,
  },

  body: {
    width: `calc(100% + ${theme.spacing(4)}px)`,
    margin: [0, -theme.spacing(2)],
    ...flex('row', 'flex-start'),
    flexWrap: 'wrap',
  },

  row: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.grey_light}`,
    width: 370,

    '&:hover': {
      background: theme.colors.grey_background,
    },
  },

  noGrow: {
    flexGrow: '0 !important',
  },

  ellipsis: {
    ...textOverflowDots(),
  },

  pagination: {
    background: 'none',
  },
}));
