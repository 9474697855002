import { History } from 'history';
import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { AnonymousRoute, ProtectedRoute } from '@core/Auth/components';

import { Login } from 'AuthPart/Login';
import { ForgotPassword } from 'AuthPart/ForgotPassword';
import { SetPassword } from 'AuthPart/SetPassword';
import { CompaniesPage } from 'Companies/components';
import { UsersPage } from 'Users/components';
import { ProceduresPage } from 'Procedures/components';
import { CollaborationPage } from 'Collaboration/components';
import { AnalyticsPage } from 'Analytics/components/AnalyticsPage';
import { UserAnalyticsPage } from 'Analytics/components/UserAnalyticsPage';
import { ProfilePage } from 'Profile/components/ProfilePage';

import { RoutePaths } from './RoutePaths';
import { PagesNames } from './PagesNames';
import { AppExternalLayout, AppLayout } from '../layouts';

type Props = {
  history: History;
};

export const AppRouter: React.FC<Props> = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path={RoutePaths.root}
        render={() => <Redirect to={RoutePaths.users} />}
      />

      <AnonymousRoute path={RoutePaths.login}>
        <AppExternalLayout><Login /></AppExternalLayout>
      </AnonymousRoute>

      <AnonymousRoute path={RoutePaths.forgotPassword}>
        <AppExternalLayout><ForgotPassword /></AppExternalLayout>
      </AnonymousRoute>

      <AnonymousRoute path={RoutePaths.setPassword}>
        <AppExternalLayout><SetPassword /></AppExternalLayout>
      </AnonymousRoute>

      <ProtectedRoute path={RoutePaths.companies} page={PagesNames.Companies}>
        <AppLayout path={RoutePaths.companies} showSidebar={false}>
          <CompaniesPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute path={RoutePaths.users} page={PagesNames.Users}>
        <AppLayout path={RoutePaths.users}>
          <UsersPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute path={RoutePaths.procedures} page={PagesNames.Procedures}>
        <AppLayout path={RoutePaths.procedures}>
          <ProceduresPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute path={RoutePaths.collaboration} page={PagesNames.Collaboration}>
        <AppLayout>
          <CollaborationPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute exact path={RoutePaths.analytics} page={PagesNames.Analytics}>
        <AppLayout path={RoutePaths.analytics}>
          <AnalyticsPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute path={RoutePaths.analyticsByUser} page={PagesNames.Analytics}>
        <AppLayout path={RoutePaths.analytics}>
          <UserAnalyticsPage />
        </AppLayout>
      </ProtectedRoute>

      <ProtectedRoute path={RoutePaths.profile} page={PagesNames.Profile}>
        <AppLayout path={RoutePaths.profile} showSidebar={false}>
          <ProfilePage />
        </AppLayout>
      </ProtectedRoute>
    </Switch>
  </Router>
);
