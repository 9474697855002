import { ISelectOption } from './ISelectOption';

export const getValueLabelOptions = <T>(
  items: T[],
  getLabelCallback: (item: T) => string,
  getValueCallback: (item: T) => string,
): ISelectOption[] => {
  return items.map(item => ({
    value: getValueCallback(item),
    label: getLabelCallback(item),
  }));
};
