import React from 'react';
import { FieldProps } from 'formik';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

import { BaseInputProps, APP_INNER_STYLE_VARIANT, APP_EXTERNAL_STYLE_VARIANT } from '../../BaseInputProps';
import { InputLabel } from '../InputLabel';
import { CleanValue } from './CleanValue';
import { useStyles } from './TextInput.styles';

export type TextInputProps = BaseInputProps & FieldProps;

export const TextInput:React.FC<TextInputProps> = props => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const {
    field: { name, value },
    form, meta,
    placeholder,
    label, isRequired,
    maxLength, disabled,
    className, readOnly, styleVariant = APP_INNER_STYLE_VARIANT,
    prefix, suffix,
    type, cleanable, helperText, autoFocus,
    min, max,
  } = props;

  const inputProps = {
    autoFocus,
    className: classNames(
      className,
      styles.input,
      { [styles.textarea]: type === 'textarea' },
      { [styles.appExternal]: styleVariant === APP_EXTERNAL_STYLE_VARIANT },
      { [styles.withError]: meta.touched && meta.error }
    ),
    autoComplete: 'off',
    disabled,
    maxLength,
    placeholder,
    type,
    name,
    value,
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    readOnly,
    min,
    max,
  }

  return (
    <>
      {
        label && (
          <InputLabel
            label={label}
            isRequired={isRequired}
            styleVariant={styleVariant}
            className={classNames(
              { [styles.labelWithError]: meta.touched && meta.error }
            )}
          />
        )
      }

      <div className={styles.inputWrapper}>
        {
          prefix && prefix(props)
        }

        {
          type === 'textarea'
            ? <textarea {...inputProps} />
            : <input {...inputProps} />
        }

        {
          cleanable && !suffix && (
            <CleanValue
              styleVariant={styleVariant}
              form={form}
              meta={meta}
              name={name}
              disabled={disabled}
            />
          )
        }

        {
          suffix && !cleanable && suffix(props)
        }
      </div>

      {
        helperText && <div className={styles.helperWrapper}>{ helperText }</div>
      }
    </>
  );
}
