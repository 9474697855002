import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex, stickyFormBlock } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  modal: {
    width: 508,
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'auto',
  },

  heading: {
    ...flex('row', 'space-between', ''),
  },

  loader: {
    color: theme.colors.violet_primary,
  },

  h2: {
    fontFamily: theme.font.secondary,
    fontSize: 24,
    lineHeight: 1.33,
    color: theme.colors.black,
    fontWeight: 'normal',
    marginBottom: theme.spacing(7),
  },
  field: {
    marginBottom: theme.spacing(6),
  },
  dropdown: {
    margin: 0,
  },
  errors: {
    ...stickyFormBlock(),
  },
  error: {
    backgroundColor: theme.colors.red,
    marginBottom: theme.spacing(2),
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  cancel: {
    marginRight: theme.spacing(5),
  },
}));
