export const messages = {
  'group.delete.success': (groupName?: string) => `The group "${groupName}" was successfully deleted`,
  'group.delete.confirmation.message': (name?: string) => `Are you sure you want to delete group ${name}?`,
  'group.delete.confirmation.note': 'Only the group will be deleted, not the users in the group.',
  'group.delete.confirmation.confirmText': 'Delete group',
  'groupModal.editAction.success': (name: string, isEditMode: boolean) =>
    `The group ${name} was successfully ${isEditMode ? 'updated' : 'created'}`,
  'groupModal.newGroup': 'New group',
  'groupModal.delete': 'Delete group',
  'groupModal.cancel': 'Cancel',
  'groupModal.save': 'Save',
  'groupModal.create': 'Create',
  'groupModal.error.required': (fieldLabel?: string) => `${fieldLabel} can't be blank`,
  'groupModal.name.label': 'Group name',
  'groupModal.users.label': 'Users',
  'groupModal.procedures.label': 'Access to procedures',
  'groupsHeading.create.tooltip': 'Create new group',
  'groupsHeading.title': 'Groups',
  'groupsList.noGroups': 'No groups found',
  'groupsList.noGroupsCreated': 'You haven’t created any groups yet',
  'groupsList.create': 'Create group',
}
