import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UsersStatisticsStoreInferred = types
  .model({
    usersCount: types.maybeNull(types.number),
    instructorsCount: types.maybeNull(types.number),
    proceduresCount: types.maybeNull(types.number),
  });

type UsersStatisticsStoreFactoryType = typeof UsersStatisticsStoreInferred;
interface IUsersStatisticsStoreFactory extends UsersStatisticsStoreFactoryType {}
export const UsersStatisticsStore: IUsersStatisticsStoreFactory = UsersStatisticsStoreInferred;
export interface IUsersStatisticsStore extends Instance<IUsersStatisticsStoreFactory> {}
export interface IUsersStatisticsStoreSnapshotIn extends SnapshotIn<IUsersStatisticsStore> {}
